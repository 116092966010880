// @flow
import React, {useContext} from 'react';
import type {Node} from 'react';
import {Box} from '@wellstone-solutions/web';
import {MemberDetailTabContext} from '../../contexts/MemberDetailTabContext';
import {AdmissionsLoader} from '../Admissions';

export const ProgramsAndGroupsTabContent = (): Node => {
  const {member} = useContext(MemberDetailTabContext);

  return (
    <Box mb={1}>
      <AdmissionsLoader memberId={member.id} />
    </Box>
  );
};
