// React
import React, {useState, useEffect} from 'react';

// Third Party
import Moment from 'react-moment';
import {Chip, Paper} from '@wellstone-solutions/web';

import {getCheckinOptions} from 'utils/Charts';
import {Theme} from '@wellstone-solutions/common';

export const EventStream = (props) => {
  const [checkinOptions, setCheckinOptions] = useState(null);

  useEffect(() => {
    const fetchCheckinOptions = async () => {
      const options = await getCheckinOptions();
      setCheckinOptions(options);
    };

    fetchCheckinOptions();
  }, []);

  const buildEventMessage = (event, e) => {
    const quickDate = new Date(event.localDate);

    if (!checkinOptions || event.category !== 'user_added_checkin') {
      return null;
    }

    const dateHeader = (
      <Moment
        style={styles.eventDate}
        element="span"
        format="MMM D, YYYY"
        date={quickDate}
      />
    );

    return (
      <Paper key={e} elevation={3} sx={{padding: 2, marginBottom: 2}}>
        <div>{dateHeader}</div>
        {event.data.states.map((state, s) => {
          const checkin = checkinOptions.find((item) => item.value === state);

          return checkin ? (
            <Chip
              key={s}
              style={{
                ...styles.moodTag,
                backgroundColor: checkin.color,
                color:
                  checkin.color === '#B2B2B2'
                    ? Theme.colorPalette.darkest
                    : Theme.colorPalette.lightest,
              }}
              variant="solid"
              label={checkin.title}></Chip>
          ) : null;
        })}
      </Paper>
    );
  };

  if (!props.events || props.events.length < 1) {
    return 'No event history available';
  }

  return (
    <div style={styles.eventsContainer} className="event-stream">
      {props.events.map((event, e) => buildEventMessage(event, e))}
    </div>
  );
};

const styles = {
  eventsContainer: {
    height: '100%',
    overflow: 'auto',
    padding: '10px 5px',
  },
  eventDate: {
    fontWeight: 700,
    fontSize: 14,
    color: Theme.colorPalette.darkest,
    marginBottom: 15,
  },
  moodTag: {
    marginRight: '0.25rem',
    marginTop: '0.25rem',
    marginBottom: '0.25rem',
    marginLeft: 0,
    fontWeight: 'bold',
    color: Theme.colorPalette.lighter,
  },
};

export default EventStream;
