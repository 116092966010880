// @flow
// React
import React, {useEffect, useState} from 'react';
import type {Node} from 'react';
import {Link} from 'react-router-dom';

// Third party
import {useQueryString, Alert, Stack} from '@wellstone-solutions/web';
import {Api, Theme} from '@wellstone-solutions/common';

// Bulma
import {Button, Heading} from 'react-bulma-components';
import {Paper} from '@wellstone-solutions/web';

import {routes} from 'api';
import {useStores} from 'hooks/useStores';
import {Palette} from '../../../palette';
import styles from '../LoginModule.module.scss';
import {EmailStage} from './EmailStage';
import {SetPasswordStage} from './SetPasswordStage';
import {RESET_STAGES} from '../constants';
import type {ResetStageType} from '../constants';

export const ResetPasswordModule = (): Node => {
  const query = useQueryString();
  const {meStore} = useStores();
  const owner = query.get('user_id');
  const token = query.get('token');
  const [stage, setStage] = useState<ResetStageType>(
    owner && token ? RESET_STAGES.password : RESET_STAGES.request,
  );

  useEffect(() => {
    // Bug fix: Scenario where user is already logged in results in the search box
    // showing up on this page, which is not desired.
    if (meStore.isAuthenticated()) {
      meStore.signOut();
    }
  }, [meStore]);

  const requestReset = async (email: string): Promise<void> => {
    setStage('wait');

    // POST to api /auth/reset-password with email
    await Api.Instance.current().post(routes.resetPasswordRequest, {
      email,
    });
  };

  const TITLES = {
    [RESET_STAGES.request]: 'Forgot Your Password?',
    [RESET_STAGES.wait]: 'Check Your Email',
    [RESET_STAGES.password]: 'Choose Your New Password',
    [RESET_STAGES.success]: 'Your Password Has Been Changed',
  };

  return (
    <div className={styles.container}>
      <Paper className={styles.content} sx={{padding: 4, marginBottom: 4}}>
        <Heading size={3} style={{color: Palette.PRIMARY}}>
          {TITLES[stage]}
        </Heading>
        {stage === RESET_STAGES.request && (
          <EmailStage requestReset={requestReset} />
        )}
        {stage === RESET_STAGES.wait && (
          <Stack>
            <div>
              You will receive an email from us with instructions for resetting
              your password. If you don't receive this email, please check your
              junk mail folder or contact an Admin from your organization for
              assistance.
            </div>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{'a:hover': {color: 'currentcolor'}, mt: Theme.spacing.mini}}
              direction="row">
              <Button inverted color="primary">
                <Link to="/auth/login?method=password">Back to Login</Link>
              </Button>
            </Stack>
          </Stack>
        )}
        {stage === RESET_STAGES.password && (
          <SetPasswordStage
            setStage={setStage}
            owner={String(owner)}
            token={String(token)}
          />
        )}
        {stage === RESET_STAGES.success && (
          <div>
            <Alert severity="success" sx={{mb: Theme.spacing.micro}}>
              Your password has been successfully reset.
            </Alert>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{'a:hover': {color: 'currentcolor'}, mt: Theme.spacing.mini}}
              direction="row">
              <Button inverted color="primary">
                <Link to="/auth/login?method=password">Back to Login</Link>
              </Button>
            </Stack>
          </div>
        )}
      </Paper>
    </div>
  );
};
