// @flow
import {Palette} from 'palette';

export const PAGE_SIZE = 25;

export const adminProgramPermissionsMsg = (roleName: string): string =>
  `${roleName}s of a program are granted access to view and modify all staff, members and groups within that program. Adding a(n) ${roleName.toLowerCase()} specifically to a group in the dropdown below allows members of that group to see and contact the ${roleName.toLowerCase()} through the Companion mobile app.`;

export const STAFF_PROGRESS = {
  completed: {
    label: 'Completed Tutorial',
    color: Palette.SUCCESS,
  },
  started: {
    label: 'Started Tutorial',
    color: Palette.MEDIUM_INFO,
  },
  loggedIn: {
    label: 'Has Logged In',
    color: Palette.INFO,
  },
  notLoggedIn: {
    label: 'Has Not Logged In',
    color: Palette.WARNING,
  },
};

export const ALL_PROGRAMS_FILTER = {
  name: 'All Staff',
  value: 'all',
};
