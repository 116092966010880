// @flow
import React from 'react';
import type {Node} from 'react';
import {
  Box,
  Checkbox,
  Chip,
  Icon,
  IconNames,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@wellstone-solutions/web';
import {useTheme} from '@mui/material/styles';
import {Theme} from '@wellstone-solutions/common';
import type {UIHabitType} from '@wellstone-solutions/common/models/rest/habit/types';
import {RemoteIcon} from 'components/RemoteIcon';
import useMediaQuery from '@mui/material/useMediaQuery';

type PropsType = {
  habit: UIHabitType & {
    checked: boolean,
  },
  handleCheckboxChange: (id: string, isChecked: boolean) => void,
  handleFrequencyChange: (id: string, newFrequency: string) => void,
  handleIntervalChange: (id: string, newInterval: number) => void,
};

type FrequencyOption = {
  label: string,
  icon: string,
};

const frequencyOptions: FrequencyOption[] = [
  {label: 'Daily', value: 'daily', icon: IconNames.Clock},
  {label: 'Weekly', value: 'weekly', icon: IconNames.ReportColumns},
  {label: 'Monthly', value: 'monthly', icon: IconNames.Calendar},
];

const FrequencySelectItem = ({item}: {item: FrequencyOption}): Node => {
  return (
    <Box sx={styles.selectOption}>
      <Icon name={item.icon} size={16} />
      <Typography sx={styles.optionText}>{item.label}</Typography>
    </Box>
  );
};

export const HabitListItem = ({
  habit,
  handleCheckboxChange,
  handleFrequencyChange,
  handleIntervalChange,
}: PropsType): Node => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper>
      {habit.habitType && (
        <Chip
          sx={styles.chip}
          size="small"
          variant={isMobile ? 'outlined' : 'solid'}
          color={habit.habitType === 'personal' ? 'secondary' : 'primary'}
          label={habit.habitType === 'personal' ? 'Personal' : 'Assigned'}
        />
      )}
      <Stack direction="row" sx={styles.container}>
        <Stack direction="column" sx={styles.leftSection}>
          <Checkbox
            checked={habit.checked}
            disabled={habit.habitType === 'personal'}
            onChange={(e) => handleCheckboxChange(habit.id, e.target.checked)}
            inputProps={{'data-testid': `habit-checkbox-${habit.id}`}}
          />

          <Stack direction="row" sx={styles.habitDetails}>
            {!isMobile && (
              <Box sx={styles.iconContainer}>
                <RemoteIcon
                  name={habit.name}
                  disableBorder={true}
                  path={'/icons/habits'}
                  size={60}
                />
              </Box>
            )}
            <Box>
              <Typography sx={styles.habitName}>{habit.name}</Typography>
              {habit.translation && (
                <Typography sx={styles.translation}>
                  {habit.translation}
                </Typography>
              )}
            </Box>
          </Stack>
        </Stack>

        <Stack sx={styles.actionsContainer}>
          <Select
            containerProps={{fullWidth: true}}
            label="Frequency"
            value={habit.frequency}
            onChange={(e) => handleFrequencyChange(habit.id, e.target.value)}
            disabled={habit.habitType === 'personal'}
            sx={styles.select}
            items={frequencyOptions}
            itemTemplate={FrequencySelectItem}
            inputProps={{'data-testid': `habit-frequency-${habit.id}`}}
          />

          <TextField
            value={habit.interval}
            onChange={(e) =>
              handleIntervalChange(habit.id, Number(e.target.value))
            }
            label="Interval"
            type="number"
            disabled={habit.habitType === 'personal'}
            inputProps={{min: 1, 'data-testid': `habit-interval-${habit.id}`}}
          />
        </Stack>
      </Stack>
    </Paper>
  );
};

const styles = {
  container: {
    padding: Theme.spacing.micro,
    display: 'flex',
    flexDirection: {xs: 'column', sm: 'row'},
    alignItems: {xs: 'flex-start', sm: 'center'},
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: Theme.spacing.micro,
  },
  leftSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  habitDetails: {
    display: 'flex',
    marginTop: Theme.spacing.micro,
  },
  habitName: {
    fontSize: Theme.spacing.large,
    fontWeight: 'bold',
  },
  translation: {
    wordBreak: 'break-word',
    fontSize: Theme.spacing.medium,
    marginBottom: Theme.spacing.micro,
    color: Theme.colorPalette.mediumDark,
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    width: {xs: '100%', sm: '40%', lg: '30%'},
    minWidth: '200px',
  },
  select: {
    marginRight: Theme.spacing.micro,
    flex: 2,
  },
  selectOption: {
    display: 'flex',
    marginRight: Theme.spacing.micro,
    alignItems: 'center',
  },
  optionText: {
    marginLeft: '4px',
  },
  iconContainer: {
    flexShrink: 0,
    marginRight: Theme.spacing.micro,
  },
  chip: {
    marginTop: Theme.spacing.micro,
    marginLeft: Theme.spacing.micro,
  },
};
