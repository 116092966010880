var indexOf;

if (typeof Array.prototype.indexOf === 'function') {
  indexOf = function (haystack, needle) {
    return haystack.indexOf(needle);
  };
} else {
  indexOf = function (haystack, needle) {
    var i = 0,
      length = haystack.length,
      idx = -1,
      found = false;

    while (i < length && !found) {
      if (haystack[i] === needle) {
        idx = i;
        found = true;
      }

      i++;
    }

    return idx;
  };
}

export const EventEmitter = {
  events: {},
  dispatch: function (event, data) {
    if (!this.events[event]) {
      return;
    }
    this.events[event].forEach((callback) => callback(data));
  },
  subscribe: function (event, callback) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(callback);
  },
  unsubscribe: function (event, callback) {
    var idx;

    if (typeof this.events[event] === 'object') {
      idx = indexOf(this.events[event], callback);

      if (idx > -1) {
        this.events[event].splice(idx, 1);
      }
    }
  },
};
