// React
import React, {useState} from 'react';

// Bulma
import {Level} from 'react-bulma-components';

// MUI
import {Select} from '@wellstone-solutions/web';

// WS
import {useStores} from 'hooks/useStores';
import {Palette} from 'palette';
import {HabitsScatterChart} from './HabitsScatterChart';

export const HabitsChart = (props) => {
  const {habitStore} = useStores();
  const [selectedFilterName, setSelectedFilterName] = useState('None');

  const filters = [
    {name: 'None', type: null, key: null},
    ...habitStore.habitActivityFilters,
  ];

  const _getActiveFilter = () =>
    filters.find((filter) => filter.name === selectedFilterName);

  const _showActiveFilter = () => {
    const activeFilter = _getActiveFilter();
    return selectedFilterName === 'None'
      ? ''
      : `Filter by ${activeFilter.name}`;
  };

  const _handleFilterChange = (event) => {
    setSelectedFilterName(event.target.value);
  };

  if (!props.habitData) {
    return null;
  }

  return (
    <div style={styles.container}>
      <Level style={styles.filterContainer}>
        <Level.Side>
          <Level.Item>
            <div>{_showActiveFilter()}</div>
          </Level.Item>
        </Level.Side>
        <Level.Side>
          <Level.Item>
            <span style={styles.viewBy}>Filter</span>
            <Select
              value={selectedFilterName}
              onChange={_handleFilterChange}
              id="habits-filter-button"
              items={filters.map((filter) => ({
                value: filter.key || 'none',
                label: filter.name,
              }))}
              canUnselect={false}
            />
          </Level.Item>
        </Level.Side>
      </Level>
      <HabitsScatterChart
        size={{height: 400, width: '95%'}}
        filterData={_getActiveFilter()}
        scatterData={props.habitData}
        x={{key: props.aggregator, type: 'category'}}
        y={{key: 'index', type: 'number'}}
      />
    </div>
  );
};

const styles = {
  container: {
    minHeight: '355px',
  },
  filterContainer: {
    marginBottom: 0,
  },
  viewBy: {
    marginRight: 10,
    fontWeight: 'bold',
    color: Palette.INFO,
  },
};
