// @flow
import {object, boolean, string, array} from 'yup';

export const MAX_GROUP_NAME_LENGTH = 128;
export const MAX_GROUP_DESC_LENGTH = 256;

// $FlowIgnore
export const groupSchema = object({
  name: string()
    .max(MAX_GROUP_NAME_LENGTH, 'Must be at most 128 characters')
    .required('Group Name is required'),
  description: string()
    .max(MAX_GROUP_DESC_LENGTH, 'Must be at most 128 characters')
    .optional(),
  programs: array().min(1, 'Program is required').required(),
  category: string().required('Group Type is required'),
  isPublic: boolean().required(),
});
