import React, {useRef, useEffect, useState} from 'react';
import {Form} from 'react-bulma-components';
import RootStore from '../../../mobx/RootStore';
const {Input} = Form;

/**
 * A core component wrapper that abstracts the bulma Input component,
 * providing extra functionality for the tutorial.
 * Inputs can be highlighted and/or pre-filled and disabled to force a user input.
 */
export default (props) => {
  const rootStore = RootStore.getInstance();
  const {tutorialStore} = rootStore.stores;

  const inputEl = useRef(null);
  const elementId = useRef(props.id).current;
  const [prefillValue, setPrefillValue] = useState('');
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [extraStyles, setExtraStyles] = useState({});
  const [isTutorialComponent, setIsTutorialComponent] = useState(false);
  const {fieldname, value, color, setval} = props;

  useEffect(() => {
    if (!inputEl || !tutorialStore || !elementId) {
      return;
    }
    const needsPreFill = () => {
      for (const [targetId] of Object.entries(
        tutorialStore.prefillComponents,
      )) {
        if (elementId === targetId) {
          return true;
        }
      }
      return false;
    };

    const needsHighlight = () => {
      for (const [, targetId] of Object.entries(
        tutorialStore.highlightComponents,
      )) {
        if (elementId === targetId) {
          return true;
        }
      }
      return false;
    };

    if (needsPreFill()) {
      setIsTutorialComponent(true);
      setPrefillValue(tutorialStore.prefillComponents[elementId]);
      // Bit unsure why this works, but it does! :D
      if (value !== tutorialStore.prefillComponents[elementId]) {
        setval(tutorialStore.prefillComponents[elementId]);
      }
      setIsInputDisabled(true);
    }

    if (needsHighlight()) {
      setExtraStyles({
        position: 'absolute',
        zIndex: 501,
        boxShadow: '0 0 2px 4px rgba(255, 255, 255, 0.92)',
      });
    }

    return () => {};
  }, [tutorialStore, elementId, setval, fieldname, value]);

  const childProps = Object.assign({}, props);
  delete childProps.setval;

  return (
    <Input
      domRef={inputEl}
      {...childProps}
      value={tutorialStore.isActive && prefillValue ? prefillValue : value}
      disabled={isInputDisabled}
      color={isTutorialComponent ? 'success' : color}
      style={extraStyles}>
      {props.children}
    </Input>
  );
};
