import React, {useState, useEffect, Fragment} from 'react';
import {Link} from 'react-router-dom';

import {inject, observer} from 'mobx-react';

import {Api, Theme, Validation} from '@wellstone-solutions/common';
import {DatePicker, Stack, Typography} from '@wellstone-solutions/web';
import Button from '../../tutorial/button';
import {Level, Heading, Tag} from 'react-bulma-components';
import {Alert} from '@wellstone-solutions/web';
import {styleTag} from '../../../utils/Styler';
import {EventEmitter} from '../../../utils/EventEmitter';
import {
  fieldStatus,
  genders,
  getGender,
  getIdentity,
  identities,
  memberStructure,
} from '../../../utils/Forms';
import PfInput from '../components/PfInput';
import PfDropdown from '../components/PfDropdown';

import moment from 'moment';
import {ENTITY_LEVEL, ENTITY_TYPES} from 'modules/rbac';

const EditMemberForm = (props) => {
  const {member, meStore, RBACStore} = props;
  const {id, user} = member;

  const [name, setName] = useState(memberStructure.name.default);
  const [gender, setGender] = useState(memberStructure.gender.default);
  const [birthdate, setBirthdate] = useState(user.birthdate);
  const [identity, setIdentity] = useState(memberStructure.identity.default);
  const [programAdmissions, setProgramAdmissions] = useState(
    member.admissions.filter((adm) => !adm.discharge_date),
  );
  const [groupAdmissions, setGroupAdmissions] = useState(
    member.group_admissions.filter((adm) => !adm.discharge_date),
  );
  const [isProcessing, setIsProcessing] = useState(false);
  // allow birthdate to remain null only if it's currently null
  const isValidBirthday =
    (!user.birthdate && !birthdate) || Validation.isEligibleAge(birthdate);

  const updateProgramAdmissions = (index, property, val) => {
    const newPrograms = [...programAdmissions];
    newPrograms[index].updated = true;
    newPrograms[index][property] = val;
    setProgramAdmissions(newPrograms);
  };

  const updateGroupAdmissions = (index, property, val) => {
    const newGroups = [...groupAdmissions];
    groupAdmissions[index].updated = true;
    groupAdmissions[index][property] = val;
    setGroupAdmissions(newGroups);
  };

  const fields = {
    name: name,
    gender: gender,
    email: user.email,
    identity: identity,
  };

  useEffect(() => {
    if (member.gender && typeof member.gender === 'string') {
      setGender(getGender(member.gender));
    }
    if (member.identifies_as && typeof member.identifies_as === 'string') {
      setIdentity(getIdentity(member.identifies_as));
    }
  }, [member.gender, member.identifies_as]);

  useEffect(() => {
    setName(member.name);
  }, [member.name]);

  const _checkForErrors = () => {
    let hasErrors = false;
    for (let [key, value] of Object.entries(memberStructure)) {
      if (fieldStatus(fields[key], value.test, value.required).error) {
        hasErrors = true;
      }
    }

    if ((user.birthdate && !birthdate) || !isValidBirthday) {
      hasErrors = true;
    }

    return hasErrors;
  };

  const _updateMember = async () => {
    const org = meStore.me.membership.organization.id;

    let params = {
      role: 'patient',
      gender: gender.value.toLowerCase(),
      identifies_as: identity.value.toLowerCase(),
      name: name,
    };

    if (birthdate) {
      params.birthdate = birthdate;
    }

    try {
      setIsProcessing(true);
      const promises = [
        Api.Instance.current().post('/orgs/' + org + '/members/' + id, params),
      ];

      programAdmissions.forEach((admission) => {
        if (admission.updated) {
          // Explicitly remove fields that should not be in the payload
          const {
            updated,
            program,
            discharge_date,
            member,
            organization,
            created,
            modified,
            ...payload
          } = admission;

          promises.push(
            Api.Instance.current().post(
              `/orgs/${org}/admissions/${admission.id}`,
              payload,
            ),
          );
        }
      });

      groupAdmissions.forEach((admission) => {
        if (admission.updated) {
          const payload = {
            start_date: admission.start_date,
            end_date: admission.end_date,
          };
          promises.push(
            Api.Instance.current().patch(
              `/orgs/${org}/groups/${admission.group.id}/admissions/${admission.id}`,
              payload,
            ),
          );
        }
      });

      Promise.all(promises)
        .then((responses) => {
          setIsProcessing(false);
          EventEmitter.dispatch('closeModal', responses[0]);
        })
        .catch((error) => {
          setIsProcessing(false);
        });
    } catch (e) {
      setIsProcessing(false);
    }
  };

  return (
    <div className={'edit-member-form'}>
      <PfInput
        fullWidth
        placeholder="full name"
        label="Full Name*"
        val={name}
        setval={(val) => {
          setName(val);
        }}
        field={{
          test: memberStructure.name.test,
          name: 'name',
          required: memberStructure.name.required,
        }}
      />
      <PfInput
        fullWidth
        placeholder=""
        label="Email"
        val={user.email}
        disabled={'disabled'}
        field={{
          test: memberStructure.name.test,
          name: 'name',
          required: memberStructure.name.required,
        }}
      />
      <span className="notice">
        Email is not editable. Please contact Pathfinder to update a member's
        email.
      </span>

      <Stack direction="row" sx={{display: 'flex', marginBottom: 4}}>
        <Typography
          sx={{flex: 1, fontWeight: 700, color: Theme.colorPalette.darkest}}>
          Birthdate
        </Typography>
        <Stack sx={{flex: 2}}>
          <DatePicker
            InputProps={{
              sx: {
                border: isValidBirthday
                  ? '1px solid #43b017'
                  : '1px solid #f68e07',
                borderRadius: 1,
                marginLeft: 1,
                '.MuiOutlinedInput-input': {
                  py: '8px',
                  px: '11px',
                },
                '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              },
            }}
            fieldName="birthdate"
            onChange={(date) => {
              setBirthdate(date?.isValid() ? date.format('YYYY-MM-DD') : '');
            }}
            value={birthdate}
          />
          {!isValidBirthday && (
            <Typography color="error" sx={{marginLeft: 1, marginTop: 1}}>
              Member must be 13 and over
            </Typography>
          )}
        </Stack>
      </Stack>

      <PfDropdown
        label="Sex"
        val={gender}
        selectFunc={(sel) => setGender(sel)}
        list={genders}
        labelPath="name"
        classes="top-drop right-drop short-drop"
        field={{
          test: memberStructure.gender.test,
          name: 'gender',
          required: memberStructure.gender.required,
        }}
      />
      <PfDropdown
        label="Identifies as"
        val={identity}
        selectFunc={(sel) => setIdentity(sel)}
        list={identities}
        labelPath="name"
        classes="right-drop short-drop"
        field={{
          test: memberStructure.identity.test,
          name: 'identity',
          required: memberStructure.identity.required,
        }}
      />

      <Fragment>
        <hr />
        <Heading subtitle>Groups</Heading>
        {groupAdmissions && groupAdmissions.length > 0 ? (
          <ul className="inline-group-list">
            {groupAdmissions.map((admission, index) => {
              const isPermitted =
                RBACStore.hasEntityLevelAccess(ENTITY_LEVEL.all) ||
                RBACStore.hasAccessToEntity(
                  ENTITY_TYPES.group,
                  admission.group.id,
                );
              return (
                <li key={index}>
                  <div className={'tag-wrapper'}>
                    <Link
                      key={index}
                      to={`/groups/${admission.group.id}`}
                      className={'tag group-tag is-info'}
                      style={{
                        ...styleTag(
                          props.organizationStore.getGroupCategoryById(
                            admission.group?.category_id,
                          ).name,
                          true,
                        ),
                      }}>
                      {admission.group.name}
                    </Link>
                  </div>
                  <div className={'inline'}>
                    <span>
                      {admission.start_date ? (
                        <DatePicker
                          value={admission.start_date}
                          disabled={!isPermitted}
                          onChange={(val) =>
                            updateGroupAdmissions(
                              index,
                              'start_date',
                              val.format('YYYY-MM-DD'),
                            )
                          }
                        />
                      ) : (
                        'N/A'
                      )}
                    </span>
                    <span className={'middle'}>- to -</span>
                    <span>
                      {admission.end_date ? (
                        <DatePicker
                          value={admission.end_date}
                          disabled={!isPermitted}
                          onChange={(val) =>
                            updateGroupAdmissions(
                              index,
                              'end_date',
                              val.format('YYYY-MM-DD'),
                            )
                          }
                          minDate={groupAdmissions[index]?.start_date}
                        />
                      ) : (
                        <Button
                          color="primary"
                          onClick={() =>
                            updateGroupAdmissions(
                              index,
                              'end_date',
                              new moment().format('YYYY-MM-DD'),
                            )
                          }>
                          Add
                        </Button>
                      )}
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <Alert severity="info">
            Member currently not assigned to any groups
          </Alert>
        )}
        <hr />
        <Heading subtitle>Programs</Heading>
        {member.admissions?.length ? (
          <>
            <ul className="inline-group-list">
              {programAdmissions.map((admission, index) => {
                const isPermitted =
                  RBACStore.hasEntityLevelAccess(ENTITY_LEVEL.all) ||
                  RBACStore.hasAccessToEntity(
                    ENTITY_TYPES.program,
                    admission.program.id,
                  );
                return (
                  <li key={index}>
                    <div className={'tag-wrapper'}>
                      <Tag
                        key={index}
                        color="info"
                        className={'group-tag'}
                        style={{
                          ...styleTag(admission.program.name),
                        }}>
                        {admission.program.name}
                      </Tag>
                    </div>
                    <div className={'inline'}>
                      <span>
                        {admission.start_date ? (
                          <DatePicker
                            value={admission.start_date}
                            disabled={!isPermitted}
                            onChange={(val) =>
                              updateProgramAdmissions(
                                index,
                                'start_date',
                                val.format('YYYY-MM-DD'),
                              )
                            }
                          />
                        ) : (
                          'N/A'
                        )}
                      </span>
                      <span className={'middle'}>- to -</span>
                      <span>
                        {admission.end_date ? (
                          <DatePicker
                            value={admission.end_date}
                            disabled={!isPermitted}
                            onChange={(val) =>
                              updateProgramAdmissions(
                                index,
                                'end_date',
                                val.format('YYYY-MM-DD'),
                              )
                            }
                            minDate={programAdmissions[index]?.start_date}
                          />
                        ) : (
                          'N/A'
                        )}
                      </span>
                    </div>
                  </li>
                );
              })}
            </ul>
          </>
        ) : (
          <Alert>Member currently not enrolled in a program</Alert>
        )}
      </Fragment>
      <hr />
      <Level>
        <Level.Side>
          <Level.Item>
            <Button
              color="primary"
              disabled={_checkForErrors() ? true : false}
              isprocessing={isProcessing}
              onClick={_updateMember}>
              Update Member
            </Button>
          </Level.Item>
        </Level.Side>
        <Level.Side>
          <Level.Item>
            <Button
              color="danger"
              onClick={() => EventEmitter.dispatch('closeModal', null)}>
              Cancel
            </Button>
          </Level.Item>
        </Level.Side>
      </Level>
    </div>
  );
};

export default inject(
  'meStore',
  'organizationStore',
  'RBACStore',
)(observer(EditMemberForm));
