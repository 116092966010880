// @flow
import React from 'react';
import type {Node} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Stack, Typography, Select} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {AccessControl, ENTITY_LEVEL} from 'modules/rbac';

type PropsType = {
  filter: string,
  setFilter: (string) => void,
  refetch: () => void,
};

export const ListHeader = ({filter, setFilter, refetch}: PropsType): Node => {
  const {organizationStore, RBACStore} = useStores();
  const navigate = useNavigate();

  const allGroupsLabel = 'All Available Groups';
  const filters = !RBACStore.hasEntityLevelAccess(ENTITY_LEVEL.all)
    ? [
        {
          name: allGroupsLabel,
          value: 'All',
        },
        {
          name: 'My Groups',
          value: 'My',
        },
      ]
    : [
        {
          name: allGroupsLabel,
          value: 'All',
        },
      ];

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  const allGroupItems = filters.map(({name, value}) => ({
    label: name,
    value: value,
  }));

  const groupNameItems = organizationStore.groupCategories.map(
    ({name, id}) => ({
      label: name,
      value: id,
    }),
  );

  return (
    <Stack
      direction={{xs: 'column', sm: 'row'}}
      justifyContent="space-between"
      spacing={{xs: 1, sm: 2}}>
      <Select
        value={filter}
        onChange={handleChange}
        items={[...allGroupItems, ...groupNameItems]}
        canUnselect={false}
      />
      {RBACStore.hasAccess(AccessControl.groups.createGroup) &&
      organizationStore.groupCategories?.length > 0 ? (
        <Button
          variant="contained"
          onClick={() => {
            navigate('/groups/new');
          }}>
          Add New Group
        </Button>
      ) : (
        <Typography
          variant="body2"
          align="center"
          sx={{width: '200px', color: Theme.colorPalette.red}}>
          Contact a Pathfinder Admin to Add Groups
        </Typography>
      )}
    </Stack>
  );
};
