// @flow
import React, {useState, useEffect, useCallback} from 'react';
import type {Node} from 'react';
import {Button} from 'react-bulma-components';
import type {UIStaffType} from '@wellstone-solutions/common';
import {Progress, Stack} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';

type PropTypes = {
  admin: UIStaffType,
  remove: (staff: UIStaffType) => Promise<void>,
};

export const AdminAuthsToRemove = ({admin, remove}: PropTypes): Node => {
  const {staffStore} = useStores();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getStaff = useCallback(async () => {
    setIsLoading(true);
    await staffStore.getStaffMember({id: admin.id});
    setIsLoading(false);
  }, [staffStore, admin]);

  useEffect(() => {
    getStaff();
  }, [getStaff]);

  if (isLoading) {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        style={styles.loadingContainer}>
        <Progress />
      </Stack>
    );
  }

  return (
    <Button
      fullwidth={true}
      inverted={false}
      color="primary"
      outlined={false}
      onClick={() => remove(admin)}>
      Remove from Pathfinder
    </Button>
  );
};

const styles = {
  removeButton: {
    marginBottom: 10,
  },
  loadingContainer: {
    width: '260px',
    height: '140px',
  },
};
