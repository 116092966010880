// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import {Modal} from '@mui/material';

import {Stack, Select} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {PfButton} from 'components/shared/PfButton';
import {CreateWizard} from '../CreateWizard';
import {ALL_PROGRAMS_FILTER} from '../../constants';
import {AccessControl} from 'modules/rbac';

type PropsType = {
  filter: string,
  setFilter: (string) => void,
  refetch: () => void,
};

export const ListHeader = ({filter, setFilter, refetch}: PropsType): Node => {
  const [showModal, setShowModal] = useState(false);
  const {meStore, RBACStore} = useStores();

  const filters = [
    {
      name: ALL_PROGRAMS_FILTER.name,
      value: ALL_PROGRAMS_FILTER.value,
    },
    // We filter by program
    ...meStore.myPrograms.map((program) => ({
      name: program.name,
      value: program.id,
    })),
  ];

  const toggleModal = (event, reason) => {
    if (reason !== 'backdropClick') {
      setShowModal(!showModal);
    }
  };

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <Stack
      direction={{xs: 'column', sm: 'row'}}
      justifyContent="space-between"
      alignItems="center"
      spacing={2}>
      <Select
        value={filter}
        onChange={handleChange}
        items={filters.map(({name, value}) => ({
          label: name,
          value: value,
        }))}
        canUnselect={false}
        containerProps={{
          sx: {
            minWidth: {
              xs: '100%',
              sm: 300,
            },
          },
        }}
      />
      {RBACStore.hasAccess(AccessControl.staff.addStaff) && (
        <div>
          <PfButton color="primary" onClick={toggleModal}>
            Add New Staff Member
          </PfButton>
          <Modal open={showModal} onClose={toggleModal}>
            <div>
              <CreateWizard onCancel={toggleModal} />
            </div>
          </Modal>
        </div>
      )}
    </Stack>
  );
};
