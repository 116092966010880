import {makeObservable, action, observable} from 'mobx';

class AppStore {
  menuShowing = false;
  apiMaintenanceMode = false;

  constructor(rootStore) {
    makeObservable(this, {
      menuShowing: observable,
      enableMaintenanceMode: action,
      apiMaintenanceMode: observable,
      toggle: action,
    });

    this.rootStore = rootStore;
    this.toggle = this.toggle.bind(this);
    this.apiMaintenanceMode = false;

    window.addEventListener('resize', (e) => {
      if (
        window.innerWidth < 1087 &&
        !this.rootStore.stores.tutorialStore.isActive
        // !document.body.classList.contains('is-tutorial')
      ) {
        this.menuShowing = false;
      }
    });
  }

  toggle() {
    // this.menuShowing = document.body.classList.contains('is-tutorial')
    this.menuShowing = this.rootStore.stores.tutorialStore.isActive
      ? true
      : !this.menuShowing;
  }

  clear() {
    // this.menuShowing = document.body.classList.contains('is-tutorial')
    this.menuShowing = this.rootStore.stores.tutorialStore.isActive
      ? true
      : false;
  }

  enableMaintenanceMode() {
    this.apiMaintenanceMode = true;
  }
}

export default AppStore;
