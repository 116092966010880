import {makeObservable, observable, action, computed} from 'mobx';
import * as Sentry from '@sentry/react';
import {KEYUTIL, RSAKey} from 'jsrsasign';
import PubNub from 'pubnub';
import moment from 'moment';

import {Storage} from '@wellstone-solutions/web';
import {Api} from '@wellstone-solutions/common';
import type {PubSubMessageType} from 'types';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';
import {Types} from '../constants/Messages';
import {
  getIdFromPubnubChannelName,
  getTimestampFromPubnubTimetoken,
} from '../utils/Utils';
import {adjustTimetoken} from '../utils/timetoken';

// keys for PRODUCTION
// const AsyncStorage = localStorage;
const apsEnv = process.env.REACT_APP_APS_ENV;
const apsTopic = process.env.REACT_APP_APS_TOPIC;
const publishKey = process.env.REACT_APP_PUBNUB_PUBLISH_KEY;
const subscribeKey = process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY;

const asyncLastViewedPrefix = 'last_viewed';

class PubnubStore {
  pubnub = {};
  connected = false;
  messages = [];
  lastViewed = '00000000000000001';
  isPartnerTyping = false;
  activeChannel = null;
  orgInbox = '';
  tokenRefreshTimer = null;
  parsedToken = null;

  init() {
    this.connect();
  }

  constructor(rootStore) {
    makeObservable(this, {
      pubnub: observable,
      activeChannel: observable,
      isPartnerTyping: observable,
      messages: observable,
      setActive: action,
      connected: observable,
      connect: action,
      addMessage: action,
      clearMessages: action,
      verifyMessage: action,
      handleMessage: action,
      getChannelHistory: action,
      _loadAsync: action,
      lastViewed: observable,
      updateLastViewed: action,
      firstMessage: computed,
      lastMessage: computed,
    });
    this.rootStore = rootStore;
    this.publish = this.publish.bind(this);
    this._loadAsync = this._loadAsync.bind(this);
    this.updateLastViewed = this.updateLastViewed.bind(this);
    this._loadAsync();
  }

  clear() {
    if (this.tokenRefreshTimer) {
      clearTimeout(this.tokenRefreshTimer);
      this.tokenRefreshTimer = null;
    }
  }

  _loadAsync() {
    this.lastViewed = Storage.getItem(asyncLastViewedPrefix);
  }

  async connect() {
    if (this.connected && this.pubnub) {
      return this.pubnub;
    }

    this.pubnub = await this.connectToPubnub();
    // Fetch and set the Pubnub token
    await this.setPubnubToken();

    if (this.pubnub) {
      this.connected = true;
      this.subscribeToInbox();
    }
    return this.pubnub;
  }

  setActive(ch) {
    this.activeChannel = ch;
  }

  addMessage = (message: PubSubMessageType) => {
    if (!this._messageExists(message)) {
      this.messages.push(message);
    }
  };

  clearMessages = () => {
    this.messages = [];
  };

  connectToPubnub = async () => {
    const {meStore} = this.rootStore.stores;

    if (!meStore.me?.id) {
      throw new Error('Bridge PubNub Connection Error');
    }

    // Initialize the PubNub instance
    const pubnub = await new PubNub({
      publishKey: publishKey,
      subscribeKey: subscribeKey,
      userId: meStore.me.id || 'undefined_bridge_user',
      subscribeRequestTimeout: 60000,
      presenceTimeout: 122,
      ssl: true,
    });

    // Store the PubNub instance for future use
    this.pubnub = pubnub;

    // Set flag that we are connected
    this.connected = true;

    return pubnub;
  };

  deleteMessage(channel, timetoken) {
    // the start and end timetoken parameter values are 1/10 nanosecond (last digit of timetoken)
    // apart to delete the message stored at the end parameter's timetoken value.
    const end = timetoken;
    const start = adjustTimetoken(timetoken, 1);

    // The Delete Message API looks backwards in time. The start timetoken parameter
    // should be more forward in time than the end parameter: end < start.
    this.pubnub.deleteMessages({
      channel,
      start,
      end,
    });
  }

  sortMessageKeysForSignature(data) {
    if (typeof data !== 'object' || data instanceof Array) {
      return data;
    }
    const sorted = {};
    Object.keys(data)
      .sort()
      .forEach((key) => {
        sorted[key] = this.sortMessageKeysForSignature(data[key]);
      });
    return sorted;
  }

  makeNameSafe = (name) => {
    const parts = name.split();
    if (parts.length === 2) {
      return parts[0] + parts[1][0] + '.';
    } else if (
      parts.length === 3 &&
      ['jr', 'jr.', 'sr', 'sr.'].includes(parts[2].toLowerCase())
    ) {
      return parts[0] + parts[1][0] + '.';
    } else if (parts.length === 3) {
      return parts[0] + parts[2][0] + '.';
    } else {
      return parts[0];
    }
  };

  async setPubnubToken() {
    const response = await Api.Instance.v2().post('/auth/pubnub-token/');
    const parsedToken = this.pubnub.parseToken(response.data);
    this.parsedToken = parsedToken;
    this.pubnub.setToken(response.data);
    this.scheduleTokenRefresh(parsedToken.timestamp, parsedToken.ttl);
  }

  hasChannelAuth(channel: string) {
    return (
      this.parsedToken &&
      Object.keys(this.parsedToken.resources.channels).includes(channel)
    );
  }

  // Set a timer to refresh the Pubnub token
  scheduleTokenRefresh(issueTimestamp: number, ttlMinutes: number): void {
    if (this.tokenRefreshTimer) {
      clearTimeout(this.tokenRefreshTimer);
    }

    // Convert issueTimestamp (seconds) and ttlMinutes (minutes) to milliseconds
    const issueTimeMs = issueTimestamp * 1000;
    const ttlMs = ttlMinutes * 60 * 1000;

    // Refresh the token 30 seconds before expiration
    const tokenExpiryMs = issueTimeMs + ttlMs;
    const refreshDelayMs = tokenExpiryMs - Date.now() - 30000;

    this.tokenRefreshTimer = setTimeout(
      () => this.setPubnubToken(),
      refreshDelayMs,
    );
  }

  publish = async (channelName, message) => {
    if (message.data.content && message.data.content !== '') {
      const {meStore} = this.rootStore.stores;
      const {me, privateKey} = meStore;

      message.data.name = this.makeNameSafe(me.name);
      message.data.author = me.id;
      message.data.user_role = 'staff';
      message.data.authorTT = moment().valueOf();
      message.data = this.sortMessageKeysForSignature(message.data);
      const readyToSign = JSON.stringify(message.data);

      message.signature = this.signMessage(readyToSign, privateKey);
      message.pn_fcm = {
        notification: {
          title: 'New message from ' + me.membership.organization.name,
        },
        data: {
          channel: message.channel,
          type: Types.MESSAGE,
        },
      };
      message.pn_apns = {
        aps: {
          alert: 'New message from ' + me.membership.organization.name, //message.data.content,
          sound: 'melody',
        },
        channel: message.channel,
        type: Types.MESSAGE,
        pn_push: [
          {
            targets: [
              {
                environment: apsEnv,
                topic: apsTopic,
                pushType: 'alert',
              },
            ],
            version: 'v2',
          },
        ],
      };

      return new Promise((resolve) => {
        if (this.isTutorialActive()) {
          this.rootStore.stores.tutorialStore.stores.pubnubStore.publish(
            channelName,
            message,
          );
          resolve();
        } else {
          this.pubnub.publish(
            {message, channel: channelName},
            (status, response) => {
              //TODO: we should check and make sure the status is 200, otherwise implement a retry
              // https://www.pubnub.com/docs/sdks/javascript/api-reference/publish-and-subscribe#publishing-messages-reliably
              // Update the channel activity
              const {channelStore} = this.rootStore.stores;
              const channelID = channelName.split('.')[1];
              const last_channel_activity = new Date(
                getTimestampFromPubnubTimetoken(response.timetoken),
              );
              const last_viewed = {
                [meStore.me.id]: last_channel_activity,
              };

              channelStore.updateChannelData(
                {id: channelID},
                {
                  last_channel_activity,
                  last_viewed,
                },
              );
              resolve({status, response});
            },
          );
        }
      });
    }
  };

  signMessage = (item, prvKeyPEM) => {
    let rsa = new RSAKey();
    let hashAlg = 'sha256';
    rsa.readPrivateKeyFromPEMString(prvKeyPEM);
    return rsa.sign(item, hashAlg);
  };

  async verifyAll() {
    const messages = this.messages.map(async (message) => {
      if (!message.message.verified) {
        const verified = await this.verifyMessage(message);
        return verified.item;
      }
      return message;
    });

    this.messages = await Promise.all(messages);
  }

  verifyMessage = async (item) => {
    const {me} = this.rootStore.stores.meStore;
    const {author} = item.message.data;
    const data = this.sortMessageKeysForSignature(item.message.data);
    const str = JSON.stringify(data);

    const publicPEM =
      author === '__server__'
        ? me.system_public_key
        : author === me.id
        ? me.public_key
        : await this.getPEMFromChannel(item);

    // const publicPEM = this.getPEMFromChannel(item);
    if (publicPEM) {
      let sMsg = str;
      let hSig = item.message.signature;
      let pubKey = KEYUTIL.getKey(publicPEM);
      item.message.verified = pubKey.verify(sMsg, hSig);
    }
    return {item, verified: item.message.verified};
  };

  getPEMFromChannel = async (item) => {
    // const {me} = this.rootStore.stores.meStore;
    // TODO: this is VERY wrong. It is only testing against the active channel
    // we need to implement a channelStore (*CommunityStore is antiquated)
    let channel = this.activeChannel;
    // let author = item.message.data.author;
    let pem;

    // if (channel) {
    //   if (me.id === author) {
    //     pem = me.public_key;
    //   } else {
    //     channel.members.forEach((member, m) => {
    //       if (member.id === author) {
    //         pem = member.public_key;
    //       }
    //     });
    //   }
    // }

    // if (!channel) {
    //   await channelStore.requestAll();
    //   channel = this.rootStore.stores.channelStore.getChannel(
    //     item.message.channel,
    //   );
    // }

    if (channel) {
      channel.members.forEach((member, m) => {
        if (member.id === item.message.data.author) {
          pem = member.public_key;
        }
      });
    }

    if (pem && pem !== '') {
      return pem;
    } else {
      console.log("couldn't find PEM");
      return null;
    }
  };

  loadAllMessages = (items) => {
    items.forEach((item) => this.handleMessage(item, false));
  };

  async handleMessage(item, isLive = false) {
    const {calendarStore, appNotificationStore} = this.rootStore.stores;
    switch (item.message.data.type) {
      case Types.MESSAGE:
        await this.handleChatMessage(item);
        break;
      case Types.CALENDAR_EVENT:
        // receiving a live calendar notification indicates a calendar event has changed
        // refetch calendar events to get the latest changes
        appNotificationStore.handlePublishedMessage(
          item,
          isLive,
          calendarStore.getAllCalendarEvents.bind(calendarStore),
        );

        break;
      case Types.CHANNEL:
        // Refresh pubnub token to get updated auth when a new channel is created
        const {action, content} = item.message.data;
        if (action === 'created' && !this.hasChannelAuth(content)) {
          await this.setPubnubToken();
        }
        break;
      case Types.SESSION_DOCUMENT:
        appNotificationStore.handlePublishedMessage(item, isLive);
        break;
      case Types.EVENT:
        // notificationStore.set(item.message.event_type, item, true);
        break;
      default:
      // notificationStore.set(item.message.event_type, item, true);
    }
  }

  handleChatMessage = async (item) => {
    const {notificationStore, meStore, channelStore} = this.rootStore.stores;

    item.message.verified = this.verifyMessage(item).verified;
    await channelStore.newChannelActivity(item.message.channel);
    // "current" channel
    if (this.activeChannel?.name === item.message.channel) {
      this.addMessage(item);
      // If it is our own message we do not update the last_viewed
      // as this should have been done when we published the message
      if (item.message.data.author !== meStore.me.id) {
        channelStore.updateChannelData(
          {id: getIdFromPubnubChannelName(item.message.channel)},
          {last_viewed: {[meStore.me.id]: item.timetoken}},
        );
      }
    } else if (item.message.data.author !== meStore.me.id) {
      channelStore.addCountToUnreadChannel(item.message.channel);
      channelStore.updateLocalChannelData(
        getIdFromPubnubChannelName(item.message.channel),
        {
          last_channel_activity: getTimestampFromPubnubTimetoken(
            item.timetoken,
          ),
        },
      );
      // TODO fully remove along with messageStore
      // messageStore.set(item, true);
    }

    if (item.message.data.author !== meStore.me.id) {
      notificationStore.showDesktopNotification(
        item.message.data.name,
        'New Message', // item.message.data.content
      );
    }
  };

  updateLastViewed(timetoken) {
    let newVal = parseInt(timetoken, 10);
    newVal = 2 + newVal;
    this.lastViewed = JSON.stringify(newVal);
    Storage.setItem(asyncLastViewedPrefix, this.lastViewed);
  }

  // _______________________________________________________

  messageAdapter = (obj) => {
    if (obj.message && !obj.message.data) {
      obj.message.data = {
        author: obj.message.author,
        targets: obj.message.targets,
        name: obj.message.name,
        content: obj.message.content,
      };
    }

    if (obj.entry && !obj.entry.data) {
      obj.entry.data = {
        author: obj.entry.publisher,
        targets: obj.entry.targets,
        name: obj.entry.name,
        content: obj.entry.msg,
      };
    }

    return {
      timetoken: obj.timetoken,
      message: obj.message ? obj.message : obj.entry,
    };
  };

  getChannelHistory = async (historyObj, callback) => {
    if (this.isTutorialActive()) {
      const responseMessages = this.rootStore.stores.tutorialStore.stores.pubnubStore.getChannelHistory();
      this.messages = [...responseMessages, ...this.messages];
      callback(responseMessages);
      return;
    }

    // add one to the count that the user wants to fetch.
    // this extra fetched message will tell us if there are more
    // messages to fetch or if they've retrieved all available messages
    let countWithExtraMsg = historyObj.count;
    if (historyObj.count !== null && Number(historyObj.count)) {
      countWithExtraMsg = historyObj.count + 1;
    }

    this.pubnub.fetchMessages(
      {...historyObj, count: countWithExtraMsg},
      (status, response) => {
        let messagesToStore = [];
        let hasMoreMessages = false;

        if (status.error) {
          showAlert(status.errorData.message, ALERT_TYPES.ERROR);
          Sentry.captureException(
            new Error(
              `PubNub fetchMessages Call in getChannelHistory Failed: Status: ${status.errorData.message}`,
            ),
          );
          callback?.(messagesToStore, {
            hasError: !!status.error,
            hasMoreMessages,
          });
          return;
        }

        if (Object.keys(response?.channels || []).length > 0) {
          const responseMessages =
            response.channels[historyObj.channels[0]] || [];

          messagesToStore = this._removeExtraMessage(
            responseMessages,
            historyObj.count,
          );

          hasMoreMessages = responseMessages.length > messagesToStore.length;

          if (historyObj.channels[0] === this.activeChannel.name) {
            this._storeAndVerifyMessages(messagesToStore);
          }
        }

        callback?.(messagesToStore, {
          hasError: !!status.error,
          hasMoreMessages,
        });
      },
    );
  };

  _removeExtraMessage = (messages, count) =>
    messages.length > count ? messages.slice(1) : messages;

  _storeAndVerifyMessages = (messages) => {
    if (messages.length > 0) {
      const messagesToAdd = messages
        .map((msg) => (this._messageExists(msg) ? false : msg))
        .filter(Boolean);

      this.messages = [...messagesToAdd, ...this.messages];

      this.verifyAll();
    } else {
      // if there are not, we've reached the end
      if (this.messages.length > 0) {
        showAlert(
          'Reached the end: There is no more history to this conversation',
          ALERT_TYPES.INFO,
        );
      }
    }
  };

  getAllMyHistory = async (historyObj, callback) => {
    this.pubnub.fetchMessages(historyObj, (status, response) => {
      if (status.error) {
        Sentry.captureException(
          new Error(
            `PubNub fetchMessages Call in getAllMyHistory Failed: Status: ${status.errorData.message}`,
          ),
        );
      }
      callback(status, response);
    });
  };

  getMessageCounts = async (channels, timetokens, callback) => {
    if (channels.length && timetokens.length) {
      this.pubnub.messageCounts(
        {
          channels: channels,
          channelTimetokens: timetokens,
        },
        (status, results) => {
          if (!status.error) {
            callback(results);
          } else {
            if (status.category === 'PNAccessDeniedCategory') {
              const removeArray = status.errorData.payload.channels;
              const newChannels = channels.filter(
                (el) => !removeArray.includes(el),
              );
              const newTimetokens = timetokens.filter((el, i) => {
                return !removeArray.includes(channels[i]);
              });
              this.getMessageCounts(newChannels, newTimetokens);
            }
          }
          // handle status, response
          console.log('status', status);
        },
      );
    } else {
      console.log('no channels to check for messages');
    }
  };

  messageListener = {
    /*
    Contains callback for real-time PN messages. Established on PN connect.
    Invoked by the real-time PN event listener and not history fetches.
    Realtime (isLive) chat messages require the raw PN message to get
    transformed with the messageAdapter method. App Notification messages
    dont need this so just pass raw PN message to those handlers.
    */
    message: (message) => {
      const {appNotificationStore} = this.rootStore.stores;
      const isAppNotification = appNotificationStore.allNotificationChannels.includes(
        message.channel,
      );

      const messagePayload = isAppNotification
        ? message
        : this.messageAdapter(message);

      this.handleMessage(messagePayload, true);
    },
  };

  presenceListener = {
    presence: (presenceEvent) => {
      const {meStore} = this.rootStore.stores;
      if (
        presenceEvent.uuid !== meStore.me.id &&
        this.activeChannel.name === presenceEvent.channel
      ) {
        if (presenceEvent.state) {
          this.isPartnerTyping = presenceEvent.state.meTyping;
        }
      }
    },
  };

  subscribeToInbox = () => {
    const {me} = this.rootStore.stores.meStore;
    this.orgInbox = 'inbox.' + me.membership.organization.id;

    this.pubnub.removeListener(this.messageListener);
    this.pubnub.addListener(this.messageListener);
    this.pubnub.subscribe({
      channels: [this.orgInbox],
      channelGroups: ['cg-personal.' + me.id],
    });

    // TODO: implement when beginning to use org inbox messages (org events etc.) again
    // this.getAllMyHistory(
    //   { channels: [this.orgInbox], end: this.lastViewed, count: 100 },
    //   (historyStatus, historyResponse) => {
    //     if (!historyStatus.error) {
    //       this.processAllHistory(historyResponse.channels);
    //     } else {
    //       console.error(
    //         'Error requesting channel history.',
    //         historyStatus.errorData.message,
    //         historyStatus.errorData.payload?.channels
    //       );
    //     }
    //   }
    // );
  };

  processAllHistory(channels) {
    const historyArray = Object.values(channels);
    var merged = [].concat.apply([], historyArray);
    merged.forEach((message) => {
      this.handleMessage(message, true);
    });
  }

  unsubscribeFromInbox = () => {
    this.pubnub.removeListener(this.messageListener);
    this.pubnub.unsubscribeAll();
    console.log('UN-SUBSCRIBED FROM Inbox');
  };

  subscribeToChannelPresence = (channels) => {
    if (this.isTutorialActive()) {
      return;
    }

    this.pubnub.removeListener(this.presenceListener);
    this.pubnub.addListener(this.presenceListener);
    this.pubnub.subscribe({channels});
    console.log('SUBSCRIBED To Presence');
  };

  unsubscribeFromChannelPresence = (channels) => {
    if (this.isTutorialActive()) {
      return;
    }

    this.pubnub.removeListener(this.presenceListener);
    this.pubnub.unsubscribe({channels});
    this.activeChannel = null;
  };

  get firstMessage() {
    return this.messages.length ? this.messages[0] : null;
  }
  get lastMessage() {
    return this.messages[this.messages.length - 1];
  }

  async setState(state) {
    return new Promise((resolve) => {
      if (this.isTutorialActive()) {
        return;
      }

      this.pubnub.setState(state, (status, response) =>
        resolve({status, response}),
      );
    });
  }

  isTutorialActive() {
    return this.rootStore.stores.tutorialStore.isActive;
  }
  clearLastViewed() {
    Storage.removeItem(asyncLastViewedPrefix);
    this.lastViewed = null;
  }

  // Private

  _messageExists(message: PubSubMessageType) {
    return this.messages.find((m) => m.timetoken === message.timetoken);
  }
}

export default PubnubStore;
