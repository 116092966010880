// @flow
import React from 'react';
import type {Node} from 'react';
import {useMutation} from '@tanstack/react-query';
import {useNavigate} from 'react-router-dom';
import {groupsCreate} from '@wellstone-solutions/api/v2/groups';
import type {Group as GroupType} from '@wellstone-solutions/api/v2/types';
import {
  Box,
  Button,
  Icon,
  IconNames,
  Stack,
  Typography,
} from '@wellstone-solutions/web';
import {BridgeEventSources, Hooks, Theme} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {useEventSource} from 'hooks/useEventSource';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';
import {Form} from './Form';
import {groupSchema} from '../constants';

const FORM_ID = 'new_group_form';

export const New = (): Node => {
  const {groupStore, programStore, meStore} = useStores();

  useEventSource(BridgeEventSources.GROUPS_PAGE);

  const mutation = useMutation({
    mutationFn: groupsCreate,
    onSuccess: () => {},
  });

  const navigate = useNavigate();

  // Not using model Factory due to new apiv2 shape
  const group: GroupType = {
    name: '',
    description: '',
    isPublic: true,
    organization: meStore.me.membership.organization.id,
    programs: [],
    category: '',
  };

  const form = Hooks.useForm({
    initialValues: group,
    schema: groupSchema,
    validationEngine: 'yup',
    onSubmit: async (values, {resetForm}) => {
      try {
        const response = await mutation.mutateAsync(values);

        resetForm();

        // TODO: Replace with ReactQuery way once list is using RQ
        await programStore.getAllPrograms();

        Promise.all([
          groupStore.loadGroups(meStore.me.membership.organization.id, false),
          groupStore.requestMyGroups(meStore.permittedGroupIds),
        ]);
        // End replace

        navigate(`/groups/${response.data.id}`);
      } catch (error) {
        Object.entries(error.response?.data).forEach(([key, values]) => {
          form.setFieldError(key, values.join(' '));
        });

        showAlert(`Unable to save new group.`, ALERT_TYPES.ERROR);
      }
    },
  });

  const saveDisabled = !form.isValid || !form.dirty || form.isSubmitting;

  return (
    <Box>
      <Stack direction={{xs: 'column', md: 'row'}}>
        <Stack
          flex={1}
          direction="column"
          justifyContent={{md: 'space-between'}}
          sx={{mb: 4}}>
          <Typography
            variant="h4"
            sx={{color: Theme.colorPalette.darkest, mb: 1, fontWeight: 'bold'}}>
            Create Group Details
          </Typography>
          <Typography sx={{color: Theme.colorPalette.darkest, mb: 2}}>
            All fields are required unless indicated as optional.
          </Typography>

          <Form form={form} formId={FORM_ID} />
          <Stack direction="row" sx={{mt: 5}}>
            <Button
              variant="outlined"
              onClick={() => navigate('/groups')}
              sx={styles.cancelButton}>
              Cancel
            </Button>
            <Button
              form={FORM_ID}
              type="submit"
              disabled={saveDisabled}
              variant="contained"
              data-testid={'group-add-new'}
              sx={styles.saveButton}>
              <Icon
                name={IconNames.FloppyDisk}
                color={
                  saveDisabled
                    ? Theme.colorPalette.mediumDark
                    : Theme.colorPalette.lightest
                }
                size={20}
              />
              <Typography sx={{px: 1, py: 0.5}}>Save</Typography>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

const styles = {
  cancelButton: {
    width: 125,
  },
  saveButton: {
    width: 125,
    ml: 3,
  },
};
