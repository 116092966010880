import React from 'react';

import moment from 'moment';
import BridgeIntroScreen from '../../components/tutorial/screens/BridgeIntro';
import CompanionScreen from '../../components/tutorial/screens/Companion';
import StaffRolesScreen from '../../components/tutorial/screens/StaffRoles';
import IntroScreen from '../../components/tutorial/screens/Intro';
import OutroScreen from '../../components/tutorial/screens/Outro';
import {getGender, getIdentity} from '../../utils/Forms';
import {DISCHARGE_STATUSES} from '../../constants/Categories';

export const counsellor_steps = [
  'welcome',
  'bridge',
  'companion',
  'roles-overview',
  'member-overview-counselor',
  'group-overview-counselor',
  'search',
  'member-detail',
  'recent-events',
  'member-detail-enrollments',
  'live-chat',
  'activity',
  'range',
  'filtering',
  'aggregate',
  'checkins',
  'discharge-from-group',
  'continue-group-discharge',
  'group-discharge-summary',
  'finish',
];

export const primary_counsellor_steps = [
  'welcome',
  'bridge',
  'companion',
  'roles-overview',
  'member-overview-counselor',
  'group-overview-counselor',
  'search',
  'member-detail',
  'recent-events',
  'member-detail-enrollments',
  'community-access',
  'community-access-1',
  'live-chat',
  'activity',
  'range',
  'filtering',
  'aggregate',
  'checkins',
  'discharge-from-program',
  'continue-program-discharge',
  // 'skip-program-re-enrollment',
  'program-discharge-summary',
  'finish',
];

export const admin_steps = [
  'welcome',
  'bridge',
  'companion',
  'roles-overview',
  'member-overview',
  'add-new-member',
  'enroll-in-program',
  'yes-to-add-primary',
  'add-to-primary',
  'add-member-complete',
  'group-overview',
  'add-group',
  'pre-staff-overview',
  'staff-overview',
  'add-counselor',
  'select-counselor',
  'assign-counselor',
  'move-to-groups',
  'move-to-group',
  'add-member-to-group',
  'add-member-to-group-1',
  'search',
  'member-detail',
  'recent-events',
  'member-detail-enrollments',
  'community-access',
  'community-access-1',
  'live-chat',
  'activity',
  'range',
  'filtering',
  'aggregate',
  'checkins',
  'discharge-from-program',
  'continue-program-discharge',
  'skip-program-re-enrollment',
  'program-discharge-summary',
  'finish',
];

const start = moment();
const end = start.clone().add(90, 'days');
const previous = start.clone().subtract(30, 'days');

export const coreSteps = [
  {
    id: 'welcome',
    baseURL: '/dashboard/members',
    title: 'Welcome to the Pathfinder tutorial',
    jsx: true,
    content: <IntroScreen key="intro" />,
    proceed: ['nextButton'],
  },
  {
    id: 'bridge',
    baseURL: '/dashboard/members',
    title: 'Pathfinder Bridge',
    jsx: true,
    content: <BridgeIntroScreen key="bridge" />,
    proceed: ['nextButton'],
  },
  {
    id: 'companion',
    baseURL: '/dashboard/members',
    title: 'Pathfinder Companion',
    jsx: true,
    content: <CompanionScreen key="companion" />,
    proceed: ['nextButton'],
  },
  {
    id: 'roles-overview',
    baseURL: '/dashboard/members',
    title: 'Roles Overview',
    jsx: true,
    content: <StaffRolesScreen key="roles" />,
    proceed: ['nextButton'],
  },
  {
    id: 'member-overview',
    baseURL: '/dashboard/members',
    title: 'Members Overview',
    content: [
      {
        type: 'text',
        data:
          'All clients/patients are entered into Pathfinder as Members. You can view all Members accessible to you by clicking Members in the side menu.',
      },
      {
        type: 'blueText',
        data: 'CLICK Add New Member',
      },
    ],
    proceed: ['add-new-member-button'],
  },
  {
    id: 'member-overview-counselor',
    baseURL: '/dashboard/members',
    title: 'Members Overview (Counselor)',
    content: [
      {
        type: 'text',
        data:
          'All clients/patients are entered into Pathfinder as Members. You can view all Members accessible to you by clicking Members in the side menu.',
      },
      {
        type: 'nextButton',
      },
    ],
    proceed: ['nextButton'],
  },
  {
    id: 'add-new-member',
    baseURL: '/dashboard/members',
    title: 'Add a New Member',
    content: [
      {
        type: 'text',
        data: 'Adding a Member is as simple as entering their name and email.',
      },
      {
        type: 'text',
        data:
          'This will create a Pathfinder account if the email does not exist, or connect your organization to their existing account.',
      },
      {
        type: 'blueText',
        data: 'CLICK Create New Member',
      },
    ],
    prefill: {
      name: 'Jodie Johnson',
      email: 'jodie@test.com',
      gender: getGender('female'),
      identity: getIdentity('female'),
    },
    proceed: ['add-new-member-modal-button'],
  },
  {
    id: 'enroll-in-program',
    title: 'Enroll in a Program',
    content: [
      {
        type: 'text',
        data:
          'Once the Member has been created, they need to be enrolled in a Program, as well as a Resource Group.',
      },
      {
        type: 'infoBox',
        data:
          'Members are enrolled in Resource Groups with assigned staff to provide outreach, and general support.',
      },
      {
        type: 'text',
        data:
          'The End Date is an estimate to help remind Counselors when a Member is nearing Program completion.',
      },
      {
        type: 'blueText',
        data: 'CLICK Enroll',
      },
    ],
    prefill: {
      program: 'iop',
      resourceGroup: 'resource-group-a',
      'start-date-month-dropdown': start.month(),
      'start-date-date-dropdown': start.date(),
      'start-date-year-dropdown': start.year(),
      'end-date-month-dropdown': end.month(),
      'end-date-date-dropdown': end.date(),
      'end-date-year-dropdown': end.year(),
    },
    proceed: ['enroll-new-member-modal-button'],
  },
  {
    id: 'yes-to-add-primary',
    title: 'Optionally Add to Primary',
    content: [
      {
        type: 'text',
        data:
          "If you're ready to add the Member to a Primary Group, you can do so.",
      },
      {
        type: 'blueText',
        data: 'CLICK Yes',
      },
    ],
    disable: ['no-to-add-primary-button'],
    proceed: ['yes-to-add-primary-button'],
  },
  {
    id: 'add-to-primary',
    title: 'Add to Primary Group',
    content: [
      {
        type: 'text',
        data:
          'Once added to a Primary Group, Members are able to reach their Primary Counselor from the Pathfinder Companion app.',
      },
      {
        type: 'infoBox',
        data:
          'Active Member counts are visible to help avoid over-filling certain Groups.',
      },
      {
        type: 'blueText',
        data: 'SELECT the Group and CLICK Add to Primary Group A.',
      },
    ],
    prefill: {
      'start-date-month-dropdown': start.month(),
      'start-date-date-dropdown': start.date(),
      'start-date-year-dropdown': start.year(),
      'end-date-month-dropdown': end.month(),
      'end-date-date-dropdown': end.date(),
      'end-date-year-dropdown': end.year(),
    },
    proceed: ['add-to-primary-button'],
  },
  {
    id: 'add-member-complete',
    title: 'Easy enough!',
    content: [
      {
        type: 'text',
        data:
          'Once a Member has been created, they can log in to the Companion app with their email address.',
      },
      {
        type: 'appStoreLinks',
      },
      {
        type: 'phoneImage',
        data: {
          url:
            'https://storage.googleapis.com/pf-bucket-default-prod/tutorial/login_screen.png',
        },
      },
      {
        type: 'text',
        data: "Now, let's take a look at Groups.",
      },
      {
        type: 'blueText',
        data: 'CLICK Groups',
      },
    ],
    proceed: ['side-panel-route-groups'],
  },
  {
    id: 'group-overview',
    baseURL: '/dashboard/groups',
    title: 'Group Overview',
    content: [
      {
        type: 'text',
        data:
          'A Group is a collection of Members often associated with a recurring in-person group.',
      },
      {
        type: 'text',
        data:
          'It can be a Primary Group, or Specialty Group such as for people suffering from Co-occurring disorders.',
      },
      {
        type: 'blueText',
        data: 'CLICK Add New Group',
      },
    ],
    proceed: ['add-group-button'],
  },
  {
    id: 'group-overview-counselor',
    baseURL: '/dashboard/groups',
    title: 'Group Overview',
    content: [
      {
        type: 'text',
        data:
          'A Group is a collection of Members often, but not always associated with a recurring in-person group.',
      },
      {
        type: 'text',
        data:
          'It can be a Primary Group, or Specialty Group such as for people suffering from Co-occurring disorders.',
      },
      {
        type: 'nextButton',
      },
    ],
    proceed: ['nextButton'],
  },
  {
    id: 'add-group',
    baseURL: '/dashboard/groups',
    title: 'Add a New Group',
    content: [
      {
        type: 'text',
        data: 'Groups can be created for any kind of categorical need though.',
      },
      {
        type: 'text',
        data: 'Name and type are the only requirements.',
      },
      {
        type: 'svg',
        data: {
          size: {
            width: '60%',
          },
          url:
            'https://storage.googleapis.com/pf-bucket-default-prod/tutorial/groups.svg',
        },
      },
      {
        type: 'blueText',
        data: 'CLICK Create New Group',
      },
    ],
    prefill: {
      name: 'Co-occurring Disorders',
      street1: '123 Main St.',
      street2: '',
      category: 'Specialty',
      city: 'Minneapolis',
      zip_code: '55111',
      state: 'MN',
    },
    proceed: ['add-group-modal-button'],
  },
  {
    id: 'pre-staff-overview',
    baseURL: '/dashboard/groups',
    title: 'Groups form Community',
    content: [
      {
        type: 'text',
        data:
          'While a Member is in treatment, Primary Groups determine what peers a Member can contact on Pathfinder. Any Members who share a Primary Group can connect through a request process.',
      },
      {
        type: 'phoneImage',
        data: {
          url:
            'https://storage.googleapis.com/pf-bucket-default-prod/tutorial/directory.png',
        },
      },
      {
        type: 'blueText',
        data: 'CLICK Staff',
      },
    ],
    proceed: ['side-panel-route-staff'],
  },
  {
    id: 'staff-overview',
    baseURL: '/dashboard/staff',
    title: 'Staff Overview',
    content: [
      {
        type: 'text',
        data:
          'Staff Members serve one of 3 different roles: Counselor, Admin or Super Admin. When Staff are added, they must be given a role.',
      },
      {
        type: 'infoBox',
        data: 'For security purposes, roles cannot be changed.',
      },
      {
        type: 'blueText',
        data: 'CLICK Add New Staff Member',
      },
    ],
    proceed: ['add-staff-member-button'],
  },
  {
    id: 'add-counselor',
    baseURL: '/dashboard/staff',
    title: 'Add a New Counselor',
    content: [
      {
        type: 'text',
        data:
          'Counselors are the most common role in most organizations, and in general use Bridge to review checkins, evaluate Member data, and contact Members & other Staff.',
      },
      {
        type: 'blueText',
        data: 'CLICK Create New Counselor',
      },
    ],
    prefill: {
      name: 'Jackie Smith',
      email: 'jackie@test.com',
      role: 'counselor',
      gender: getGender('female'),
      identity: getIdentity('female'),
    },
    proceed: ['add-staff-member-modal-button'],
  },
  {
    id: 'select-counselor',
    baseURL: '/dashboard/staff',
    title: 'Select Counselor',
    content: [
      {
        type: 'text',
        data:
          'Admins & Super Admins have the freedom to contact Members, and review and evaluate Member data as well. They also are responsible for enrolling and editing limited Member data.',
      },
      {
        type: 'text',
        data:
          'Lastly, they are in charge of granting and revoking permissions and access for Counselors.',
      },
      {
        type: 'blueText',
        data: 'CLICK Jackie Smith to take a look at her account',
      },
    ],
    proceed: ['goto-tutorial-user-0'],
  },
  {
    id: 'assign-counselor',
    baseURL: '/dashboard/staff/tutorial-user-0',
    title: 'Assign Counselor to a Group',
    content: [
      {
        type: 'text',
        data:
          'Assigning Group permissions for a Counselor allows them to see and communicate with Members in that Group.',
      },
      {
        type: 'text',
        data:
          'Permissions can be granted for a Primary Counselor, a Resource Specialist, or temporarily for a float Counselor if the situation warrants.',
      },
      {
        type: 'blueText',
        data: 'CLICK Add Jackie Smith to Co-occurring Disorders',
      },
    ],
    prefill: {
      selectedGroup: {
        id: 'tutorial-group-0',
        name: 'Co-occurring Disorders',
      },
    },
    highlight: ['permissions-block'],
    disable: ['selectedGroup'],
    proceed: ['add-permissions-button'],
  },
  {
    id: 'move-to-groups',
    baseURL: '/dashboard/staff/tutorial-user-0',
    title: 'Great!',
    content: [
      {
        type: 'text',
        data: "We're almost set up!",
      },
      {
        type: 'text',
        data:
          "Jodie's account and enrollments have now been created, and she can install and log into Companion. Jackie's Staff account has been created and she has been assigned a Group. Let's take another look at Groups.",
      },
      {
        type: 'blueText',
        data: 'CLICK Groups',
      },
    ],
    proceed: ['side-panel-route-groups'],
  },
  {
    id: 'move-to-group',
    baseURL: '/dashboard/staff/tutorial-user-0',
    title: 'Great!',
    content: [
      {
        type: 'text',
        data:
          "We still need Jodie to be enrolled in Co-occurring Disorders to close the loop. Let's take a look at Co-Occurring Disorders, and add Jodie there.",
      },
      {
        type: 'blueText',
        data: 'CLICK Co-occurring Disorders',
      },
      // {
      //   type: 'linkTo',
      //   data: '/dashboard/groups/tutorial-group-0',
      //   text: 'Go to Co-occurring Disorders',
      // },
    ],
    proceed: ['goto-group-0'],
  },
  {
    id: 'add-member-to-group',
    baseURL: '/dashboard/groups/tutorial-group-0',
    title: 'Group Members',
    content: [
      {
        type: 'text',
        data:
          'Adding a Member to a Primary or Specialty Group allows them to connect with the other peers in that Group.',
      },
      {
        type: 'text',
        data:
          'It also allows the Member to reach out and connect with any Counselor assigned to that Group.',
      },
      {
        type: 'blueText',
        data: 'CLICK Add Member to Co-occurring Disorders',
      },
    ],
    proceed: ['add-group-member-button'],
  },
  {
    id: 'add-member-to-group-1',
    baseURL: '/dashboard/groups/tutorial-group-0',
    title: 'Add Member to Group',
    content: [
      {
        type: 'text',
        data: 'Members can be added to any number of Groups.',
      },
      {
        type: 'text',
        data:
          'Any current membership in other Groups will appear next to their name.',
      },
      {
        type: 'blueText',
        data: 'SELECT the checkbox next to Jodie and CLICK Add Member',
      },
    ],
    prefill: {
      'start-date-month-dropdown': start.month(),
      'start-date-date-dropdown': start.date(),
      'start-date-year-dropdown': start.year(),
      'end-date-month-dropdown': end.month(),
      'end-date-date-dropdown': end.date(),
      'end-date-year-dropdown': end.year(),
    },
    proceed: ['add-group-member-modal-button'],
  },
  {
    id: 'search',
    title: 'Search',
    content: [
      {
        type: 'text',
        data:
          'You can search by name or email for Members or Staff from anywhere on Bridge.',
      },
      {
        type: 'blueText',
        data:
          'CLICK the search field at the top of the page. TYPE the first few letters and look for Xavier. CLICK his name once it appears.',
      },
    ],
    highlight: ['search-input'],
    proceed: ['button-xavier'],
  },
  {
    id: 'member-detail',
    baseURL: '/dashboard/members/599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
    title: 'Member Detail Overview',
    content: [
      // {
      //   type: 'text',
      //   data:
      //     "This is an important place filled with valuable information to help understand your patient's recovery.",
      // },
      {
        type: 'text',
        data:
          'Members check-in using their Pathfinder Companion app, letting Staff know how they’re doing mentally, physically and emotionally. A color-coded indicator shows how long it’s been since their last checkin.',
      },
      {
        type: 'phoneVideo',
        data: {
          size: {
            maxHeight: 'calc(100% - 1.8vh)',
          },
          url:
            'https://storage.googleapis.com/pf-bucket-default-prod/tutorial/checkin.mp4',
        },
      },
      {
        type: 'nextButton',
      },
    ],
    highlight: ['since-checkin-counter'],
    proceed: ['nextButton'],
  },
  {
    id: 'recent-events',
    // baseURL: '/members/599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
    title: 'Recent Events',
    content: [
      {
        type: 'text',
        data:
          "See some of the most recent and important events at a glance. Recent checkins, relationships they've formed on Companion and more.",
      },
      {
        type: 'nextButton',
      },
    ],
    highlight: ['recent-events-wrapper'],
    proceed: ['nextButton'],
  },
  {
    id: 'member-detail-enrollments',
    title: 'Programs & Groups',
    content: [
      {
        type: 'text',
        data:
          'You can also see the active Groups and Program for the Member along with the start and end dates.',
      },
      {
        type: 'nextButton',
      },
    ],
    highlight: ['program-tag', 'group-tag'],
    proceed: ['nextButton'],
  },
  {
    id: 'community-access',
    title: 'Community Access',
    content: [
      {
        type: 'text',
        data:
          "While Xavier has been enrolled in a Program, as well as a Primary Group, he has not yet been given Peer-to-peer access. Any Admin, or the Primary Counselor can activate or de-activate a Member's Peer-to-peer access at any time.",
      },
      {
        type: 'phoneImage',
        data: {
          url:
            'https://storage.googleapis.com/pf-bucket-default-prod/tutorial/directory_empty.png',
        },
      },
      {
        type: 'blueText',
        data: 'CLICK the Peer-to-peer Access switch',
      },
    ],
    // highlight: ['community-switch-block'],
    proceed: ['community-switch-button'],
  },
  {
    id: 'community-access-1',
    title: 'Peer-to-peer Access Confirmed',
    content: [
      {
        type: 'text',
        data:
          'Peer-to-peer Access means that a Member can see and be seen by other peers in their Groups.',
      },
      {
        type: 'phoneImage',
        data: {
          url:
            'https://storage.googleapis.com/pf-bucket-default-prod/tutorial/directory.png',
        },
      },
      {
        type: 'nextButton',
      },
    ],
    highlight: ['community-switch-block'],
    proceed: ['nextButton'],
  },
  {
    id: 'live-chat',
    // baseURL: '/dashboard/members/599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
    title: 'Live Chat',
    content: [
      {
        type: 'text',
        data: 'Start or continue an existing conversation.',
      },
      {
        type: 'blueText',
        data: "TYPE a message to Xavier asking how he's doing.",
      },
      {
        type: 'phoneVideo',
        data: {
          size: {
            maxHeight: 'calc(100% - 1.8vh)',
          },
          url:
            'https://storage.googleapis.com/pf-bucket-default-prod/tutorial/receive_notification.mp4',
        },
      },
      {
        type: 'text',
        data:
          'Xavier will get a notification and can respond from the Companion app.',
      },
      {
        type: 'text',
        data:
          'Send private 1-on-1 reminders, inquire about a concerning checkin, or just reach out to see how a Member is doing.',
      },

      /*{
        type: 'text',
        data:
          'You can start or continue a conversation with your client. They will respond to you from their Pathfinder Companion app. Send a private reminder for an upcoming 1-on-1, or just reach out to see how they’re doing.',
      },
      {
        type: 'text',
        data: 'Click Start Conversation, and ask your client how their doing.',
      },
      {
        type: 'phoneVideo',
        data: {
          size: {
            maxHeight: 'calc(100% - 1.8vh)',
          },
          url:
            'https://storage.googleapis.com/pf-bucket-default-prod/tutorial/receive_notification.mp4',
        },
      },*/
      {
        type: 'nextButton',
      },
    ],
    highlight: ['chat-wrapper'],
    proceed: ['nextButton'],
  },
  {
    id: 'activity',
    baseURL: '/dashboard/members/599be126-03bc-4ae5-9cb7-87dfe8d64f1b',
    title: 'Activity',
    content: [
      {
        type: 'text',
        data:
          "The Activity section is where you can look at the entire history of Members' events, and look for incidents that may help you in supporting the Member, clues that may spur important conversations.",
      },
      {
        type: 'text',
        data:
          'You can also view aggregate data for your Group, and Admins can view for the entire organization.',
      },
      {
        type: 'remoteImage',
        data: {
          size: {
            maxWidth: '100%',
          },
          url:
            'https://storage.googleapis.com/pf-bucket-default-prod/tutorial/other_activity_sections.png',
        },
      },
      {
        type: 'blueText',
        data: 'SELECT the Activity tab',
      },
    ],
    highlight: ['tab-member-detail-activity'],
    proceed: ['tab-member-detail-activity'],
  },
  {
    id: 'range',
    baseURL: '/dashboard/members/599be126-03bc-4ae5-9cb7-87dfe8d64f1b/activity',
    title: 'Range of Time',
    content: [
      {
        type: 'text',
        data:
          "As we noted earlier, we haven't seen a checkin from Xavier for over 2 weeks. Select a larger range of time to get a more holistic view.",
      },
      {
        type: 'nextButton',
        text: 'Set date range to last 4 weeks.',
      },
    ],
    disable: ['chart-legend-check-in', 'chart-legend-new-relationship'],
    highlight: ['member-overview-data-chart'],
    proceed: ['nextButton'],
  },
  {
    id: 'filtering',
    baseURL: '/dashboard/members/599be126-03bc-4ae5-9cb7-87dfe8d64f1b/activity',
    title: 'Filtering Events',
    content: [
      {
        type: 'text',
        data:
          "Sometimes it's difficult to look at data side-by-side. You can toggle an event label to hide or show those data.",
      },
      {
        type: 'blueText',
        data: 'CLICK to hide "New Relationships"',
      },
    ],
    prefill: {
      // This is technically for the completion of the prev step.
      memberDateRangePicker: {
        selection: {
          endDate: start.toDate(),
          key: 'selection',
          startDate: previous.toDate(),
        },
      },
    },
    disable: ['chart-legend-check-in'],
    proceed: ['chart-legend-new-relationship'],
  },
  {
    id: 'aggregate',
    baseURL: '/dashboard/members/599be126-03bc-4ae5-9cb7-87dfe8d64f1b/activity',
    title: 'Aggregate events by ...',
    content: [
      {
        type: 'text',
        data:
          "Often times it's important to look at events through a different lens.",
      },
      {
        type: 'blueText',
        data: 'SELECT Time of Day from the View By dropdown',
      },
      {
        type: 'text',
        data:
          'Notice the increased activity that becomes apparent late at night.',
      },
      {
        type: 'text',
        data:
          'Information like this can help Counselors to have more informative conversations with Members.',
      },
      {
        type: 'nextButton',
        text: 'Check ins',
      },
    ],
    disable: ['chart-legend-check-in', 'chart-legend-new-relationship'],
    highlight: ['member-view-by-dropdown', 'member-overview-data-chart'],
    proceed: ['nextButton'],
  },
  {
    id: 'checkins',
    baseURL: '/dashboard/members/599be126-03bc-4ae5-9cb7-87dfe8d64f1b/activity',
    title: 'Check ins',
    content: [
      {
        type: 'text',
        data:
          'While some events are informative just by seeing when they happened. Events such as checkins have much more to offer.',
      },
      {
        type: 'text',
        data:
          'Was the Member happy when they checked in? Were they suffering from body aches? How often do they have issues sleeping?',
      },
      {
        type: 'text',
        data:
          "Try a few of the filters to see the different lenses through which to view Members' checkins.",
      },
      {
        type: 'infoBox',
        data:
          "It is important to remember, data doesn't appear on its own. Establishing a strong relationship with the Member, and explaining how checking in helps you both is vital to encouraging Members to check-in on a regular basis.",
      },
      {
        type: 'nextButton',
      },
    ],
    highlight: [
      'member-checkins-filter-button',
      'member-overview-checkin-chart',
    ],
    proceed: ['nextButton'],
  },
  // NOT BEING USED
  // {
  //   id: 'prepare-to-discharge',
  //   title: 'Prepare to Discharge',
  //   content: [
  //     {
  //       type: 'text',
  //       data:
  //         "---- Now that we've seen everything... preparing to discharge ----",
  //     },
  //     {
  //       type: 'blueText',
  //       data: 'Click General Tab',
  //     },
  //   ],
  //   highlight: ['tab-member-detail-general'],
  //   proceed: ['tab-member-detail-general'],
  // },
  {
    id: 'discharge-from-program',
    title: 'Discharge from Program',
    content: [
      {
        type: 'text',
        data: 'The last step is to look at the discharge process.',
      },
      {
        type: 'blueText',
        data: 'CLICK Discharge Now',
      },
    ],
    highlight: ['program-block-0'],
    proceed: ['program-discharge-button-0'],
  },
  {
    id: 'continue-program-discharge',
    title: 'Program Discharge Form',
    content: [
      {
        type: 'text',
        data:
          'As mentioned earlier, the end date was an estimate. When discharging the form will default to today, but you can adjust if needed, as well as noting whether it was a successful discharge.',
      },
      {
        type: 'blueText',
        data: 'CLICK Discharge',
      },
    ],
    prefill: {
      'program-status-dropdown': DISCHARGE_STATUSES[0],
      'discharge-date-month-dropdown': start.month(),
      'discharge-date-date-dropdown': start.date(),
      'discharge-date-year-dropdown': start.year(),
    },
    disable: ['program-status-dropdown'],
    proceed: ['program-discharge-form-button'],
  },
  {
    id: 'skip-program-re-enrollment',
    title: 'Skip Program Re-Enrollment',
    content: [
      {
        type: 'text',
        data:
          'Once a Member has been discharged from a Program, they will no longer see or be seen by peers in the Companion app directory.',
      },
      {
        type: 'infoBox',
        data:
          'IMPORTANT: IF peers have connected as friends prior to being discharged though, those contacts will remain.',
      },
      {
        type: 'text',
        data:
          "Sometimes a Member may be entering a new Program immediately following, you can re-enroll them immediately. We'll skip this for now though.",
      },
      {
        type: 'blueText',
        data: 'Click No',
      },
    ],
    disable: ['yes-to-add-primary-button'],
    proceed: ['no-to-add-primary-button'],
  },
  {
    id: 'program-discharge-summary',
    title: 'Program Discharge Summary',
    content: [
      {
        type: 'text',
        data: 'One last thing!',
      },
      {
        type: 'infoBox',
        data:
          'Once a member has been discharged, All of their saved contacts will stay in place. After a period of time determined by the organization, Staff to Member connections will be removed. Contact as well as Emergency Contact information will be provided in its place.',
      },
      {
        type: 'nextButton',
        text: 'Finish',
      },
    ],
    proceed: ['nextButton'],
  },
  {
    id: 'discharge-from-group',
    title: 'Discharge from Group',
    content: [
      {
        type: 'text',
        data: 'The last step is to look at the release process.',
      },
    ],
    highlight: ['group-block-1'],
    proceed: ['group-discharge-button-1'],
  },
  {
    id: 'continue-group-discharge',
    title: 'Group Discharge Form',
    content: [
      {
        type: 'text',
        data:
          'As mentioned earlier, the end date was an estimate. When releasing, the form will default to today, but you can adjust if needed.',
      },
      {
        type: 'blueText',
        data: 'Click Release Now',
      },
    ],
    prefill: {
      'discharge-date-month-dropdown': start.month(),
      'discharge-date-date-dropdown': start.date(),
      'discharge-date-year-dropdown': start.year(),
    },
    proceed: ['group-discharge-form-button'],
  },
  {
    id: 'group-discharge-summary',
    title: 'Group Discharge Summary',
    content: [
      {
        type: 'text',
        data:
          'Once a Member has been released from a Group, they will no longer see or be seen by peers of that Group in the Companion app directory.',
      },
      {
        type: 'infoBox',
        data:
          'IMPORTANT: IF peers have connected as friends prior to being released though, those contacts will remain.',
      },
      {
        type: 'nextButton',
        text: 'Finish',
      },
    ],
    proceed: ['nextButton'],
  },
  {
    id: 'finish',
    jsx: true,
    content: <OutroScreen key="outro" />,
    proceed: ['finishButton', 'restart-button'],
  },
];
