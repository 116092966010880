// @flow
import React from 'react';
import type {Node} from 'react';
import {Stack, Switch, Typography} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';

type PropsType = {
  form: any,
};

export const ChatSwitch = ({form}: PropsType): Node => {
  return (
    <Stack direction="column" sx={{flex: 1}}>
      <Stack direction="row" alignItems="center">
        <Typography
          sx={{fontWeight: 'bold', color: Theme.colorPalette.darkest}}>
          Enable Chat
        </Typography>
        <Switch
          label=""
          checked={form.values.isPublic}
          onChange={(event) => {
            form.setFieldTouched('isPublic');
            form.setFieldValue('isPublic', event.target.checked);
          }}
          sx={{ml: 5}}
        />
      </Stack>
      <Typography>{`Turning this ${
        form.values.isPublic ? 'off disables' : 'on enables'
      } messaging access in Companion between group members.`}</Typography>
    </Stack>
  );
};
