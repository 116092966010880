// @flow
// React
import React from 'react';
import type {Node} from 'react';
import moment from 'moment';
import {Tag} from 'react-bulma-components';

import {getSeverityColor} from 'utils/Charts';

const MAX_DAYS = 20;

type PropsType = {
  checkIn?: {
    created: string,
  },
};

export const SinceCheckin = ({checkIn}: PropsType): Node => {
  if (!checkIn) {
    return <Tag>No recent check-ins</Tag>;
  }

  const daysDiff = moment().diff(checkIn.created, 'days');
  const backgroundColor = getSeverityColor(daysDiff, MAX_DAYS);
  const dayLabel = daysDiff === 1 ? 'day' : 'days';

  return (
    <Tag
      style={{
        ...styles.dayIndicator,
        ...{backgroundColor},
      }}>
      {daysDiff} {dayLabel} since last check in
    </Tag>
  );
};

const styles = {
  dayIndicator: {
    color: 'white',
    marginTop: 6,
  },
};
