// React
import React, {useContext} from 'react';

// Third party
import {DateRangePicker} from 'react-date-range';
import 'emoji-mart/css/emoji-mart.css';

// Bulma
import {Heading, Level} from 'react-bulma-components';

// WS
import {Select} from '@wellstone-solutions/web';
import {getDateRange} from 'utils/Charts';
import {aggregatingList} from 'constants/Aggregators';
import PfModal from 'components/modal/PfModal';
import {Palette} from 'palette';
import MembersOverview from 'components/MembersOverview';
import ScrollTo from 'components/tutorial/scrollto';
import {MemberDetailTabContext} from '../../contexts/MemberDetailTabContext';

export const ActivityTabContent = () => {
  const {state, setState, memberNotifications, onSelectAggregator} = useContext(
    MemberDetailTabContext,
  );

  const {
    aggregator,
    activityData,
    appnavigationData,
    badgeData,
    checkinData,
    habitData,
    engagementData,
    rawEventData,
    selectionRange,
  } = state || {};

  const handleDateSelect = (ranges) => {
    const {startDate, endDate} = ranges.selection || ranges.range1;

    setState({
      ...state,
      selectionRange: {
        startDate,
        endDate,
      },
    });
  };

  return (
    <div>
      <ScrollTo stepId="range" />
      <Level>
        <Level.Side>
          <Level.Item>
            <Heading style={styles.heading} size={4}>
              Community Activity
            </Heading>
          </Level.Item>
        </Level.Side>
        <Level.Side>
          <Level.Item>
            <PfModal
              button={{
                outlined: true,
                text: getDateRange({state}),
                style: styles.button,
              }}
              modal={{
                closeOnBlur: true,
                showClose: false,
                closeOnEsc: true,
              }}
              id={'memberDateRangePicker'}
              tutorialFunction={(v) => {
                handleDateSelect(v);
              }}>
              <DateRangePicker
                ranges={[selectionRange]}
                rangeColors={[Palette.LINK]}
                id={'memberDateRangePicker'}
                onChange={(v) => {
                  handleDateSelect(v);
                }}
              />
            </PfModal>
          </Level.Item>
          <Level.Item>
            <span style={styles.viewBy}>View by</span>
          </Level.Item>
          <Level.Item style={styles.relative}>
            <Select
              value={aggregator}
              onChange={(event) => onSelectAggregator(event.target.value)}
              items={aggregatingList.map((agg) => ({
                value: agg.key,
                label: agg.name,
              }))}
              id="member-view-by-dropdown"
              canUnselect={false}
            />
          </Level.Item>
        </Level.Side>
      </Level>
      <MembersOverview
        aggregator={aggregator}
        habitData={habitData}
        checkinData={checkinData}
        activityData={activityData}
        badgeData={badgeData}
        appnavigationData={appnavigationData}
        engagementData={engagementData}
        memberNotifications={memberNotifications}
        start={selectionRange.startDate}
        end={selectionRange.endDate}
        rawEvents={rawEventData}
        showDetailsModal={false}
      />
    </div>
  );
};

const styles = {
  button: {
    height: 40,
    right: 0,
  },
  relative: {
    position: 'relative',
    height: 40,
  },
  heading: {
    color: Palette.GREY_4,
  },
  viewBy: {
    fontWeight: 'bold',
    color: Palette.GREY_4,
  },
};
