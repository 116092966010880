// React
import React from 'react';

// Bulma
import {Loader} from 'react-bulma-components';

class PfLoader extends React.Component {
  render() {
    const {isCentered = false} = this.props;
    const extraStyles = isCentered ? styles.centered : {};

    return (
      <div style={{...styles.loaderContainer, ...extraStyles}}>
        <Loader style={{...this.props.loaderStyle, ...styles.loader}} />
      </div>
    );
  }
}
const styles = {
  centered: {
    display: 'flex',
    justifyContent: 'center',
  },
  loaderContainer: {
    width: '100%',
    padding: 20,
  },
  loader: {
    // border: "8px solid #7ac0c3",
    // borderTopColor: "transparent",
    // borderRightColor: "transparent",
    // marginLeft: "auto",
    // marginRight: "auto"
  },
};
export default PfLoader;
