import React, {useState, useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {Icon} from '@wellstone-solutions/web';
import {Modal} from 'react-bulma-components';

import Button from '../tutorial/button';
import {EventEmitter} from '../../utils/EventEmitter';
import {Palette} from '../../palette';

/**
 * A functional modal component.
 * @param {Object} props
 */
const PfModal = (props) => {
  const {
    id,
    /**
     * @type {object} Props to apply to the core bulma modal.
     */
    modal,
    /**
     * @type {function} Callback when modal is opened.
     */
    onOpen,
    /**
     * @type {Object} Props to send to onOpen.
     */
    onOpenParams,
    /**
     * @type {function} Callback when modal is closed.
     */
    onCloseModal,
    /**
     * @type {object | null} Data about displayed icon.
     */
    icon = null,
    /**
     * @type {object | null} Props for the button.
     */
    button,
    /**
     * @type {Boolean}
     */
    disabled,
    /**
     * @type {Object} Styles for the button.
     */
    buttonStyle,
    /**
     * @type {Boolean}
     */
    large = false,
    children,
    /**
     * @type {Object} Styles for the button container object.
     */
    buttonContainerStyle,
    /**
     *  @type {Object} Custom styles for content wrapper
     */
    contentStyle = {},
    /**
     * @type {Boolean} Is the modal open by default.
     */
    isModalOpen = false,
    tooltip,
    tutorialFunction,
    tutorialStore,
  } = props;

  /**
   * @type {[Boolean, Function]} Loading
   */
  const [isShowing, setIsShowing] = useState(isModalOpen);
  const [isTutorialComponent, setIsTutorialComponent] = useState(false);

  const modalProps = modal;
  if (document.body.classList.contains('is-tutorial')) {
    modalProps.closeOnEsc = false;
  }

  /**
   * Listen and stop listening for a closeModal event.
   */
  useEffect(() => {
    EventEmitter.subscribe('closeModal', _close);
    return () => {
      EventEmitter.unsubscribe('closeModal', _close);
    };
  });

  useEffect(() => {
    if (
      tutorialStore.prefillComponents.hasOwnProperty(id) &&
      !isTutorialComponent
    ) {
      tutorialFunction(tutorialStore.prefillComponents[id]);
      setIsTutorialComponent(true);
    }
  }, [
    id,
    isTutorialComponent,
    tutorialFunction,
    tutorialStore,
    tutorialStore.currentStep,
    tutorialStore.prefillComponents,
  ]);

  /**
   * Open the modal.
   */
  const _open = () => {
    setIsShowing(true);
    if (onOpen) {
      onOpen(onOpenParams);
    }
  };

  /**
   * Close the modal.
   * @param {Object} data Response data.
   */
  const _close = (data) => {
    if (isShowing === false) {
      return;
    }

    setIsShowing(false);

    if (
      data &&
      (data.status === 'ok' || data.isSuccess) &&
      typeof onCloseModal === 'function'
    ) {
      onCloseModal(data);
    }
  };

  const hasIcon = !!icon;
  const iconProps = hasIcon
    ? {
        color: Palette.GREY_1,
        style: styles.buttonIcon,
        ...icon,
      }
    : {};

  return (
    <div
      style={buttonContainerStyle}
      data-tooltip={tooltip}
      className="has-tooltip-left">
      <Button
        color={button.color || 'primary'}
        disabled={disabled}
        inverted={button.inverted}
        outlined={button.outlined ? true : false}
        onClick={() => _open()}
        id={button.id || ''}
        style={buttonStyle}
        className={button.className}>
        {button.text}
        {hasIcon && (
          <Icon onClick={() => _open(onOpen, onOpenParams)} {...iconProps} />
        )}
      </Button>

      <Modal
        show={isShowing}
        onClose={_close}
        className="pfModal"
        {...modalProps}>
        <Modal.Content
          className={large ? 'large-modal' : null}
          style={{...styles.modal, ...contentStyle}}>
          {children}
        </Modal.Content>
      </Modal>
    </div>
  );
};

const styles = {
  modal: {
    backgroundColor: 'white',
    padding: 30,
    borderRadius: 10,
    width: 'auto',
  },
  buttonIcon: {
    marginLeft: '0.5rem',
  },
};

export default inject('tutorialStore')(observer(PfModal));
