// @flow
import {action, computed, observable, runInAction, makeObservable} from 'mobx';
import type {IObservableMap} from 'mobx';
import RootStore from 'mobx/RootStore';
import {Models} from '@wellstone-solutions/common';
import type {
  ApiResourceCategoryType,
  UIResourceCategoryType,
  UIResourceQuestionType,
  UIResourceAnswerType,
  ApiSearchFilterType,
} from '@wellstone-solutions/common';
import type {SearchFilterModel} from '@wellstone-solutions/web';

const {Resource, ResourceCategory, ResourceAnswer, ResourceQuestion} = Models;

export class ResourceStore {
  rootStore: RootStore;
  categories: IObservableMap<
    string, // Category ID
    Array<UIResourceCategoryType>,
  > = observable.map();
  questions: IObservableMap<
    string, // Category ID
    Array<UIResourceQuestionType>,
  > = observable.map();
  answers: IObservableMap<
    string, // Question ID
    Array<UIResourceAnswerType>,
  > = observable.map();

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      clear: action,
      categoriesArray: computed,
    });

    this.rootStore = rootStore;
  }

  // Categories

  async getCategories({
    forceFetch = false,
    states,
  }: {
    forceFetch?: boolean,
    states?: Array<string> | null | void,
  } = {}): Promise<Array<UIResourceCategoryType>> {
    // if there are categories already loaded, do not fetch new categories.
    if (!forceFetch && this.categoriesArray.length > 0) {
      return Promise.resolve(this.categoriesArray);
    }

    const result = await ResourceCategory.getAll({
      params: {
        state: states,
      },
    });

    const apiCategories = result.isSuccess ? result.data.groups : [];

    const uiCategories: UIResourceCategoryType[] = apiCategories
      ? apiCategories.map((category: ApiResourceCategoryType) =>
          ResourceCategory.toUI(category),
        )
      : [];

    runInAction(() => {
      this.categories.clear();
      uiCategories.map((category) =>
        this.categories.set(category.id, category),
      );
    });

    return this.categoriesArray;
  }

  get categoriesArray(): Array<UIResourceCategoryType> {
    // Converts map values iterator to array
    return Array.from(this.categories.values());
  }

  // Questions

  async getQuestionsByCategory({
    categoryId,
    states,
    forceFetch = false,
  }: {
    categoryId: string,
    states?: Array<string> | null | void,
    forceFetch?: boolean,
  }): Promise<Array<UIResourceQuestionType>> {
    // if there are questions already loaded for this category, do not fetch new questions.
    if (!forceFetch && this._hasKeyAndArray(this.questions, categoryId)) {
      return Promise.resolve(this.questions.get(categoryId));
    }

    const result = await ResourceQuestion.getByCategory(categoryId, {
      params: {
        state: states,
      },
    });

    const apiQuestions = result.isSuccess ? result.data.questions : [];

    const questions = apiQuestions
      ? apiQuestions.map((question) => ResourceQuestion.toUI(question))
      : [];

    runInAction(() => {
      this.questions.set(categoryId, questions);
    });

    return this.questions.get(categoryId);
  }

  // Answers

  async getAnswersByQuestion({
    categoryId,
    questionId,
    states,
    forceFetch = false,
  }: {
    categoryId: string,
    questionId: string,
    states?: Array<string> | null | void,
    forceFetch?: boolean,
  }): Promise<Array<UIResourceQuestionType>> {
    // if there are answers already loaded for this question, do not fetch new answers.
    if (!forceFetch && this._hasKeyAndArray(this.answers, questionId)) {
      return Promise.resolve(this.answers.get(questionId));
    }

    const result = await ResourceAnswer.getByQuestion(categoryId, questionId, {
      params: {
        state: states,
      },
    });

    const apiAnswers = result.isSuccess ? result.data.answers : [];

    const answers = apiAnswers
      ? apiAnswers.map((answer) => ResourceAnswer.toUI(answer))
      : [];

    runInAction(() => {
      this.answers.set(questionId, answers);
    });

    return this.answers.get(questionId);
  }

  async search({
    states,
    filterModel,
  }: {
    states: Array<string>,
    filterModel: SearchFilterModel,
  }): Promise<void> {
    if (filterModel.items?.length <= 0) {
      this.clear();
      return Promise.resolve();
    }

    const apiSearchFilters = this._buildApiFilters(filterModel);
    const result = await Resource.search(states, apiSearchFilters);

    if (result.isSuccess) {
      this.clear();
      const uiResult = Resource.toUI(result.data);
      const {questions, answers, categories} = uiResult;

      runInAction(() => {
        // populate categories map
        categories.forEach((category) =>
          this.categories.set(category.id, category),
        );

        // populate questions map (many to many relationship)
        questions.forEach((question) =>
          question.categories.forEach((categoryId) =>
            this._createOrAddToMap(categoryId, question, this.questions),
          ),
        );

        // populate answers map
        answers.forEach((answer) =>
          this._createOrAddToMap(answer.questionId, answer, this.answers),
        );
      });
    }
  }

  clear(): void {
    this.categories.clear();
    this.answers.clear();
    this.questions.clear();
  }

  // if the `mapKey` doesnt exist on the `map` create it
  // if the `mapKey` exists on the `map` add the `item` to its value array
  _createOrAddToMap(
    mapKey: string,
    item:
      | UIResourceAnswerType
      | UIResourceQuestionType
      | UIResourceCategoryType,
    map: IObservableMap<string, any[]>,
  ): void {
    const mapValues = map.get(mapKey);
    if (!mapValues) {
      map.set(mapKey, [item]);
    } else {
      mapValues.push(item);
    }
  }

  _hasKeyAndArray(map: IObservableMap<string, any[]>, mapKey: string): boolean {
    return map.has(mapKey) && map.get(mapKey).length > 0;
  }

  _buildApiFilters(filterModel: SearchFilterModel): ApiSearchFilterType[] {
    return filterModel.items.map((filter) => ({
      field: filter.field.replace(/_/g, '.'), // useQueryString uses underscores for field name, convert to dots for api
      value: filter.value,
      operator: filterModel.linkOperator,
      filter_by: filter.operatorValue,
    }));
  }
}
