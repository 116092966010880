// @flow
import {makeObservable} from 'mobx';
import RootStore from 'mobx/RootStore';
import {EntityAC, ENTITY_LEVEL, ENTITY_TYPES} from './constants';
import {ROLES} from 'utils/Permissions';

export class RBACStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {});
    this.rootStore = rootStore;
  }

  get meStore(): any {
    return this.rootStore.stores.meStore;
  }

  get myRole(): any {
    return this.meStore.me.membership.role;
  }

  /*** current user is within the list of passed in roles ***

    This is designed be used with the AccessControl constant defined in `rbac/constants.js`
    By using the AccessControl constant it will pass in an array of roles into this method.
    It is highly recommended you use the AccessControl every time you use this method, doing
    this will ensure that all of our RBAC feature controls live in one spot for easy readibility.

    For example:
      If you'd like to check if the current user can add admins in the create staff wizard
      You can call this method: `hasAccess(AccessControl.staff.addAdmins)`

      If you manually want to check if the current user is a role you can call this with:
      `hasAccess([Roles.ADMIN])`  *** NOT RECOMMENDED ***

    arguments:
      * featureRoles - an array of roles (AccessControl constant is designed to provide this array of roles)

    return Boolean
  ****************************************/
  hasAccess(featureRoles: Array<$Values<typeof ROLES>>): boolean {
    return featureRoles.includes(this.myRole);
  }

  // private method that grabs the higher up roles with the one passed in.
  // so if you pass in 'program' it'll include roles associated with 'all' as well.
  _getEntityLevelAndAbove(
    entityLevel: $Values<typeof ENTITY_LEVEL>,
  ): Array<$Values<typeof ROLES>> {
    switch (entityLevel) {
      case ENTITY_LEVEL.group:
        return [
          ...EntityAC[ENTITY_LEVEL.group],
          ...EntityAC[ENTITY_LEVEL.program],
          ...EntityAC[ENTITY_LEVEL.all],
        ];
      case ENTITY_LEVEL.program:
        return [
          ...EntityAC[ENTITY_LEVEL.program],
          ...EntityAC[ENTITY_LEVEL.all],
        ];
      default:
        return EntityAC[ENTITY_LEVEL.all];
    }
  }

  /*** has access to the ENTITY_LEVEL ***
    Use this method to check whether the role has access to entities of the specified level.
    This does not check access to a specific entity.

    For example:
      If full access to programs is required for the list of groups to show up you can call `hasEntityLevelAccess(ENTITY_LEVELS.program)`
      This would currently grant Admins and Super Admins the ability to see the list of groups for each program.

    arguments:
    * entityLevel - 'all' or 'program' or 'group
    * forceRole?  - the role to check the entity level against, if none provided use the current users role
    * strict?     - if false the entity level inherits the higher level roles (e.g. an admin who has program level access has access to the 'group' entity level)
    *               if true the entity level does not inherit higher level roles (e.g. only a counselor would pass a check for 'group' entity level)

    returns Boolean
  ****************************************/
  hasEntityLevelAccess(
    entityLevel: $Values<typeof ENTITY_LEVEL>,
    forceRole?: string,
    strict?: boolean,
  ): boolean {
    const fullEntityLevels = strict
      ? EntityAC[entityLevel]
      : this._getEntityLevelAndAbove(entityLevel);
    return fullEntityLevels.includes(forceRole || this.myRole);
  }

  /*** has access to a specific entity ***
  Check if the current user can access the specific entity
  The method checks if the current user's authorizations has the specified entity.

  For example:
    if you want to see if the current user can access a specific group
    you can call `hasAccessToEntity(ENTITY_TYPES.group, group.id)`

  arguments:
    * entityType  - the type of entity you are checking
    * entityId    - the id of the specific entity you are checking access for

  returns Boolean
  ****************************************/
  hasAccessToEntity(
    entityType: $Values<typeof ENTITY_TYPES>,
    entityId: string,
  ): boolean {
    if (this.hasEntityLevelAccess(ENTITY_LEVEL.all)) {
      return true;
    }

    switch (entityType.toLowerCase()) {
      case ENTITY_TYPES.program:
        return this._programIsInMyPrograms(entityId);
      case ENTITY_TYPES.group:
        return this._groupIsInMyGroups(entityId);
    }

    return false;
  }

  _programIsInMyPrograms(programId: string): boolean {
    return this.meStore.permittedProgramIds.includes(programId);
  }

  _groupIsInMyGroups(groupId: string): boolean {
    return this.meStore.permittedGroupIds.includes(groupId);
  }
}
