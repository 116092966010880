// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {Theme} from '@wellstone-solutions/common';
import type {FormType} from '@wellstone-solutions/common';

import {
  Box,
  Form as CommonForm,
  FormRow,
  Select,
  Stack,
  TextField,
} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import type {Group} from '../../types';
import {ChatSwitch} from './ChatSwitch';
import {MAX_GROUP_DESC_LENGTH, MAX_GROUP_NAME_LENGTH} from '../../constants';

type PropsType = {
  formId: string,
  form: FormType<Group>,
};

type CountdownProps = {
  fieldName: string,
  helperText?: string,
  countdown?: number,
};

// TODO Consider moving this into the design system for TextField
const CountdownHelperTextRow = ({
  fieldName,
  helperText,
  countdown,
}: CountdownProps) => (
  // MUI renders helperText in p tag, so you get errors if you nest divs
  <span style={styles.countdown}>
    {Boolean(helperText && helperText.length > 0) && <span>{helperText}</span>}
    {countdown !== undefined && (
      <span
        style={{
          flex: 1,
          pl: 2,
          pt: 0.5,
          textAlign: 'right',
          width: '75%',
          color:
            countdown >= 0 ? Theme.colorPalette.dark : Theme.colorPalette.red,
        }}>
        {countdown > -1
          ? `${countdown} characters remaining`
          : 'Character count exceeded'}
      </span>
    )}
  </span>
);

export const Form = ({form, formId}: PropsType): Node => {
  const [nameCountdown, setNameCountdown] = useState(MAX_GROUP_NAME_LENGTH);
  const [descCountdown, setDescCountdown] = useState(MAX_GROUP_DESC_LENGTH); // description
  const {meStore, organizationStore} = useStores();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {groupCategories} = organizationStore;

  const programItems = meStore.myPrograms.map((program) => ({
    label: program.name,
    value: program.id,
  }));

  const groupTypeItems = groupCategories.map((category) => ({
    label: category.name,
    value: category.id,
  }));

  const handleNameChange = (event) => {
    const value = event.target.value;
    setNameCountdown(MAX_GROUP_NAME_LENGTH - value.length);

    form.setFieldTouched('name');
    form.setFieldValue('name', value);
  };

  const handleDescChange = (event) => {
    const value = event.target.value;
    setDescCountdown(MAX_GROUP_DESC_LENGTH - value.length);

    form.setFieldTouched('description');
    form.setFieldValue('description', value);
  };

  const handleProgramChange = (event) => {
    const value = event.target.value;

    const [selectedValue] = programItems.filter(
      (program) => program.value === value,
    );

    const newValue = value.length > 0 ? [selectedValue.value] : [];

    form.setFieldTouched('programs');
    form.setFieldValue('programs', newValue);
  };

  const handleGroupTypeChange = (event) => {
    const value = event.target.value;

    const [selectedCategory] = groupTypeItems.filter(
      (category) => (category.value = value),
    );

    form.setFieldTouched('category');
    form.setFieldValue('category', selectedCategory?.value ?? '');
  };

  return (
    <CommonForm
      data-testid="add-group-form"
      id={formId}
      onSubmit={form.handleSubmit}>
      <Stack direction="column">
        <FormRow>
          <TextField
            error={Boolean(form.touched.name && form.errors.name)}
            formField="name"
            label="Group Name"
            inputProps={{maxLength: MAX_GROUP_NAME_LENGTH + 1}}
            onBlur={() => {
              form.setFieldTouched('name');
            }}
            onChange={handleNameChange}
            value={form.values.name}
            helperText={
              <CountdownHelperTextRow
                helperText={form.errors.name}
                fieldName="name"
                countdown={nameCountdown}
              />
            }
            sx={{width: {xs: '100%', md: '75%'}, mb: 1}}
          />
        </FormRow>
        <FormRow>
          <TextField
            multiline
            inputProps={{maxLength: MAX_GROUP_DESC_LENGTH + 1}}
            error={Boolean(
              form.touched.description && Boolean(form.errors.description),
            )}
            formField="description"
            label="Description (Optional)"
            onChange={handleDescChange}
            value={form.values.description}
            helperText={
              <CountdownHelperTextRow
                fieldName="description"
                helperText={
                  !isMobile
                    ? 'Describe the activities or services this group provides to its members.'
                    : ''
                }
                countdown={descCountdown}
              />
            }
            sx={{mb: 2, width: {xs: '100%', md: '75%'}}}
          />
        </FormRow>

        <FormRow>
          <Stack sx={styles.selectRow}>
            <Box sx={{flex: 1, mr: 2, width: '100%', mb: 1}}>
              <Select
                canUnselect={false}
                containerProps={{
                  fullWidth: true,
                  sx: styles.dropdown,
                }}
                label="Program"
                value={form.values.programs[0]}
                onChange={handleProgramChange}
                items={programItems}
              />
            </Box>
            <Box sx={{flex: 1, width: '100%'}}>
              <Select
                canUnselect={false}
                containerProps={{
                  fullWidth: true,
                  sx: styles.dropdown,
                }}
                label="Group Type"
                value={form.values.category}
                onChange={handleGroupTypeChange}
                items={groupTypeItems}
              />
            </Box>
          </Stack>
        </FormRow>
        <FormRow>
          <ChatSwitch form={form} />
        </FormRow>
      </Stack>
    </CommonForm>
  );
};

const styles = {
  countdown: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 16,
  },
  dropdown: {
    flex: 1,
    mr: {xs: 0, sm: 5},
    mb: {xs: 1, md: 0},
  },
  selectRow: {
    flexDirection: {sm: 'column', md: 'row'},
    alignItems: 'top',
    width: {xs: '100%', md: '75%'},
  },
};
