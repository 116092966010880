// React
import React, {Component} from 'react';

// Third party
import {DateRangePicker} from 'react-date-range';
import {inject, observer} from 'mobx-react';
import moment from 'moment';

// Bulma
import {Heading, Level} from 'react-bulma-components';

import {Alert, Select} from '@wellstone-solutions/web';

// WS
import {Api, BridgeEventSources, Events} from '@wellstone-solutions/common';
import MembersOverview from '../../components/MembersOverview';
import PfModal from '../../components/modal/PfModal';
import PfLoader from '../../components/shared/PfLoader';
import {aggregatingList} from '../../constants/Aggregators';
import {handleDateSelect, getDateRange, processData} from '../../utils/Charts';

import {Palette} from '../../palette';

class OverviewPage extends Component {
  state = {
    selectionRange: {},
  };

  componentDidMount() {
    this.props.eventStore.setEventSource(BridgeEventSources.ACTIVITY_PAGE);

    this._loadUX();
  }

  _loadUX = () => {
    this.props.appUIStore.setBreadcrumbs([
      {
        name: 'Activity',
        url: '/activity/',
        active: true,
      },
    ]);

    this.props.eventStore.addEvent(Events.VIEWED_ACTIVITY);

    let start = new Date();
    let end = new Date();
    start.setDate(start.getDate() - 6);
    end.setDate(end.getDate() + 1);

    this.setState(
      {
        aggregator: 'date',
        selectionRange: {
          startDate: start,
          endDate: end,
          key: 'selection',
        },
      },
      () => this._getData(),
    );

    this._getData();
  };

  _getData = async () => {
    const org = this.props.meStore.me.membership.organization.id;
    const start = moment(this.state.selectionRange.startDate).format(
      'YYYY-MM-DD',
    );
    const end = moment(this.state.selectionRange.endDate).format('YYYY-MM-DD');
    const params = {
      limit: 99999,
      range_start: start,
      range_end: end,
      only_patient: 1,
    };

    try {
      const response = await Api.Instance.current().get(
        '/orgs/' + org + '/events',
        {params},
      );
      const allData = await processData(org, response, this.state);
      this.setState(allData);
    } catch (e) {}
  };

  // when choosing "by time of day", "by month" etc
  _handleSelectAggregator = async (sel) => {
    this.setState({aggregator: sel}, () => this._getData());
  };

  render() {
    if (!this.state.checkinData) {
      return <PfLoader />;
    }
    return (
      <div>
        <Level>
          <Level.Side>
            <Level.Item>
              <Heading style={styles.heading}>
                {this.props.meStore.me.membership.organization.name} Activity
              </Heading>
            </Level.Item>
          </Level.Side>
          <Level.Side>
            <Level.Item>
              <PfModal
                button={{outlined: true, text: getDateRange(this)}}
                modal={{
                  closeOnBlur: true,
                  showClose: false,
                  closeOnEsc: true,
                }}>
                <DateRangePicker
                  ranges={[this.state.selectionRange]}
                  rangeColors={[Palette.LINK]}
                  onChange={(v) => handleDateSelect(v, this)}
                />
              </PfModal>
            </Level.Item>
            <Level.Item>
              <span style={styles.viewBy}>View by</span>
              <Select
                value={this.state.aggregator}
                onChange={(event) => {
                  this._handleSelectAggregator(event.target.value);
                }}
                items={aggregatingList.map((agg) => ({
                  value: agg.key,
                  label: agg.name,
                }))}
                canUnselect={false}
              />
            </Level.Item>
          </Level.Side>
        </Level>

        {this.state.checkinData.length < 1 ? (
          <Alert style={styles.notificationNoData}>
            NO DATA FOUND FOR THIS PERIOD
          </Alert>
        ) : (
          <MembersOverview
            context={this}
            aggregator={this.state.aggregator}
            checkinData={this.state.checkinData}
            habitData={[]}
            activityData={this.state.activityData}
            badgeData={this.state.badgeData}
            appnavigationData={this.state.appnavigationData}
            engagementData={this.state.engagementData}
            memberNotifications={this.props.memberNotifications}
            start={this.state.selectionRange.startDate}
            end={this.state.selectionRange.endDate}
            rawEvents={this.state.rawEventData}
          />
        )}
      </div>
    );
  }
}

const styles = {
  heading: {
    color: Palette.GREY_4,
  },
  viewBy: {
    marginRight: 10,
    color: Palette.GREY_4,
  },
  notificationNoData: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
};

export default inject(
  'appUIStore',
  'eventStore',
  'meStore',
)(observer(OverviewPage));
