// @flow
import React from 'react';
import type {Node} from 'react';
import {Theme, Utils} from '@wellstone-solutions/common';
import type {FormType} from '@wellstone-solutions/common';
import {
  Box,
  DatePicker,
  ErrorMessage,
  Form as CommonForm,
  FormRow,
  Stack,
  TextField,
  Typography,
} from '@wellstone-solutions/web';
import {useScrollToError} from 'hooks/useScrollToError';
import {hasFieldError} from 'utils/Forms';
import type {UISessionDocumentType} from 'modules/documentation/model/types';
import {SessionTypeRadio} from './SessionTypeRadio';
import {MemberAttendRadio} from './MemberAttendRadio';
import {ServiceTypeMultiSelect} from './ServiceTypeMultiSelect';
import {getDurationAndUnits} from '../../../utils';

type PropsType = {
  formId: string,
  form: FormType<UISessionDocumentType>,
};

export const Form = ({form, formId}: PropsType): Node => {
  useScrollToError(form);

  const handleStartTimeChange = (event) => {
    const startTime = event.target.value;

    if (startTime.length > 0) {
      // Only shift end time if it is already set
      const shiftedEndTime = form.values.endTime
        ? Utils.shiftEndTime(
            {
              startTime: form.values.startTime ?? '',
              endTime: form.values.endTime ?? '',
            },
            startTime,
          )
        : form.values.endTime;

      // $FlowIgnoreMe
      form.setTouched({startTime: true, endTime: true});
      // $FlowIgnoreMe
      form.setValues({
        ...form.values,
        startTime: startTime,
        endTime: shiftedEndTime,
      });
    } else {
      form.setFieldValue('startTime', event.target.value);
    }
  };

  // $FlowIgnoreMe
  const showDuration = !form.errors.startTime && !form.errors.endTime;

  return (
    <CommonForm
      data-testid="documentation-form"
      id={formId}
      // $FlowFixMe
      onSubmit={form.handleSubmit}>
      <Typography variant="h6" sx={{color: Theme.colorPalette.darkest}}>
        Session Details
      </Typography>
      <Typography variant="body2" sx={{mb: 2}}>
        All fields are required unless indicated as optional.
      </Typography>
      <Stack direction="column">
        <FormRow direction="row" sx={{gap: '10px'}}>
          <Stack sx={{flex: 0.5, paddingRight: '5px'}}>
            <DatePicker
              data-testid="document-form-datepicker"
              fieldName="dateOfService"
              label="Service date"
              error={hasFieldError(form, 'dateOfService')}
              onChange={(date) => {
                form.setFieldValue(
                  'dateOfService',
                  date?.isValid() ? date.toISOString() : '',
                );
              }}
              value={form.values.dateOfService}
            />
            <ErrorMessage
              name="dateOfService"
              errors={form.errors}
              values={form.values}
              touched={form.touched}
            />
          </Stack>
        </FormRow>
        <FormRow
          data-testid="document-start-end-times"
          sx={{
            display: !form.values.isAllDay ? 'flex' : 'none',
            gap: '10px',
          }}
          direction="row">
          <Stack sx={styles.halfRow}>
            <TextField
              label="Start time"
              inputProps={{'data-testid': 'start-time'}}
              formField="startTime"
              onChange={handleStartTimeChange}
              type="time"
              value={form.values.startTime}
              error={hasFieldError(form, 'startTime')}
              InputLabelProps={{shrink: true}}
            />
            <ErrorMessage
              name="startTime"
              errors={form.errors}
              touched={form.touched}
              values={form.values}
            />
          </Stack>
          <Stack sx={styles.halfRow}>
            <TextField
              label="End time"
              inputProps={{'data-testid': 'end-time'}}
              formField="endTime"
              onChange={form.handleFieldChange('endTime')}
              type="time"
              value={form.values.endTime}
              error={hasFieldError(form, 'endTime')}
              InputLabelProps={{shrink: true}}
            />
            <ErrorMessage
              name="endTime"
              errors={form.errors}
              touched={form.touched}
              values={form.values}
            />
          </Stack>
        </FormRow>
        {showDuration && (
          <FormRow
            sx={{mt: 1, display: !form.values.isAllDay ? 'flex' : 'none'}}>
            <Typography variant="body2" sx={{mt: 0}}>
              {`Duration: ${getDurationAndUnits({
                startTime: form.values.startTime,
                endTime: form.values.endTime,
                dateOfService: form.values.dateOfService,
              })}`}
            </Typography>
          </FormRow>
        )}
        <FormRow>
          <TextField
            formField="titleOrReason"
            label="Title or reason for session"
            inputProps={{maxLength: 100}}
            onChange={form.handleFieldChange('titleOrReason')}
            value={form.values.titleOrReason ?? ''}
            error={hasFieldError(form, 'titleOrReason')}
          />
          <Typography variant="body2" sx={{mt: 1, ml: 1}}>
            e.g. Assisted member in applying for SNAP, Provided recovery
            experience through self-disclosure
          </Typography>
          <ErrorMessage
            name="titleOrReason"
            errors={form.errors}
            touched={form.touched}
          />
        </FormRow>
        <FormRow>
          <SessionTypeRadio
            form={form}
            hasError={hasFieldError(form, 'sessionType')}
          />
        </FormRow>
        <FormRow>
          <MemberAttendRadio
            form={form}
            hasError={hasFieldError(form, 'didMemberAttend')}
          />
        </FormRow>
        <FormRow>
          <Box sx={{maxWidth: 800}}>
            <ServiceTypeMultiSelect
              form={form}
              hasError={hasFieldError(form, 'typeOfService')}
            />
          </Box>
        </FormRow>
        <FormRow>
          <TextField
            formField="location"
            label="Location of the service"
            inputProps={{maxLength: 100}}
            onChange={form.handleFieldChange('location')}
            value={form.values.location ?? ''}
            error={hasFieldError(form, 'location')}
          />
          <Typography variant="body2" sx={{mt: 1, ml: 1}}>
            e.g. Teams call, Zoom call, Clinic, Outpatient office.
          </Typography>
          <ErrorMessage
            name="location"
            errors={form.errors}
            touched={form.touched}
          />
        </FormRow>

        <FormRow>
          <Typography variant="h6" sx={styles.notesTitle}>
            Session Notes
          </Typography>

          <Typography variant="body2" sx={{mb: Theme.spacing.micro}}>
            What did you talk about? What details can you provide about the
            session?
          </Typography>
          <TextField
            formField="notes"
            fullWidth
            multiline
            minRows={5}
            value={form.values.notes}
            onChange={form.handleFieldChange('notes')}
            error={hasFieldError(form, 'notes')}
          />
          <ErrorMessage
            name="notes"
            errors={form.errors}
            touched={form.touched}
          />
        </FormRow>
        <FormRow>
          <Typography variant="h6" sx={styles.notesTitle}>
            Next Steps{' '}
            <Typography variant="body2" sx={{display: 'inline-block'}}>
              <i>(optional)</i>
            </Typography>
          </Typography>

          <Typography variant="body2" sx={{mb: Theme.spacing.micro}}>
            What are some actions or tasks that the member needs to work on?
          </Typography>
          <TextField
            formField="nextSteps"
            fullWidth
            multiline
            minRows={5}
            value={form.values.nextSteps}
            onChange={form.handleFieldChange('nextSteps')}
            error={hasFieldError(form, 'nextSteps')}
          />
          <ErrorMessage
            name="nextSteps"
            errors={form.errors}
            touched={form.touched}
          />
        </FormRow>
      </Stack>
    </CommonForm>
  );
};

const styles = {
  sectionTitle: {
    color: 'black',
    marginTop: Theme.spacing.mini,
    marginBottom: `${Theme.spacing.mini}px`,
  },
  halfRow: {
    display: 'flex',
    direction: 'column',
    flex: 1,
  },
  checkbox: {
    marginLeft: Theme.spacing.average,
  },
  optionalText: {
    fontSize: Theme.typography.size.s2,
    fontStyle: 'italic',
  },
  notesTitle: {
    color: 'black',
    marginTop: Theme.spacing.mini,
    marginBottom: `${Theme.spacing.mini}px`,
  },
};
