// @flow
import {
  hasFlag,
  HIDE_MEMBERS_NAV,
  TMP_STORY_MANAGER,
  TMP_PEERS_DOCUMENTATION,
} from 'constants/FeatureFlags';
import type {RouteType} from 'types';
import {withRouter} from 'components/withRouter';
import {useParams, Navigate} from 'react-router-dom';

import {NAVIGATION_NAMES, NAVIGATION_ICONS} from 'constants/Navigation';
import {Roles} from '@wellstone-solutions/common';

import {
  AnnouncementsPage,
  AnnouncementsPageV3,
} from './authorized/Announcements';
import {AnnouncementNew} from 'modules/announcements/v3';
import OverviewPage from './authorized/Overview';
import ConversationPage from './authorized/Conversations';
import {CalendarPage} from './authorized/Calendar';
import AdminDetailPage from './authorized/AdminDetail';
import GroupDetailPage from './authorized/GroupDetail';
import {DocumentationLanding} from 'modules/documentation';
import {MemberRoot, MemberDetail, MemberDetailTabs} from 'modules/member';
import {GroupRoot, New as NewGroup} from 'modules/group';
import {StaffRoot} from 'modules/staff';
import {StoriesRoot} from 'modules/stories';
import {StoryNew} from 'modules/stories';
import {StoryLoader} from 'modules/stories';
import {NewCalendarEvent} from 'modules/calendar';
import {CalendarDetailPage} from 'modules/calendar';
import {DashboardRoot} from 'modules/dashboard';
import {DocumentationRoot} from 'modules/documentation';
import {
  ResourcesRoot,
  SavedResources,
  AllResources,
} from 'modules/resources/v2';

export const getAuthorizedRoutes = (meStore): Array<RouteType> => {
  const accessToMembers = !hasFlag(HIDE_MEMBERS_NAV, meStore.features);
  // Remove TMP_STORY_MANAGER feature flag, see PAT-3021
  const accessToStories = hasFlag(TMP_STORY_MANAGER, meStore.features);
  const accessToResources = true;
  // Remove TMP_PEER_DOCUMENTATION feature flag, see PAT-3502
  const accessToDocumentation = hasFlag(
    TMP_PEERS_DOCUMENTATION,
    meStore.features,
  );
  const accessToAnnouncementsV3 = hasFlag('announcements_v3', meStore.features);

  let authorizedRoutes = [
    {
      showInMenu: false,
      path: 'conversations/:channel',
      name: NAVIGATION_NAMES.conversation,
      icon: NAVIGATION_ICONS.conversation,
      Component: ConversationPage,
      allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
    },
    {
      showInMenu: true,
      path: 'conversations',
      name: NAVIGATION_NAMES.conversations,
      icon: NAVIGATION_ICONS.conversation,
      Component: ConversationPage,
      allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
    },
    {
      showInMenu: true,
      path: 'calendar',
      name: NAVIGATION_NAMES.calendar,
      icon: NAVIGATION_ICONS.calendar,
      Component: CalendarPage,
      allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
      isBottomRuled: true,
    },
    {
      path: 'calendar/calendar-event/new',
      name: NAVIGATION_NAMES.newEvent,
      icon: NAVIGATION_ICONS.newEvent,
      Component: NewCalendarEvent,
      allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
    },
    {
      path: 'calendar/calendar-event/:id',
      name: NAVIGATION_NAMES.detailEvent,
      icon: NAVIGATION_ICONS.detailEvent,
      Component: CalendarDetailPage,
      allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
    },
    {
      path: 'dashboard/*',
      Component: () => {
        // Redirect nested dashboard routes
        const {'*': splat} = useParams();
        return <Navigate to={`/${splat}`} />;
      },
    },
    {
      showInMenu: true,
      path: 'dashboard',
      name: NAVIGATION_NAMES.dashboard,
      icon: NAVIGATION_ICONS.dashboard,
      Component: DashboardRoot,
      allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
    },
  ];

  if (accessToMembers) {
    authorizedRoutes = [
      ...authorizedRoutes,
      {
        path: 'members/:member',
        name: NAVIGATION_NAMES.memberDetail,
        icon: NAVIGATION_ICONS.memberDetail,
        Component: withRouter(MemberDetail),
        allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
        children: [
          {
            path: 'activity',
            name: NAVIGATION_NAMES.memberDetailActivityTab,
            icon: NAVIGATION_ICONS.memberDetailActivityTab,
            Component: MemberDetailTabs.ActivityTabContent,
            allowed: [
              Roles.SUPER_ADMIN,
              Roles.ADMIN,
              Roles.COUNSELOR,
              Roles.PEER,
            ],
          },
          {
            path: 'habit-schedule',
            name: NAVIGATION_NAMES.memberDetailHabitScheduleTab,
            icon: NAVIGATION_ICONS.memberDetailHabitScheduleTab,

            Component: MemberDetailTabs.HabitScheduleTabContent,
            allowed: [
              Roles.SUPER_ADMIN,
              Roles.ADMIN,
              Roles.COUNSELOR,
              Roles.PEER,
            ],
          },
          {
            path: 'session-docs',
            name: NAVIGATION_NAMES.memberDetailSessionDocTab,
            icon: NAVIGATION_ICONS.memberDetailSessionDocTab,
            Component: MemberDetailTabs.SessionDocTabContent,
            allowed: [
              Roles.SUPER_ADMIN,
              Roles.ADMIN,
              Roles.COUNSELOR,
              Roles.PEER,
            ],
            isDisabled: !accessToDocumentation,
          },
          {
            path: 'permissions',
            name: NAVIGATION_NAMES.programsAndGroups,
            icon: NAVIGATION_ICONS.memberDetailActivityTab,
            Component: MemberDetailTabs.ProgramsAndGroupsTabContent,
            allowed: [Roles.SUPER_ADMIN, Roles.ADMIN],
          },
          {
            index: true,
            name: NAVIGATION_NAMES.memberDetailGeneralTab,
            icon: NAVIGATION_ICONS.memberDetailGeneralTab,
            Component: MemberDetailTabs.GeneralTabContent,
            allowed: [
              Roles.SUPER_ADMIN,
              Roles.ADMIN,
              Roles.COUNSELOR,
              Roles.PEER,
            ],
          },
        ],
      },
      {
        showInMenu: true,
        path: 'members',
        name: NAVIGATION_NAMES.members,
        icon: NAVIGATION_ICONS.members,
        Component: MemberRoot,
        allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
      },
    ];

    // Adds routes for documentation
    if (accessToDocumentation) {
      authorizedRoutes = [
        ...authorizedRoutes,
        {
          showInMenu: true,
          path: 'documentation',
          name: NAVIGATION_NAMES.documentation,
          icon: NAVIGATION_ICONS.documentation,
          Component: DocumentationLanding,
          allowed: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.COUNSELOR,
            Roles.PEER,
          ],
        },
        {
          path: 'members/:member/documentation',
          name: NAVIGATION_NAMES.documentation,
          icon: NAVIGATION_ICONS.documentation,
          Component: withRouter(DocumentationRoot),
          allowed: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.COUNSELOR,
            Roles.PEER,
          ],
        },
        {
          path: 'members/:member/documentation/:id',
          name: NAVIGATION_NAMES.documentation,
          icon: NAVIGATION_ICONS.documentation,
          Component: withRouter(DocumentationRoot),
          allowed: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.COUNSELOR,
            Roles.PEER,
          ],
        },
      ];
    }
  }

  authorizedRoutes = [
    ...authorizedRoutes,
    {
      path: 'groups/:group',
      name: NAVIGATION_NAMES.groupDetail,
      icon: NAVIGATION_ICONS.groupDetail,
      Component: GroupDetailPage,
      allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
    },
    {
      showInMenu: true,
      path: 'groups',
      name: NAVIGATION_NAMES.groups,
      icon: NAVIGATION_ICONS.groups,
      Component: GroupRoot,
      allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
    },
    {
      path: 'groups/new',
      name: NAVIGATION_NAMES.groups,
      icon: NAVIGATION_ICONS.groups,
      Component: NewGroup,
      allowed: [Roles.SUPER_ADMIN, Roles.ADMIN],
    },
    {
      path: 'staff/:admin',
      name: NAVIGATION_NAMES.staffDetail,
      icon: NAVIGATION_ICONS.staffDetail,
      Component: withRouter(AdminDetailPage),
      allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
    },
    {
      showInMenu: true,
      path: 'staff',
      name: NAVIGATION_NAMES.staff,
      icon: NAVIGATION_ICONS.staff,
      Component: StaffRoot,
      allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
      isBottomRuled: accessToStories && !meStore.isSuperAdmin,
    },
    {
      showInMenu: true,
      path: 'activity',
      name: NAVIGATION_NAMES.activity,
      icon: NAVIGATION_ICONS.activity,
      Component: OverviewPage,
      allowed: [Roles.SUPER_ADMIN],
      isBottomRuled: accessToStories,
    },
  ];

  if (accessToResources) {
    authorizedRoutes = [
      ...authorizedRoutes,
      {
        showInMenu: true,
        path: 'resources',
        name: NAVIGATION_NAMES.resources,
        icon: NAVIGATION_ICONS.resources,
        Component: ResourcesRoot,
        allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
        children: [
          {
            index: true,
            name: NAVIGATION_NAMES.resourcesAll,
            icon: NAVIGATION_ICONS.resourcesAll,
            Component: AllResources,
            allowed: [
              Roles.SUPER_ADMIN,
              Roles.ADMIN,
              Roles.COUNSELOR,
              Roles.PEER,
            ],
          },
          {
            path: 'saved',
            name: NAVIGATION_NAMES.savedResources,
            icon: NAVIGATION_ICONS.savedResources,
            Component: SavedResources,
            allowed: [
              Roles.SUPER_ADMIN,
              Roles.ADMIN,
              Roles.COUNSELOR,
              Roles.PEER,
            ],
          },
        ],
      },
    ];
  }

  if (accessToStories) {
    authorizedRoutes = [
      ...authorizedRoutes,
      {
        showInMenu: true,
        path: 'stories',
        name: NAVIGATION_NAMES.stories,
        icon: NAVIGATION_ICONS.stories,
        Component: StoriesRoot,
        allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
      },
      {
        showInMenu: false,
        path: 'stories/new',
        name: NAVIGATION_NAMES.storyNew,
        icon: NAVIGATION_ICONS.storyNew,
        Component: StoryNew,
        allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
      },
      {
        showInMenu: false,
        path: 'stories/:id',
        name: NAVIGATION_NAMES.storyEdit,
        icon: NAVIGATION_ICONS.storyEdit,
        Component: StoryLoader,
        allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
      },
    ];
  }

  if (accessToAnnouncementsV3) {
    // add the new announcement pages
    authorizedRoutes = [
      ...authorizedRoutes,
      {
        path: 'announcements/new',
        name: NAVIGATION_NAMES.announcementNew,
        icon: NAVIGATION_ICONS.announcements,
        Component: AnnouncementNew,
        allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
      },
      {
        showInMenu: true,
        path: 'announcements',
        name: NAVIGATION_NAMES.announcements,
        icon: NAVIGATION_ICONS.announcements,
        Component: AnnouncementsPageV3,
        allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
      },
    ];
  } else {
    // use current/legacy announcements
    authorizedRoutes = [
      ...authorizedRoutes,
      {
        path: 'announcements/:group_id',
        name: NAVIGATION_NAMES.announcements,
        icon: NAVIGATION_ICONS.announcements,
        Component: AnnouncementsPage,
        allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
      },
      {
        showInMenu: true,
        path: 'announcements',
        name: NAVIGATION_NAMES.announcements,
        icon: NAVIGATION_ICONS.announcements,
        Component: AnnouncementsPage,
        allowed: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.COUNSELOR, Roles.PEER],
      },
    ];
  }

  const defaultPage = '/dashboard';
  authorizedRoutes.push({
    Component: (): Node => <Navigate to={defaultPage} />,
    isProtected: true,
    path: '*',
    allowed: [],
  });

  return authorizedRoutes;
};
