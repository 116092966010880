// @flow
import React from 'react';
import {Link} from 'react-router-dom';

// This is using the Bulma class names to match a button
const toClassNames = ({color, outlined}) => {
  const classNames = ['button', `is-${color}`];

  if (outlined) {
    classNames.push('is-outlined');
  }

  return classNames.join(' ');
};

type PropsType = {
  children: any,
  color: string,
  outlined: boolean,
  to: any,
};

export const PfLinkButton = ({
  children,
  color = 'info',
  outlined = true,
  to,
}: PropsType) => {
  return (
    <Link className={toClassNames({color, outlined})} to={to}>
      {children}
    </Link>
  );
};
