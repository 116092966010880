import React, {Fragment} from 'react';
import {Image} from 'react-bulma-components';
import Button from '../button';
import {Animated} from 'react-animated-css';

export default (props) => {
  return (
    <Fragment>
      <div style={styles.wrapper}>
        <div style={styles.leftColumn}>
          <Animated
            animationIn="fadeInLeft"
            animationInDuration={500}
            animationInDelay={100}
            animateOnMount={true}>
            <h2 style={styles.h2}>Staff Roles</h2>
          </Animated>
          <Animated
            animationIn="fadeInLeft"
            animationInDuration={500}
            animationInDelay={200}
            animateOnMount={true}>
            <p style={styles.body}>
              As you'll see throughout the tutorial, not all staff roles have
              the same responsibilities. Take a look at the summary to the
              right, and then let's get started!
            </p>
          </Animated>
          <Animated
            animationIn="fadeIn"
            animationInDuration={500}
            animationInDelay={1500}
            animateOnMount={true}>
            <Button id="nextButton">Let's get started!</Button>
          </Animated>
        </div>
        <div style={styles.rightColumn}>
          <Animated
            animationIn="fadeIn"
            animationInDuration={500}
            animationInDelay={400}
            animateOnMount={true}>
            <Image
              renderAs="p"
              style={styles.image}
              src={
                'https://storage.googleapis.com/pf-bucket-default-prod/tutorial/role_matrix.png'
              }
            />
          </Animated>
        </div>
      </div>
      <Button
        id="tutorialSkipButton"
        className={'tutorialSkipButton'}
        data-testid="skip-tutorial-button">
        Skip Tutorial
      </Button>
    </Fragment>
  );
};

const styles = {
  h2: {
    fontSize: 26,
    fontWeight: 700,
    marginBottom: '25px',
    color: '#ffffff',
  },
  body: {
    marginBottom: 20,
    fontWeight: 500,
    fontSize: 18,
  },
  wrapper: {
    width: '90vw',
    display: 'flex',
    height: 'calc(100% - 56px)',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#d6d6d6',
    margin: '0 auto',
  },
  image: {
    maxWidth: 840,
    margin: '0 auto',
    width: '100%',
  },
  leftColumn: {
    width: '25vw',
    marginRight: '5vw',
  },
  rightColumn: {
    width: '70vw',
  },
};
