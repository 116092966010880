// React
import React, {useContext} from 'react';
import 'emoji-mart/css/emoji-mart.css';

// Bulma
import {Box, Stack, Typography, Paper} from '@wellstone-solutions/web';

import {EventStream} from 'components/member/EventStream';
import {MemberDetailTabContext} from '../../contexts/MemberDetailTabContext';

export const GeneralTabContent = () => {
  const {state, member, getChatModule} = useContext(MemberDetailTabContext);
  const {recentEvents} = state || {};

  return (
    <Stack
      direction={{xs: 'column', md: 'row'}}
      spacing={3}
      sx={{
        height: {xs: 'auto', md: '440px'},
        minHeight: {xs: '880px', md: '440px'},
        marginBottom: '200px',
      }}>
      <Box sx={{flex: 1, height: '100%'}}>
        <Typography variant="h5" gutterBottom>
          Check ins
        </Typography>
        <Paper sx={{height: '440px', overflow: 'auto'}}>
          <EventStream
            events={recentEvents}
            memberName={member.name}
            memberID={member.id}
            userID={member.user.id}
          />
        </Paper>
      </Box>

      <Box
        sx={{
          flex: 1,
          height: '440px',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <Typography variant="h5" gutterBottom>
          Chat
        </Typography>
        {getChatModule()}
      </Box>
    </Stack>
  );
};
