// @flow
import React, {useContext} from 'react';
import type {Node} from 'react';
import {Box, Stack} from '@wellstone-solutions/web';
import {MemberDetailTabContext} from '../../contexts/MemberDetailTabContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';

import {
  SessionDocumentList,
  CreateSessionDocumentButton,
} from 'modules/documentation';

export const SessionDocTabContent = (): Node => {
  const {member} = useContext(MemberDetailTabContext);

  const theme = useTheme();
  const medBreakPoint = useMediaQuery('@media (max-width:749.95px)');
  const intermedBreakPoint = useMediaQuery('@media (max-width:999.95px)');
  const largeBreakPoint = useMediaQuery(theme.breakpoints.down('lg'));

  let allColumns;
  if (medBreakPoint) {
    allColumns = ['documentDescription', 'actions'];
  } else if (intermedBreakPoint) {
    allColumns = ['dateOfService', 'staff', 'status', 'actions'];
  } else if (largeBreakPoint) {
    allColumns = ['dateOfService', 'staff', 'sessionType', 'status', 'actions'];
  } else {
    allColumns = [
      'dateOfService',
      'staff',
      'sessionType',
      'typeOfService',
      'status',
      'actions',
    ];
  }

  return (
    <Box mb={1}>
      <Stack direction="row" justifyContent="flex-end" mb={3}>
        <CreateSessionDocumentButton memberId={member.id} />
      </Stack>

      <SessionDocumentList
        title={`${member.name}'s support sessions`}
        description="This list represents all peer support documentation written by the member's support staff."
        columnNames={allColumns}
        defaultParams={{member: member.id}}
        showMemberFilter={false}
        showStaffFilter={true}
        {...(medBreakPoint && {
          rowHeight: () => 115,
          showGridHeader: false,
        })}
        showDocumentCount={true}
      />
    </Box>
  );
};
