// @flow
import {observable, action, makeObservable} from 'mobx';
import {Api} from '@wellstone-solutions/common';
import {AnnouncementStore} from 'modules/announcements/store';
import {AppUIStore} from 'modules/app';
import MeStore from './MeStore';
import PubnubStore from './PubnubStore';
import {ProgramStore} from './ProgramStore';
import NotificationStore from './NotificationStore';
import {AppNotificationStore} from 'modules/notifications/store';
import {MemberStore} from 'modules/member/store';
import MemberDetailStore from './MemberDetailStore';
import MessageStore from './MessageStore';
import AppStore from './AppStore';
import {ResourceStore} from 'modules/resources/store';
import {ResourceStoreV2} from 'modules/resources/v2/store';
import {CalendarStore} from 'modules/calendar/store';
import {DashboardStore} from 'modules/dashboard/store';
import {RBACStore} from 'modules/rbac';
import {StaffStore} from 'modules/staff/store';
import {StoryStore} from 'modules/stories/store';
import ChannelStore from './ChannelStore';
import TutorialStore from './tutorial/TutorialStore';
import OrganizationStore from './OrganizationStore';
import {GroupStore} from 'modules/group/store';
import EventStore from './EventStore';
import {HabitStore} from 'modules/habits/store';
import {SessionDocumentStore} from 'modules/documentation/store';
import {LocationStore} from 'modules/location/store';

const STORE_SUFFIX = 'Store';
const STORE_BLACKLIST = ['meStore'];

export type StoresType = {
  announcementStore: AnnouncementStore,
  groupStore: any,
  tutorialStore: any,
  meStore: any,
  memberStore: MemberStore,
  pubnubStore: any,
  notificationStore: any,
  appNotificationStore: AppNotificationStore,
  messageStore: any,
  appStore: any,
  resourceStore: ResourceStore,
  resourceStoreV2: ResourceStoreV2,
  calendarStore: CalendarStore,
  dashboardStore: DashboardStore,
  RBACStore: RBACStore,
  staffStore: StaffStore,
  storyStore: StoryStore,
  channelStore: any,
  organizationStore: any,
  eventStore: any,
  appUIStore: any,
  memberDetailStore: MemberDetailStore,
  habitStore: HabitStore,
  programStore: ProgramStore,
  sessionDocumentStore: SessionDocumentStore,
  locationStore: LocationStore,
};

class RootStore {
  static myInstance: RootStore | null = null;
  stores: StoresType;
  isReady: boolean = false;

  static getInstance(): RootStore | null {
    if (RootStore.myInstance == null) {
      RootStore.myInstance = new RootStore();
    }

    return this.myInstance;
  }

  constructor() {
    makeObservable(this, {
      isReady: observable,
      done: action,
    });

    this.stores = {
      announcementStore: new AnnouncementStore(this),
      groupStore: new GroupStore(this),
      tutorialStore: new TutorialStore(this),
      meStore: new MeStore(this),
      memberStore: new MemberStore(this),
      pubnubStore: new PubnubStore(this),
      notificationStore: new NotificationStore(this),
      appNotificationStore: new AppNotificationStore(this),
      messageStore: new MessageStore(this),
      appStore: new AppStore(this),
      resourceStore: new ResourceStore(this),
      resourceStoreV2: new ResourceStoreV2(this),
      calendarStore: new CalendarStore(this),
      dashboardStore: new DashboardStore(this),
      RBACStore: new RBACStore(this),
      staffStore: new StaffStore(this),
      storyStore: new StoryStore(this),
      channelStore: new ChannelStore(this),
      organizationStore: new OrganizationStore(this),
      eventStore: new EventStore(this),
      appUIStore: new AppUIStore(this),
      memberDetailStore: new MemberDetailStore(this),
      habitStore: new HabitStore(this),
      programStore: new ProgramStore(this),
      sessionDocumentStore: new SessionDocumentStore(this),
      locationStore: new LocationStore(this),
    };
  }

  done(): void {
    this.isReady = true;
  }

  async init(): Promise<mixed> {
    try {
      // $FlowFixMe
      const canLoadApp = await this.stores.meStore.init();

      // Bounce out if we have no token, if it is invalid when we make
      // an api call it will 401 and sign out the user
      if (!canLoadApp) {
        this.done();
        return;
      }

      // Initialize `PubNubStore` before dependent stores
      await this.stores.pubnubStore.connect();

      // Initialize other stores
      const initPromises = [];
      for (const prop in this.stores) {
        if (!STORE_BLACKLIST.includes(prop) && prop.endsWith(STORE_SUFFIX)) {
          if (prop !== 'pubnubStore' && this.stores[prop].init) {
            initPromises.push(this.stores[prop].init());
          }
        }
      }

      await Promise.all(initPromises);

      // $FlowFixMe
      this.stores.meStore.setTutorial();
    } catch (error) {
      console.log('rootStore:error', error);
      // don't throw an error if its an aborted call
      if (!Api.Instance.current().isAborted(error)) {
        throw error;
      }
    } finally {
      this.done();
    }
  }
}

export default RootStore;
