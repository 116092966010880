// React
import React, {useState} from 'react';

// Third party

import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
// Bulma

import {Modal} from 'react-bulma-components';

// WS
import {chartProps} from 'constants/ChartProps';
import {MemberList} from './MemberList';

const CustomizedBar = (props) => {
  const {bar, x, y, width, height, showModal} = props;
  const val = props[bar.key];
  const list = props[bar.key + '_userlist'];
  return (
    <rect
      x={x}
      y={y}
      width={width}
      height={height}
      stroke="black"
      strokeWidth={0}
      fill={bar.color}
      style={styles.cursor}
      onClick={() => showModal(val, list)}
    />
  );
};

export const PfBarChart = (props) => {
  const {size, data, orientation, selectedValues, x} = props;
  const {height, width} = size;
  const [userIds, setUserIds] = useState([]);

  const showModal = async (val, users) => {
    if (props.showDetailsModal === false) {
      return null;
    }

    setUserIds(users);
  };

  const hideModal = () => {
    setUserIds([]);
  };

  return (
    <>
      <Modal
        show={!!userIds.length}
        onClose={hideModal}
        className="pfModal"
        closeOnBlur={true}
        closeOnEsc={true}>
        <Modal.Content style={styles.modal}>
          <MemberList userIds={userIds} />
        </Modal.Content>
      </Modal>
      <ResponsiveContainer width={width} height={height}>
        <BarChart
          data={data}
          layout={orientation || 'vertical'}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          style={styles.barChart}>
          <CartesianGrid strokeDasharray="3 3" />
          {orientation === 'horizontal' ? (
            <XAxis type={x.type} dataKey={x.key} />
          ) : (
            <XAxis type="number" />
          )}
          {orientation === 'horizontal' ? (
            <YAxis type="number" />
          ) : (
            <YAxis type={x.type} dataKey={x.key} />
          )}

          <Tooltip
            cursor={{strokeDasharray: '3 3', fill: 'rgba(0,0,0,.02)'}}
            wrapperStyle={styles.toolTip}
            content={<CustomTooltip />}
          />
          {selectedValues.map((bar, b) => {
            return (
              <Bar
                key={b}
                dataKey={bar.key}
                stackId={bar.stack}
                type="monotone"
                fill={bar.color}
                shape={
                  <CustomizedBar key={b} bar={bar} showModal={showModal} />
                }
              />
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

const CustomTooltip = ({active, payload, label}) => {
  if (active && payload && payload[0]) {
    return (
      <div style={styles.tooltipBox}>
        <p key="label" style={styles.label}>
          <span>{label}</span>
        </p>
        {payload.map((bar, b) => {
          if (bar.value > 0) {
            return (
              <p key={b} style={styles.value}>
                <span style={{color: bar.fill}}>
                  {bar.value} {chartProps[bar.name].name}
                </span>
              </p>
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  }
  // if no data, do NOT display a tooltip
  return null;
};

const styles = {
  cursor: {cursor: 'pointer'},
  tooltipBox: {
    border: '1px solid #ccc',
    backgroundColor: 'white',
    padding: '8px 12px',
    width: 'max-content',
  },
  loader: {
    width: 100,
    height: 100,
    marginTop: 100,
  },
  label: {
    fontWeight: 'normal',
    color: 'grey',
  },
  value: {
    fontWeight: 'bold',
    marginBottom: 0,
  },
  barChart: {
    width: 0,
  },
  toolTip: {
    zIndex: 100,
  },
  modal: {
    backgroundColor: 'white',
    padding: 30,
    borderRadius: 10,
    width: 'auto',
  },
};
