// React
import React, {useMemo} from 'react';

// Third party
import {
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
} from 'recharts';
import {Heading} from 'react-bulma-components';
import {categoryIcons} from 'constants/Icons';
import moment from 'moment';

const HABIT_COLORS = {
  one: '#788F8F',
  two: '#228A8A',
  three: '#005656',
};

const getColorForCompletions = (completions) => {
  switch (completions) {
    case 1:
      return HABIT_COLORS.one;
    case 2:
      return HABIT_COLORS.two;
    default:
      return HABIT_COLORS.three;
  }
};

const CustomizedDot = (props) => {
  const {cx, largeDataSet, index, payload, target} = props;

  const height = largeDataSet ? 8 : 18;
  const fontSize = largeDataSet ? '10px' : '20px';
  const ringHeight = height + 2;
  const y = height * 2;
  const completions = payload.value;
  const isCompleted = completions >= target;
  const fill = getColorForCompletions(payload.value);
  const text = completions > 0 ? completions : null;

  return (
    <g>
      {isCompleted ? (
        <circle
          cx={cx}
          cy={y}
          r={index > 0 ? ringHeight : 0}
          stroke="#20C01C"
          strokeWidth={2}
          fill="none"
        />
      ) : null}
      <circle cx={cx} cy={y} r={index > 0 ? height : 0} fill={fill} />
      {text ? (
        <text
          fill="white"
          x={cx}
          y={y}
          textAnchor="middle"
          dy=".3em"
          style={{
            fontSize,
          }}>
          {text}
        </text>
      ) : null}
    </g>
  );
};

export const HabitsScatterChart = (props) => {
  const {scatterData, filterData, size, x, y} = props;

  const parseDomain = () => [
    0,
    Math.max.apply(
      Math,
      scatterData.map((o) => {
        return Math.max.apply(
          Math,
          o.data.map((entry) => {
            return entry.value;
          }),
        );
      }),
    ),
  ];

  const renderChart = ({dataset, domain, key, range}) => {
    const datasetSize = dataset.data.length;
    const largeDataSet = datasetSize > 10;
    const height = largeDataSet ? 70 : 100;
    const dateFontSize = largeDataSet ? 10 : 12;
    const {target} = dataset;

    return (
      <ResponsiveContainer key={key} width={size.width} height={height}>
        <ScatterChart
          margin={{
            top: 10,
            right: 0,
            bottom: 0,
            left: 0,
          }}>
          <XAxis
            type={x.type}
            dataKey={x.key}
            interval={0}
            tick={{fontSize: dateFontSize}}
            tickLine={{transform: 'translate(0, -6)'}}
            tickFormatter={(tick) => moment(tick).format('M/D')}
          />
          <YAxis
            type={y.type}
            dataKey={y.key}
            name={dataset.name}
            width={200}
            tick={false}
            tickLine={false}
            axisLine={true}
            label={{
              value: dataset.name.replace('_', ' '),
              position: 'insideRight',
              fill: dataset.color,
              style: styles.axisLabel,
            }}
          />
          <ZAxis type="number" dataKey="value" domain={domain} range={range} />
          <Tooltip
            cursor={{strokeDasharray: '3 3'}}
            position={{y: -50}}
            wrapperStyle={styles.tooltipWrapper}
            content={<CustomTooltip target={target} />}
          />
          <Scatter
            data={dataset.data}
            fill={dataset.color}
            shape={
              <CustomizedDot target={target} largeDataSet={largeDataSet} />
            }
          />
        </ScatterChart>
      </ResponsiveContainer>
    );
  };

  const domain = useMemo(parseDomain, [scatterData]);
  const range = [16, 225];

  const categories = [
    ...new Set(
      scatterData.reduce((accum, item) => {
        return [...accum, ...item.tags];
      }, []),
    ),
  ]
    .filter((category) => {
      if (filterData.key) {
        return filterData.key === category;
      }

      return true;
    })
    .sort()
    .map((category) => ({
      name: category,
      data: scatterData.filter((data) => data.tags.includes(category)),
    }));

  return (
    <ul style={styles.list}>
      {categories.map((category, index) => {
        const listItemStyle = {
          ...styles.listItem,
          ...(index > 0 ? styles.listItemBordered : {}),
        };

        return (
          <li style={listItemStyle} key={category.name}>
            <div style={styles.categoryHeader}>
              <img
                style={styles.categoryImage}
                src={`${process.env?.PUBLIC_URL || '/'}${
                  categoryIcons[category.name.toUpperCase()]
                }`}
              />
              <Heading style={styles.listItemHeader} size={5}>
                {category.name}
              </Heading>
            </div>

            {category.data.map((habitData, categoryIndex) =>
              renderChart({
                dataset: habitData,
                domain,
                key: `${category.name}-${categoryIndex}`,
                range,
              }),
            )}
          </li>
        );
      })}
    </ul>
  );
};

const CustomTooltip = ({active, payload, label, target}) => {
  if (active && payload[0] && payload[1] && payload[2]) {
    return (
      <div style={styles.tooltipBox}>
        <h4 style={styles.tooltipTitle}>{payload[1].name}</h4>
        <p style={styles.tooltipDate}>{payload[0].value}</p>
        <div style={styles.tooltipRow}>
          <div style={styles.tooltipCell}>
            <div style={styles.tooltipLabel}>Target</div>
            <div style={styles.tooltipValue}>{target}</div>
          </div>
          <div style={styles.tooltipCell}>
            <div style={styles.tooltipLabel}>Actual</div>
            <div style={styles.tooltipValue}>{payload[2].value}</div>
          </div>
        </div>
      </div>
    );
  }
  // if no data, do NOT display a tooltip
  return null;
};

const styles = {
  loaderStyle: {
    width: 100,
    height: 100,
    marginTop: 100,
  },
  tooltipWrapper: {
    zIndex: 100,
  },
  tooltipBox: {
    border: '1px solid #ccc',
    backgroundColor: '#fff',
    borderRadius: '5px',
    padding: '1rem',
    minWidth: '200px',
  },
  tooltipTitle: {
    marginBottom: '0.5rem',
    fontSize: '1rem',
    textAlign: 'center',
  },
  tooltipLabel: {
    fontWeight: 'bold',
  },
  tooltipDate: {
    textAlign: 'center',
  },
  tooltipRow: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  tooltipCell: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  list: {
    margin: 0,
    padding: 0,
  },
  listItem: {
    listStyle: 'none',
    margin: 0,
    padding: '1rem 0 2rem',
  },
  listItemBordered: {
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  },
  listItemHeader: {
    margin: 0,
    padding: 0,
  },
  categoryHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '0.5rem',
  },
  categoryImage: {
    marginRight: '0.5rem',
    width: '40px',
    height: '40px',
  },
  value: {
    fontWeight: 'bold',
  },
  axisLabel: {
    fontWeight: 'bold',
    fontSize: 12,
    marginRight: 5,
  },
  modal: {
    backgroundColor: 'white',
    padding: 30,
    borderRadius: 10,
    width: 'auto',
  },
};
