// @flow
import {action, observable, runInAction, makeObservable} from 'mobx';
import type {IObservableArray} from 'mobx';
import moment from 'moment';
import {Models} from '@wellstone-solutions/common';
import type {
  ApiAnnouncementType,
  ApiResponseType,
  UIAnnouncementType,
} from '@wellstone-solutions/common';
import type {
  AnnouncementPreviewType,
  BaseParamsType,
  IdParamsType,
} from './types';

const {Announcement} = Models;

export class AnnouncementStore {
  // Public Observables

  // $FlowFixMe
  pastAnnouncements: IObservableArray<UIAnnouncementType> = [];

  // Private props

  rootStore: any;

  constructor(rootStore: any) {
    makeObservable(this, {
      deleteAnnouncement: action,
      pastAnnouncements: observable,
      persistNewAnnouncement: action,
      syncPastAnnouncements: action,
    });

    this.rootStore = rootStore;
  }

  // Public Methods

  async deleteAnnouncement({
    announcementId,
    organizationId,
  }: BaseParamsType & IdParamsType) {
    await Announcement.cancelByOrg({
      announcementId,
      organizationId,
    });
  }

  async syncPastAnnouncements(params: BaseParamsType) {
    const getAllParams = {};
    if (params.groupId) {
      getAllParams.group_id = params.groupId;
    }

    const result = await Announcement.getAllByOrg(params.organizationId, {
      params: getAllParams,
    });

    if (result.isSuccess) {
      const uiAnnouncements = result.data.announcements.map(Announcement.toUI);

      runInAction(() => {
        this.pastAnnouncements.replace(uiAnnouncements);
      });
    }
  }

  async persistNewAnnouncement(
    params: BaseParamsType,
    {content, title}: AnnouncementPreviewType,
  ): Promise<ApiResponseType<ApiAnnouncementType>> {
    const newAnnouncement = Announcement.ApiFactory({
      content,
      group_id: params.groupId,
      title,
      status: 'draft',
      scheduled: this._getScheduledDate(),
      author: params.author,
    });

    const result = await Announcement.createByOrg({
      announcement: newAnnouncement,
      organizationId: params.organizationId,
    });

    if (result.isSuccess) {
      // Now publish it
      await Announcement.publishByOrg({
        announcementId: result.data.id ? result.data.id : '',
        organizationId: params.organizationId,
        groupId: params.groupId ? params.groupId : '',
      });
    }

    return result;
  }

  _getScheduledDate(): string {
    // $FlowFixMe
    return new moment(new Date()).utc().toISOString();
  }
}
