// @flow
import React from 'react';
// TODO - Replace with import from Common Libs when available
import {Breadcrumbs as MuiBreadcrumbs} from '@mui/material';
import {observer} from 'mobx-react';
import {Box, Typography} from '@wellstone-solutions/web';
import type {BreadcrumbType} from 'modules/app';
import {useStores} from 'hooks/useStores';
import {Link} from 'components/Link';

export const Breadcrumbs = observer(() => {
  const {appUIStore} = useStores();

  if (!appUIStore.breadcrumbs || appUIStore.breadcrumbs.length === 0) {
    return null;
  }

  return (
    <Box>
      <MuiBreadcrumbs aria-label="breadcrumb">
        {appUIStore.breadcrumbs.map((breadcrumb: BreadcrumbType) => {
          return breadcrumb.active ? (
            <Typography
              key={breadcrumb.name}
              variant="body"
              sx={{display: 'inline', paddingRight: 1}}>
              {breadcrumb.name}
            </Typography>
          ) : (
            <Link
              key={breadcrumb.name}
              to={breadcrumb.url}
              sx={{fontWeight: 'normal'}}>
              {breadcrumb.name}
            </Link>
          );
        })}
      </MuiBreadcrumbs>
      <hr />
    </Box>
  );
});
