// @flow
import {action, computed, observable, runInAction, makeObservable} from 'mobx';
import type {IObservable, IObservableArray} from 'mobx';
import RootStore from 'mobx/RootStore';
import {Models, Utils} from '@wellstone-solutions/common';
import moment from 'moment';
import type {
  ApiResponseType,
  ApiCalendarEventType,
  ApiCalendarEventInvitationType,
  UICalendarEventType,
  UICalendarEventInvitationType,
} from '@wellstone-solutions/common';
import {concatDateTime, getDateRange} from './utils';
import {buildIdLookup} from 'utils/buildIdLookup';
import type {ScheduledEventType, UIParticipantType} from './types';
import {toScheduledEvent} from './transforms';
import {STATUSES, RECURRENCE_FREQ_MAP} from './constants';
import type {NotificationType} from 'modules/notifications/types';

const {CalendarEvent, CalendarEventInvitation} = Models;

const CALENDAR_EVENT_WINDOW = 6;
const CALENDAR_EVENT_PERIOD = 'months';

export class CalendarStore {
  rootStore: RootStore;
  calendarEvents: IObservableArray<UICalendarEventType> = observable.array();

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      calendarEvents: observable,
      activeEvents: computed,
      dismissNotificationsByCalenderEventId: action,
    });

    this.rootStore = rootStore;
  }

  async init(): Promise<mixed> {
    await this.getAllCalendarEvents();
  }

  async getAllCalendarEvents(): Promise<mixed> {
    const dateRange = getDateRange(
      CALENDAR_EVENT_WINDOW,
      CALENDAR_EVENT_WINDOW,
      CALENDAR_EVENT_PERIOD,
    );

    return Promise.all([
      CalendarEvent.getAll({
        params: {
          // $FlowIgnoreMe
          owner: this.rootStore.stores.meStore.me.id,
          start: dateRange.start,
          end: dateRange.end,
        },
      }),
    ]).then(([calendarEventsResponse]) => {
      const rawCalendarEvents = calendarEventsResponse.isSuccess
        ? calendarEventsResponse.data.calendar_events
        : [];

      const uiCalendarEvents = rawCalendarEvents.map(CalendarEvent.toUI);

      runInAction(() => {
        this.calendarEvents.replace(uiCalendarEvents);
      });
    });
  }

  async getCalendarEvent({
    id,
    originalStart,
    forceFetch = true,
  }: {
    id: string,
    originalStart: string,
    forceFetch?: boolean,
  }): Promise<ApiResponseType<IObservable<UICalendarEventType> | void> | void> {
    const foundCalendarEvent = this.findCalendarEvent(id, originalStart);

    if (!forceFetch && foundCalendarEvent) {
      return Promise.resolve(foundCalendarEvent);
    }

    const response = await CalendarEvent.get(id, {
      params: {
        // $FlowIgnoreMe
        owner: this.rootStore.stores.meStore.me.id,
        original_start: originalStart,
      },
    });

    if (response.isSuccess) {
      const uiCalendarEvent = CalendarEvent.toUI(response.data);

      // update this.staff to include the new fetched staff member
      const uiCalendarEvents = foundCalendarEvent
        ? this.calendarEvents.map((event: UICalendarEventType) =>
            event.id === id && event.originalStart === originalStart
              ? uiCalendarEvent
              : event,
          )
        : [...this.calendarEvents, uiCalendarEvent];

      runInAction(() => {
        this.calendarEvents.replace(uiCalendarEvents);
      });

      return {...response, data: this.findCalendarEvent(id, originalStart)};
    }

    // $FlowFixMe
    return response;
  }

  findCalendarEvent(
    id: string,
    originalStart: string,
  ): IObservable<UICalendarEventType> | void {
    return this.calendarEvents.find(
      (event: UICalendarEventType) =>
        event.id === id && event.originalStart === originalStart,
    );
  }

  get activeEvents(): UICalendarEventType[] {
    return this.calendarEvents.filter(
      (calendarEvent: UICalendarEventType) =>
        calendarEvent.status !== STATUSES.canceled,
    );
  }

  dismissNotificationsByCalenderEventId(eventId: string): void {
    const {appNotificationStore} = this.rootStore.stores;

    const notificationsToDismiss = appNotificationStore.notifications.filter(
      (
        notification: NotificationType<{calendarEvent: UICalendarEventType}>,
      ) => {
        if (!notification.data?.calendarEvent) {
          return false;
        }
        return notification.data.calendarEvent.id === eventId;
      },
    );

    if (notificationsToDismiss.length <= 0) {
      return;
    }

    notificationsToDismiss.forEach(
      // $FlowFixMe
      appNotificationStore.dismissNotification.bind(appNotificationStore),
    );
  }

  async cancelCalendarEvent(
    calendarEvent: UICalendarEventType,
    applyToAll?: boolean,
  ): Promise<ApiResponseType<ApiCalendarEventType>> {
    const {originalStart} = calendarEvent;
    const apiCalendarEvent = CalendarEvent.toApi(calendarEvent);

    const calendarEventResponse = await CalendarEvent.cancel(apiCalendarEvent, {
      params: {
        // $FlowFixMe - original_start is optional UICalendarEventType and required in UpdateParamsType
        owner: this.rootStore.stores.meStore.me.id,
        original_start: String(originalStart),
        apply_to_all: !!applyToAll,
      },
    });

    if (calendarEventResponse.isSuccess) {
      // filter the events to exclude the events being canceled
      const eventFilter = applyToAll
        ? (c: UICalendarEventType) =>
            // if canceling "this and following events" remove all events after and including the selected date
            !(
              c.id === calendarEvent.id &&
              moment(c.originalStart).isSameOrAfter(calendarEvent.originalStart)
            )
        : (c: UICalendarEventType) =>
            // if canceling "this event" remove only the selected event
            !(
              c.id === calendarEvent.id &&
              c.originalStart === calendarEvent.originalStart &&
              c.endTime === calendarEvent.endTime
            );

      runInAction(() => {
        this.calendarEvents.replace(this.calendarEvents.filter(eventFilter));
      });
    }

    return calendarEventResponse;
  }

  async createCalendarEvent(
    calendarEvent: UICalendarEventType,
  ): Promise<ApiResponseType<ApiCalendarEventType>> {
    calendarEvent.startDate = this._adjustedRecurrenceStartDate(calendarEvent);
    const payload = CalendarEvent.toApi(calendarEvent);

    const response = await CalendarEvent.create(payload);

    return response;
  }

  async updateCalendarEvent(
    calendarEvent: UICalendarEventType,
    applyToAll?: boolean,
  ): Promise<ApiResponseType<ApiCalendarEventType>> {
    if (applyToAll) {
      calendarEvent.startDate = this._adjustedRecurrenceStartDate(
        calendarEvent,
      );
    }
    // TODO: Remove logic that excludes these fields once the API is updated for them
    const {originalStart, ...rest} = calendarEvent;

    // $FlowIgnoreMe TODO: Remove once all the fields are recognized by API
    const payload = CalendarEvent.toApi(rest);
    const options = {
      params: {
        // $FlowIgnoreMe
        owner: this.rootStore.stores.meStore.me.id,
        original_start: originalStart,
        apply_to_all: !!applyToAll,
      },
    };
    // $FlowIgnoreMe
    const response = await CalendarEvent.update(payload, options);

    return response;
  }

  async updateCalendarEventInvitation(
    eventId: string,
    calendarEventInvitation: UICalendarEventInvitationType,
    params: {
      owner: string,
      originalStart: string,
      applyToAll: boolean,
    },
  ): Promise<ApiResponseType<ApiCalendarEventInvitationType>> {
    const response = await CalendarEventInvitation.updateFields(
      eventId,
      CalendarEventInvitation.toApi(calendarEventInvitation),
      {
        params: {
          owner: params.owner,
          original_start: params.originalStart,
          apply_to_all: params.applyToAll,
        },
      },
    );

    if (response.isSuccess) {
      // refresh events to pick up isAttending on this and any recurrings
      this.getAllCalendarEvents();
    }

    return response;
  }

  async getAdjacentEvents(
    startDate: string,
  ): Promise<Array<ScheduledEventType>> {
    const response = await CalendarEvent.getAll({
      params: {
        // $FlowIgnoreMe
        owner: this.rootStore.stores.meStore.me.id,
        start: concatDateTime(startDate, '00:00'),
        end: concatDateTime(startDate, '23:59'),
      },
    });

    const rawCalendarEvents = response.isSuccess
      ? response.data.calendar_events
      : [];

    const calendarEvents = rawCalendarEvents
      .filter((event) => event.status !== STATUSES.canceled)
      .map(CalendarEvent.toUI)
      .map((uiCalendarEvent) =>
        toScheduledEvent({
          calendarEvent: uiCalendarEvent,
          invitations: uiCalendarEvent.invitations,
          // $FlowIgnoreMe
          myUserId: this.rootStore.stores.meStore.me.id,
        }),
      );

    return calendarEvents;
  }

  // adds flags for UI Components to use
  _augmentInvitations(
    invitations: IObservableArray<UICalendarEventInvitationType>,
    owner: string,
  ): IObservableArray<UICalendarEventInvitationType> {
    // $FlowIgnoreMe
    const myUserId = this.rootStore.stores.meStore.me.id;

    return invitations
      .map((invite) => {
        if (invite.user === myUserId) {
          // remove self from shown invitations
          return;
        }
        if (invite.user === owner) {
          // allows for showing owner indicator
          return {
            ...invite,
            isOwner: true,
          };
        }
        return invite;
      })
      .filter(Boolean);
  }

  // The startDate needs to be set to the first occurence of the recurrence, when Weekly is selected.
  _adjustedRecurrenceStartDate(calendarEvent: UICalendarEventType): string {
    if (
      calendarEvent.isRecurring &&
      calendarEvent.recurrence.freq === RECURRENCE_FREQ_MAP.weekly &&
      calendarEvent.recurrence.byweekday
    ) {
      const adjustedStartDate = Utils.adjustStartByRecurrence(
        calendarEvent.startDate,
        calendarEvent.recurrence.byweekday,
      );
      return moment(adjustedStartDate).toISOString();
    }
    return calendarEvent.startDate;
  }

  getNamedParticipants(
    invitations: IObservableArray<UICalendarEventInvitationType>,
    owner: string,
  ): Array<UIParticipantType> {
    if (!invitations) {
      return [];
    }

    const users = [
      ...this.rootStore.stores.memberStore.members,
      ...this.rootStore.stores.staffStore.staff,
    ].map((person) => person.user);

    // adds flags for UI Components to use
    const augmentedInvitations = this._augmentInvitations(invitations, owner);

    const userLookup = buildIdLookup(users);
    const namedInvitations = augmentedInvitations
      .map((invite) => {
        const foundUser = userLookup[invite.user];
        if (!foundUser) {
          return null;
        }
        return {
          id: invite.user,
          name: foundUser.name,
          email: foundUser.email,
          isAttending: invite.isAttending,
          isOwner: invite.isOwner,
        };
      })
      .filter(Boolean);

    return namedInvitations;
  }
}
