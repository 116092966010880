// Third Party
import {observable, computed, action, makeObservable} from 'mobx';

// WS
import type {
  UIMemberType,
  UIPermittedEntityType,
  UIProgramType,
} from '@wellstone-solutions/common';
import {Api, Roles, Models} from '@wellstone-solutions/common';
import {Login} from '@wellstone-solutions/common/models/v2';
import {Storage} from '@wellstone-solutions/web';
import {routes} from 'api';
import {buildIdLookup} from 'utils/buildIdLookup';
import {STAFF_PERMISSIONS} from 'constants/Permissions';
import {ENTITY_LEVEL, ENTITY_TYPES} from 'modules/rbac';
import {hasFlag, PENDO_ENABLED} from '../constants/FeatureFlags';
import {
  loadPendo,
  setupPendoForCurrentUser,
  resetPendo,
  removePendoScript,
} from '../utils/pendoManager';
import {jwtDecode} from 'jwt-decode';

const {Integration, PermittedEntity, UserData} = Models;

const CRYPTO_KEY = process.env.REACT_APP_CRYPTO_KEY;
const STORED_V2_TOKEN_KEY = 'stored_v2_token';
const STORED_PRIVATE_KEY = 'stored_pk';
const STORED_SELECTED_ORGANIZATION = 'stored_selected_organization';
const STORED_DEFAULT_CALENDAR_VIEW = 'stored_default_calendar_view';
const TOU_VERSION = '1.2';

// 'stored_token', and 'stored_pubnub_creds' are legacy keys.
// Keep them here to clean up old data on next signout
const CLEAN_UP_KEYS = [
  'stored_token',
  'stored_pubnub_creds',
  STORED_V2_TOKEN_KEY,
  STORED_PRIVATE_KEY,
  STORED_SELECTED_ORGANIZATION,
];

class MeStore {
  constructor(rootStore) {
    makeObservable(this, {
      // observables
      email: observable,
      features: observable,
      isSigningOut: observable,
      isLoggedIn: observable,
      me: observable,
      searchBoxTally: observable,

      // computed values
      permittedEntities: computed,
      permittedGroupIds: computed,
      activeMembership: computed,
      hasActiveMembership: computed,
      isStaffMember: computed,
      isSuperAdmin: computed,
      myPrograms: computed,
      myProgramsLookup: computed,
      organizationId: computed,

      // actions
      init: action,
      clear: action,
      clearData: action,
      edit: action,
      requestCode: action,
      setActiveMembership: action,
      setActiveCalendarView: action,
      setActiveOrganization: action,
      setFeatures: action,
      signOut: action,
      tickSearchBox: action,
    });
    this.rootStore = rootStore;
  }

  // represents a combination of real and tutorial authorizations for certain UI purposes during tutorial
  // TODO: Remove this once the tutorial is refactored and no longer depends on this
  email = '';
  features = [];
  isSigningOut = false;
  isLoggedIn = observable.box(false);
  me = {};
  searchBoxTally = 0;
  privateKey = '';
  staffRoles = Object.values(Roles).filter((r) => r !== Roles.PATIENT);
  activeCalendarView = '';

  get RBACStore() {
    return this.rootStore.stores.RBACStore;
  }

  async init() {
    // Get persisted token and private key
    // The token is required to make api calls and
    const v2Token = this.getPersistedItem(STORED_V2_TOKEN_KEY);
    const privateKey = this.getPersistedItem(STORED_PRIVATE_KEY);

    const activeCalendarView = Storage.getItem(
      STORED_DEFAULT_CALENDAR_VIEW,
      CRYPTO_KEY,
    );

    if (!v2Token || !privateKey) {
      return false;
    }

    this.privateKey = privateKey;
    this.activeCalendarView = activeCalendarView;

    if (!this.isAuthenticated()) {
      // when the user loads the page but is already logged in
      // since they havent gone through the /login flow
      // we need to set the bearer token
      Api.Instance.v2().setAuthTokens(v2Token);
      this.isLoggedIn.set(true);
    }

    const [me, features] = await Promise.all([
      this.getMe(),
      this.getFeatures(),
    ]);

    // If either of these failed, bounce out
    // this will force the user to sign in again
    if (!me || !features) {
      return false;
    }

    // We use organizations id they selected on login to determine selected membership
    // this would be better off as a computed, however me.membership is
    // sprinkled everywhere, so leaving as is for now
    await this.setActiveMembership(me);
    // Ensure we have an active membership
    if (!this.hasActiveMembership) {
      return false;
    }

    this.setFeatures(features);
    this.setPendo(this.features);

    // Fetch all programs
    // This is needed to get a complete set of permittedEntities on load
    await this.rootStore.stores.programStore.getAllPrograms();

    // Validate current terms of use
    const currentTouObj = {data: {...me.data, touVersion: TOU_VERSION}};
    if (me.data?.touVersion !== TOU_VERSION) {
      UserData.updateUserData(currentTouObj);
    }

    return true;
  }

  // Complete list of all entities a user has the ability to read/write to
  get permittedEntities(): UIPermittedEntityType[] {
    if (
      !this.hasActiveMembership ||
      this.RBACStore.hasEntityLevelAccess(ENTITY_LEVEL.all)
    ) {
      return [];
    }

    // Convert all auths to permittedEntities
    const permittedEntities: UIPermittedEntityType[] = this.activeMembership.authorizations.map(
      (auth) =>
        PermittedEntity.Factory({
          id: auth.id,
          entityType: auth.obj_type,
          entityId: auth.obj_id,
          permissions: auth.permissions,
          isInherited: false,
        }),
    );

    // Program permissions will fill any all groups underneath the users authorized programs
    if (this.RBACStore.hasEntityLevelAccess(ENTITY_LEVEL.program)) {
      const existingLookup = buildIdLookup(permittedEntities, 'entityId');

      // any program that you are a part of grants you access to every group in that program
      const inheritedGroupPerms: UIPermittedEntityType[] = this.myPrograms
        .map((program: UIProgramType) =>
          program.groups.map((groupId: string) => {
            // only create inherited permittedEntity if one does not exists
            if (!existingLookup[groupId]) {
              return PermittedEntity.Factory({
                entityId: groupId,
                entityType: 'Group',
                permissions: [STAFF_PERMISSIONS.read, STAFF_PERMISSIONS.write],
                isInherited: true,
              });
            }
          }),
        )
        .flat()
        .filter(Boolean);

      return [...permittedEntities, ...inheritedGroupPerms];
    }

    // Group permissions are identical to their authorizations
    // there are no inherited permissions
    if (this.RBACStore.hasEntityLevelAccess(ENTITY_LEVEL.group)) {
      return permittedEntities;
    }

    return [];
  }

  get permittedGroupIds(): string[] {
    if (!this.hasActiveMembership) {
      return [];
    }

    return this.permittedEntities
      .filter(
        (entity) => entity.entityType.toLowerCase() === ENTITY_TYPES.group,
      )
      .map((entity) => entity.entityId);
  }

  get permittedProgramIds(): string[] {
    if (!this.hasActiveMembership) {
      return [];
    }

    return this.permittedEntities
      .filter(
        (entity) => entity.entityType.toLowerCase() === ENTITY_TYPES.program,
      )
      .map((entity) => entity.entityId);
  }

  get activeMembership() {
    return this.me?.membership;
  }

  get hasActiveMembership() {
    return !!this.activeMembership;
  }

  isAuthenticated() {
    return this.isLoggedIn?.get();
  }

  isAllowedRole(roles) {
    return roles.length > 0 && roles.includes(this.activeMembership?.role);
  }

  get isStaffMember() {
    return this.staffRoles.includes(this.activeMembership.role);
  }

  get isSuperAdmin() {
    return this.activeMembership.role === Roles.SUPER_ADMIN;
  }

  get ehrIntegrationOption() {
    const apiEHRIntegration = this.activeMembership.organization?.integrations.find(
      (integration) => integration?.integration_type === 'ehr',
    );

    if (apiEHRIntegration) {
      const uiEHRIntegration = Integration.toUI(apiEHRIntegration);
      return uiEHRIntegration;
    }

    return null;
  }

  // a list of programs that correlate with my program authorizations
  get myPrograms() {
    if (this.isSuperAdmin) {
      return this.rootStore.stores.programStore.programs;
    }

    return this.activeMembership.authorizations
      .map((auth) => {
        if (auth.obj_type.toLowerCase() === 'program') {
          return this.rootStore.stores.programStore.programsMap[auth.obj_id];
        }

        return null;
      })
      .filter(Boolean);
  }

  get myProgramsLookup() {
    return buildIdLookup(this.myPrograms);
  }

  get organizationId() {
    return this.activeMembership.organization.id;
  }

  setTutorial() {
    this.rootStore.stores.appStore.menuShowing = !(
      window.innerWidth < 1087 && !this.rootStore.stores.tutorialStore.isActive
    );
  }

  inMyPrimaryGroups(member: UIMemberType): boolean {
    // Admins always have primary access to members
    if (
      this.rootStore.stores.RBACStore.hasEntityLevelAccess(ENTITY_LEVEL.program)
    ) {
      return true;
    }

    const memberPrimaryGroups = this.rootStore.stores.memberStore.getPrimaryGroupIds(
      member,
    );

    return this.permittedGroupIds.some((id) =>
      memberPrimaryGroups.includes(id),
    );
  }

  setActiveOrganization(organizationId) {
    Storage.setItem(STORED_SELECTED_ORGANIZATION, organizationId, CRYPTO_KEY);
  }

  setActiveCalendarView(viewName) {
    this.activeCalendarView = viewName;
    Storage.setItem(STORED_DEFAULT_CALENDAR_VIEW, viewName, CRYPTO_KEY);
  }

  findSelectedMembership(me) {
    const selectedOrgId = this.getPersistedItem(
      STORED_SELECTED_ORGANIZATION,
      true,
    );
    if (selectedOrgId && me?.memberships) {
      return me.memberships.find(
        (membership) => membership.organization.id === selectedOrgId,
      );
    }

    return undefined;
  }

  async setActiveMembership(me) {
    const membership = this.findSelectedMembership(me);

    if (!membership) {
      await this.signOut();
    } else {
      this.setMe({...me, membership});
    }
  }

  setMe(me) {
    this.me = me;
  }

  getPersistedItem(key: string, forceSignOut: boolean = true): any {
    let value = null;
    try {
      value = Storage.getItem(key, CRYPTO_KEY);
    } catch {
      if (forceSignOut) {
        this.signOut();
      }
    }

    return value;
  }

  persistV2Tokens(v2Response: {access: string, refresh: string}) {
    Storage.setItem(STORED_V2_TOKEN_KEY, v2Response, CRYPTO_KEY);
  }

  persistPrivateKey(privateKey: string) {
    Storage.setItem(STORED_PRIVATE_KEY, privateKey, CRYPTO_KEY);
  }

  async requestCode(email) {
    const response = await Login.generateCode(email);
    this.email = email;
    return response;
  }

  async handleAuthResponse(authTokens) {
    Api.Instance.v2().setAuthTokens(authTokens);
    const {privateKey} = jwtDecode(authTokens.access);

    // Validate that the user has a staff role
    // It would be better to check accessRoles from the JWT instead but
    // the current login flow requires the full membership object anyways
    const me = await this.getMe();
    if (!me || me.memberships.length === 0) {
      return null;
    }

    this.persistV2Tokens(authTokens);
    this.persistPrivateKey(privateKey);

    this.isLoggedIn.set(true);

    return me.memberships;
  }

  async signin(email, code) {
    try {
      const authResponse = await Login.loginWithCode(email, code);
      return await this.handleAuthResponse(authResponse.data);
    } catch (error) {
      return [];
    }
  }

  async signinWithPassword(email, password) {
    try {
      const authResponse = await Login.loginWithPassword(email, password);
      const memberships = await this.handleAuthResponse(authResponse.data);
      return {memberships, response: authResponse};
    } catch (error) {
      return {memberships: [], response: error};
    }
  }

  setFeatures(features) {
    // get active orginization
    const activeOrganization = this.activeMembership?.organization?.id || null;

    // filter features based on active organization -- help with this
    const filteredFeatures = features.filter((feature) => {
      if (feature.organizations.length === 0) {
        return true;
      }
      return feature.organizations.includes(activeOrganization);
    });
    this.features = filteredFeatures;
  }

  setPendo(features) {
    if (hasFlag(PENDO_ENABLED, features)) {
      loadPendo();
      setupPendoForCurrentUser(this.me);
    }
  }

  async getFeatures() {
    const response = await Api.Instance.current().get('/stoplight/features');

    return response.data?.features;
  }

  async getMe() {
    const response = await Api.Instance.current().get(routes.me);

    return response.isSuccess ? response.data : null;
  }

  edit(key, prop) {
    this.me[key] = prop;
  }

  async updateTutorialStep(step) {
    await Api.Instance.current().post('/users/me', {
      data: {
        ...this.me.data,
        tutorial_bridge: step,
      },
    });
    this.me.data.tutorial_bridge = step;
  }

  clear() {
    // Clear out token from api
    Api.Instance.v2().setAuthTokens({access: '', refresh: ''});
    this.isLoggedIn.set(false);

    // Clean up keys
    CLEAN_UP_KEYS.forEach(Storage.removeItem);

    this.me = {};
    this.features = [];
  }

  // async so we can await it below during signout
  clearData = async () => {
    this.isSigningOut = true;
    // delete pendo if org had pendo
    if (hasFlag(PENDO_ENABLED, this.features)) {
      resetPendo();
      removePendoScript();
    }

    const {stores} = this.rootStore;
    if (stores.tutorialStore.isActive) {
      stores.tutorialStore.setIsActive(false);
    }

    Object.keys(stores).forEach((store) => {
      const blacklist = ['meStore', 'notificationStore'];
      if (!blacklist.includes(store) && stores[store].clear) {
        stores[store].clear();
      }
    });
    this.clear();
    window.location.href = '/auth/login';
    await new Promise(() => {}); // Never resolves, suspends execution
  };

  signOut = async () => {
    try {
      await Login.logout();
    } catch (e) {
      console.error('API V2: Sign out failed', e);
    } finally {
      await this.clearData();
    }
  };

  tickSearchBox = () => {
    this.searchBoxTally = this.searchBoxTally += 1;
  };
}

export default MeStore;
