// @flow
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import type {Node} from 'react';
import {Form, Button} from 'react-bulma-components';
import {Stack, Typography} from '@wellstone-solutions/web';
import {fieldStatus} from 'utils/Forms';
import {EMAIL_PLACEHOLDER} from '../constants';
const {Field, Control, Input} = Form;

type PropsType = {
  requestReset: (string) => Promise<void>,
};

export const EmailStage = ({requestReset}: PropsType): Node => {
  const [email, setEmail] = useState('');

  const isInvalidEmail = Boolean(fieldStatus(email, 'email', true).error);

  return (
    <Stack>
      <Field>
        <Control>
          <Input
            data-testid="email-input"
            type="email"
            required
            placeholder={EMAIL_PLACEHOLDER}
            onChange={(newVal) => {
              setEmail(newVal.target.value);
            }}
            value={email}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                requestReset(email);
              }
            }}
          />
        </Control>
        <Typography sx={{fontStyle: 'italic', fontSize: 12, mt: 0.5, ml: 0.5}}>
          Enter your email address above and we'll send you password reset
          instructions.
        </Typography>
      </Field>
      <Stack direction="row">
        <Button
          color="primary"
          disabled={isInvalidEmail}
          data-testid="send-reset"
          onClick={() => requestReset(email)}>
          Continue
        </Button>
        <Stack
          sx={{ml: 'auto', 'a:hover': {color: 'currentcolor'}}}
          direction="row">
          <Button inverted color="primary">
            <Link to="/auth/login?method=password">Back to Login</Link>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
