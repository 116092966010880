// @flow
import React from 'react';
import type {Node} from 'react';
import {Heading} from 'react-bulma-components';
import {MarkdownViewer} from '@wellstone-solutions/web';
import type {AnnouncementPreviewType} from '../../types';
import {AnnouncementContentWrapper} from '../AnnouncementContentWrapper';
import {Theme} from '@wellstone-solutions/common';

type PropsType = {
  fields: AnnouncementPreviewType,
};

export const Preview = ({fields}: PropsType): Node => (
  <div style={styles.container}>
    <Heading size={5} style={styles.heading}>
      Preview
    </Heading>
    <div style={styles.title}>{fields.title}</div>
    <div style={styles.markdown}>
      <AnnouncementContentWrapper>
        <MarkdownViewer value={fields.content} />
        <div style={styles.createdBy}>
          by
          <strong> {fields.author}</strong>
        </div>
      </AnnouncementContentWrapper>
    </div>
  </div>
);

const styles = {
  container: {
    backgroundColor: Theme.colorPalette.lightest,
    padding: '1.5rem',
    display: 'block',
    width: '100%',
    overflowY: 'auto',
  },
  heading: {
    color: Theme.colorPalette.dark,
    fontSize: '1.25rem',
    fontWeight: '500',
    marginBottom: '1rem',
    borderBottom: '1px solid ' + Theme.colorPalette.mediumLight,
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: '500',
    marginBottom: '1rem',
    color: Theme.colorPalette.secondary,
    borderBottom: '1px solid ' + Theme.colorPalette.secondary3,
  },
  markdown: {
    fontSize: '1rem',
    lineHeight: '1.5',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
  },
  createdBy: {
    fontSize: '0.875rem',
    marginTop: '1rem',
    fontStyle: 'italic',
  },
};
