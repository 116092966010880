// @flow
import {action, computed, observable, makeObservable} from 'mobx';
import type {
  ApiResponseType,
  UILocationType,
  UILocationSuggestionType,
} from '@wellstone-solutions/common';
import {Models} from '@wellstone-solutions/common';
import RootStore from 'mobx/RootStore';

const {Location} = Models;

export class LocationStore {
  rootStore: RootStore;
  currentLocation: UILocationType | null = null;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      currentLocation: observable,
      getLocationSuggestions: action,
      saveCurrentLocation: action,
      currentLocationDisplayText: computed,
    });

    this.rootStore = rootStore;
  }

  async getLocationSuggestions(
    query: string,
  ): Promise<ApiResponseType<Array<UILocationSuggestionType>>> {
    const response = await Location.getLocationSuggestions({query});
    if (response.isSuccess) {
      const uiSuggestions = response.data.map(Location.toUILocationSuggestion);
      return {...response, data: uiSuggestions};
    } else {
      return {...response, data: []};
    }
  }

  saveCurrentLocation(location: UILocationType | null): void {
    this.currentLocation = location;
  }

  get currentLocationDisplayText(): string | null {
    if (!this.currentLocation) return null;

    const {city, state, formattedAddress} = this.currentLocation;

    if (city && state) {
      return `${city}, ${state}`;
    }

    return formattedAddress || null;
  }
}
