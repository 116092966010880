// @flow
import React, {useCallback, useRef, useState} from 'react';
import type {Node} from 'react';
import {Heading, Level, Modal} from 'react-bulma-components';
import {Paper} from '@wellstone-solutions/web';
import {Hooks, Models, Theme} from '@wellstone-solutions/common';
import {observer} from 'mobx-react';
import {useStores} from 'hooks/useStores';
import {AccessControl} from 'modules/rbac';
import {PfButton} from 'components/shared/PfButton';
import {Form} from '../Form';
import {Preview} from '../Preview';

const NEW_ORG_ANNOUNCEMENT = 'New Organization Announcement';
const NEW_GROUP_ANNOUNCEMENT = 'New Group Announcement';

const AdminsOnlyMessage = () => (
  <p style={styles.message}>
    Only administrators can send announcements to the entire organization.
    Select a group from the dropdown above to send a Group Announcement.
    <br />
    <br />
    If you would like to get a message out to the entire organization's clients,
    please get in touch with someone who has an admin account.
  </p>
);

type PropsType = {
  groupId: string,
  groupName: string,
};

const FORM_ID = 'new-form';
export const NewAnnouncement: any = observer(
  ({groupId, groupName}: PropsType): Node => {
    const [isModal, setIsModal] = useState(false);
    const contentRef = useRef();
    const {announcementStore, meStore, RBACStore} = useStores();
    const organizationId = meStore.me.membership.organization.id;

    const initialAnnouncement = Models.Announcement.Factory();
    const form = Hooks.useForm({
      initialValues: {
        content: initialAnnouncement.content,
        id: initialAnnouncement.id,
        scheduled: initialAnnouncement.scheduled,
        status: 'draft',
        title: initialAnnouncement.title,
      },
      schema: Models.Announcement.schema,
      onSubmit: async (values, {resetForm}) => {
        const result = await announcementStore.persistNewAnnouncement(
          {
            organizationId,
            groupId,
            author: getCreatedBy(),
          },
          values,
        );

        if (result.isSuccess) {
          announcementStore.syncPastAnnouncements({
            organizationId,
            groupId,
          });
          resetContent();
          resetForm();
          hideModal();
        }
      },
    });

    const isTouched = Object.keys(form.touched).length > 0;
    const hasErrors = Object.keys(form.errors).length > 0;

    const resetContent = useCallback(() => {
      // $FlowFixMe
      contentRef.current.getInstance().setMarkdown('');
    }, []);

    const hideModal = () => setIsModal(false);

    const canSendToAllMembers = RBACStore.hasAccess(
      AccessControl.announcements.sendToAllMembers,
    );

    const isOrgWide = !groupId;
    const isNotPermitted = isOrgWide && !canSendToAllMembers;

    const pageHeading = isOrgWide
      ? NEW_ORG_ANNOUNCEMENT
      : NEW_GROUP_ANNOUNCEMENT;

    const getCreatedBy = () => {
      if (groupName === 'All Members') {
        return meStore.me.membership.organization.name;
      }
      return meStore.me.name;
    };

    return (
      <>
        <Modal
          onClose={hideModal}
          show={isModal}
          closeOnBlur={true}
          closeOnEsc={true}>
          <Modal.Content style={styles.modal}>
            <Heading style={styles.heading} size={4}>
              Confirm
            </Heading>
            <div style={styles.modalPreview}>
              <Preview fields={{...form.values, author: getCreatedBy()}} />
            </div>
            <div style={styles.modalButtonContainer}>
              <PfButton
                form={FORM_ID}
                isDisabled={form.isSubmitting}
                isLoading={form.isSubmitting}
                noStyles={false}
                size="medium"
                style={{}}
                color="primary"
                type="submit"
                onClick={() => {}}>
                Send to {groupName}
              </PfButton>
              <PfButton
                isDisabled={false}
                isLoading={false}
                noStyles={false}
                size="medium"
                style={{}}
                color="light"
                onClick={hideModal}>
                Cancel
              </PfButton>
            </div>
          </Modal.Content>
        </Modal>

        <Paper style={styles.container} sx={{padding: 4, marginBottom: 4}}>
          <Heading style={styles.heading} size={4}>
            {pageHeading}
          </Heading>
          {isNotPermitted ? (
            <AdminsOnlyMessage />
          ) : (
            <Level style={styles.formContainer}>
              <Level.Side style={styles.form}>
                <div style={styles.formParent}>
                  {/* $FlowFixMe */}
                  <Form form={form} formId={FORM_ID} ref={contentRef} />
                </div>
                <div style={styles.buttonContainer}>
                  <PfButton
                    isDisabled={hasErrors || !isTouched}
                    isLoading={false}
                    noStyles={false}
                    color="primary"
                    onClick={() => setIsModal(true)}>
                    Create
                  </PfButton>
                </div>
              </Level.Side>
              <Level.Side align="left" style={styles.preview}>
                <Preview fields={{...form.values, author: getCreatedBy()}} />
              </Level.Side>
            </Level>
          )}
        </Paper>
      </>
    );
  },
);

const styles = {
  buttonContainer: {
    marginTop: '1rem',
    padding: '1rem 0 0',
  },
  button: {
    borderSize: 0,
    paddingRight: '15px',
    width: '276px',
  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
    padding: '1.5rem',
    borderRadius: '10px',
    boxShadow:
      '0 0.1em 0.25em -0.1em rgba(10, 10, 10, 0.2), 0 0px 0 1px rgba(10, 10, 10, 0.02)',
  },
  formParent: {
    maxWidth: '500px',
    width: '100%',
    marginRight: '2rem',
  },
  form: {
    alignItems: 'flex-start',
    flexDirection: 'column',
    flex: 2,
    maxWidth: '600px',
    minWidth: '300px',
    '@media screen and (min-width: 768px)': {
      marginTop: '20px',
    },
  },
  formContainer: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: '2rem',
  },
  heading: {
    color: Theme.colorPalette.dark,
    fontSize: '1.5rem',
    fontWeight: '500',
    marginBottom: '1.5rem',
  },
  message: {
    fontSize: '0.8rem',
  },
  modal: {
    backgroundColor: '#fff',
    padding: '30px',
    borderRadius: '10px',
    minWidth: '200px',
    width: 'auto !important',
    boxShadow: '0 0.1em 0.25em -0.1em rgba(10, 10, 10, 0.2)',
  },
  modalButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '1rem',
    '& > button + button': {
      marginLeft: '1rem',
    },
  },
  modalPreview: {
    padding: '1rem 0',
  },
  preview: {
    flex: 1,
    '@media screen and (min-width: 768px)': {
      maxWidth: '40%',
    },
  },
};
