// @flow
import React from 'react';
import type {Node} from 'react';
import {Theme} from '@wellstone-solutions/common';
import {Stack, Typography} from '@wellstone-solutions/web';
import {Tag} from 'react-bulma-components';
import {styleTag} from 'utils/Styler';

type ColumnsMapType = {
  name: any,
  type: any,
  activeMembers: any,
};

export const useColumnMap = (): ColumnsMapType => {
  const NameCell = ({group}): Node => (
    <Stack flexDirection="column">
      <Typography sx={{fontWeight: 'bold'}}>{group.name}</Typography>
      <Typography
        sx={{
          color: Theme.colorPalette.darker,
          fontSize: '14px',
        }}>
        {group.programNames}
      </Typography>
    </Stack>
  );

  const TypeCell = ({group}): Node => (
    <Tag style={styleTag(group.type, true)}>{group.type}</Tag>
  );

  const CountCell = ({group}): Node => (
    <Typography
      sx={{color: Theme.colorPalette.darkest, mx: 'auto', fontWeight: 'bold'}}>
      {group.activeMembers}
    </Typography>
  );

  const columnMap = {
    name: {
      field: 'name',
      headerName: 'Group Name',
      flex: 1,
      minWidth: 200,
      renderCell: ({row: group}) => <NameCell group={group} />,
    },
    type: {
      field: 'type',
      headerName: 'Type',
      minWidth: 120,
      renderCell: ({row: group}) => <TypeCell group={group} />,
    },
    activeMembers: {
      field: 'activeMembers',
      headerName: 'Active Members',
      minWidth: 130,
      renderCell: ({row: group}) => <CountCell group={group} />,
    },
  };

  return columnMap;
};
