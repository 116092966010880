// @flow
import {computed, observable, makeObservable} from 'mobx';
import type {IObservableArray} from 'mobx';
import * as api from './api';
import type {UIHabitType} from './types';
export class HabitStore {
  rootStore: any;
  // $FlowFixMe
  allHabits: IObservableArray<UIHabitType> = [];

  constructor(rootStore: any) {
    makeObservable(this, {
      allHabits: observable,
      habitActivityFilters: computed,
    });
    this.rootStore = rootStore;
  }

  // Public Methods

  async init(): Promise<void> {
    const allHabits = await api.getAll();

    this.allHabits.replace(allHabits);
  }

  // computed

  get habitActivityFilters(): {
    name: string,
    type: string,
    key: string,
  }[] {
    const filters = [...new Set(this.allHabits.flatMap((h) => h.tags))].map(
      (name) => ({
        name,
        type: name,
        key: name,
      }),
    );

    return filters;
  }
}
