// React
import React, {useState, useEffect, useCallback} from 'react';
import {inject, observer} from 'mobx-react';

// Bulma
import {Columns} from 'react-bulma-components';

// WS
import {BridgeEventSources, Events} from '@wellstone-solutions/common';
import {Select} from '@wellstone-solutions/web';
import {useEventSource} from 'hooks/useEventSource';
import {NAVIGATION_NAMES} from 'constants/Navigation';
import ConversationsList from '../../components/list/conversation/List';
import PfChatModule from '../../components/chat/PfChatModule';
import {useStores} from 'hooks/useStores';

const ConversationsPage = (props) => {
  useEventSource(BridgeEventSources.CONVERSATIONS_PAGE);
  const [selected, setSelected] = useState(null);
  const [channelCollection, setChannelCollection] = useState('all');
  const {channelStore, eventStore} = useStores();

  const setCrumbs = useCallback(async () => {
    try {
      props.appUIStore.setBreadcrumbs([
        {
          name: NAVIGATION_NAMES.conversations,
          url: '/conversations',
          active: true,
        },
      ]);
    } catch (e) {}
  }, [props.appUIStore]);

  const chooseCollection = useCallback(
    async (e) => {
      setChannelCollection(e.target.value);
      setSelected(null);
      await channelStore.loadChannels(true, e.target.value === 'unread');
    },
    [channelStore],
  );

  useEffect(() => {
    setCrumbs();
    eventStore.addEvent(Events.VIEWED_CONVERSATIONS);
  }, [setCrumbs, eventStore]);

  if (!channelCollection) {
    return null;
  }

  return (
    <div>
      <Columns>
        <Columns.Column style={styles.list}>
          <div style={styles.dropdownContainer}>
            <Select
              value={channelCollection}
              onChange={chooseCollection}
              items={[
                {label: 'All Conversations', value: 'all'},
                {label: 'Unread Conversations', value: 'unread'},
              ]}
              canUnselect={false}
            />
          </div>
          <ConversationsList selected={selected} select={setSelected} />
        </Columns.Column>
        <Columns.Column>
          {selected && (
            <div style={styles.topMargin}>
              <PfChatModule channel={selected.id} />
            </div>
          )}
        </Columns.Column>
      </Columns>
    </div>
  );
};

const styles = {
  dropdownContainer: {
    marginBottom: '15px',
  },
  topMargin: {
    marginTop: '55px',
  },
  list: {
    minWidth: '300px',
  },
};

export default inject('appUIStore')(observer(ConversationsPage));
