// React
import React, {useEffect} from 'react';

// Third Party
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import Alert from 'react-s-alert';
import {Provider} from 'mobx-react';
import {inject, observer} from 'mobx-react';
import moment from 'moment-timezone';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import useRoutingInstrumentation from 'react-router-v6-instrumentation';

// WS
import {initializeApiClient} from '@wellstone-solutions/api/instance';
import {Api} from '@wellstone-solutions/common';
import {ThemeProvider} from '@wellstone-solutions/web';
import {SiteHeader} from 'modules/app/components/SiteHeader';
import {SiteFooter} from 'modules/app/components/SiteFooter';
import PageContainer from 'components/layout/PageContainer';
import Tutorial from 'components/tutorial/';
import {baseUrl, proxy, handleResponse, handleErrors} from 'api';
import {handleErrors as handleV2Errors} from 'api/v2';

// mobx storage
import RootStore from 'mobx/RootStore';

// Assets
import './App.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

import ScrollTo from './components/tutorial/scrollto';

const isDevelopment =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const envSuffixMap = {
  prod: 'P',
  dev: 'Dev',
  demo: 'Demo',
  local: 'L',
};

const envSuffix = envSuffixMap[process.env.REACT_APP_ENV] ?? '';
const bridgeVersion = `v${process.env.REACT_APP_BRIDGE_VERSION} ${envSuffix}`;

let dsn;
let tracesSampleRate = 0;

// Create a ReactQuery client
const queryClient = new QueryClient();

if (!isDevelopment) {
  dsn =
    'https://d5a73755bcc54c2aa8ff4365f87441f7@o294755.ingest.sentry.io/5377541';
  tracesSampleRate = 0.3;
}

// Remove this once Bulma has been removed
if (isDevelopment) {
  const originalConsoleError = console.error;

  console.error = (...args) => {
    const suppressedMessage =
      'Support for defaultProps will be removed from function components in a future major release';

    if (typeof args[0] === 'string' && args[0].includes(suppressedMessage)) {
      return;
    }

    originalConsoleError(...args);
  };
}

const apiVersions = [
  Api.API_VERSIONS.V1,
  Api.API_VERSIONS.REST,
  Api.API_VERSIONS.V2,
];
apiVersions.forEach((version) => {
  Api.configure({
    baseURL: baseUrl,
    version,
    headers: {
      common: {
        'x-client-version':
          'bridge,' + (process.env.REACT_APP_BRIDGE_VERSION || ''),
        'x-user-time-zone': moment.tz.guess(true),
      },
    },
    onTokenRefresh: (v2Tokens: {access: string, refresh: string}) => {
      if (proxy.stores.meStore) {
        proxy.stores.meStore.persistV2Tokens(v2Tokens);
      }
      return Promise.resolve();
    },
  });

  if (version === Api.API_VERSIONS.V2) {
    Api.Instance.current(version).interceptors.response.use(
      (response) => response,
      handleV2Errors,
    );
    Api.Instance.current(version).interceptors.request.use((config) => {
      if (proxy.stores.meStore.activeMembership?.organization.id) {
        config.headers['X-Org-ID'] =
          proxy.stores.meStore.activeMembership.organization.id;
      }

      return config;
    });
  } else {
    Api.Instance.current(version).interceptors.response.use(
      handleResponse,
      handleErrors,
    );
  }
});

initializeApiClient(Api.Instance.v2().axiosInstance);

const App = () => {
  // Initialize Sentry with the browser tracing integration.
  const routingInstrumentation = useRoutingInstrumentation();
  useEffect(() => {
    const browserTracing = new Integrations.BrowserTracing({
      routingInstrumentation,
    });
    Sentry.init({
      dsn,
      environment: process.env.REACT_APP_ENV,
      integrations: [browserTracing],
      tracesSampleRate,
      ignoreErrors: [/^Api Call Failed: Status: 401/],
    });
  }, [routingInstrumentation]);

  const rootStore = RootStore.getInstance();
  proxy.stores.meStore = rootStore.stores.meStore;
  proxy.stores.tutorialStore = rootStore.stores.tutorialStore;
  proxy.stores.appStore = rootStore.stores.appStore;

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <Provider {...rootStore.stores}>
          <Main />
        </Provider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

const Main = inject('tutorialStore')(
  observer(({tutorialStore}) => {
    const {isComplete} = tutorialStore;

    return (
      <div data-testid="app-main" style={styles.wrapper}>
        <div style={isComplete ? styles.fullPage : styles.anchorLeft}>
          <ScrollTo stepId="move-to-groups" />
          <SiteHeader />
          <PageContainer />
          <Alert stack={{limit: 3}} html={true} />
          <SiteFooter version={bridgeVersion} />
        </div>
        <Tutorial />
      </div>
    );
  }),
);

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'row',
    flexDirection: 'row',
    flexWrap: 'no-wrap',
    flexFlow: 'row no-wrap',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  fullPage: {
    width: '100vw',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  anchorLeft: {
    width: '72vw',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
};

export default App;
