import React, {useCallback, useEffect, useRef, useState} from 'react';
import moment from 'moment';

import {Table, Heading, Button, Columns, Form} from 'react-bulma-components';

import {Api} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
const {Input} = Form;

const POLL_INTERVAL = 3;
const POLL_TIMEOUT = 60 * 3; // 3 minutes
const ID_LENGTH = 36;

const validMemberId = (memberId) => memberId?.length >= ID_LENGTH;

export const EventsPage = () => {
  const {meStore} = useStores();
  const [lastPolled, setLastPolled] = useState(new Date());
  const [nextPollTimeout, setNextPollTimeout] = useState(
    moment().add(POLL_TIMEOUT, 'seconds'),
  );
  const [isPolling, setIsPolling] = useState(false);
  const [memberId, setMemberId] = useState('');
  const [events, setEvents] = useState([]);
  const intervalRef = useRef();
  const timeoutRef = useRef();

  const org = meStore.me.membership.organization.id;

  const pollEvents = useCallback(async () => {
    let start = new moment().startOf('day');
    let end = new moment().add(1, 'day');

    const params = {
      limit: 999,
      range_start: start.format('YYYY-MM-DD'),
      range_end: end.format('YYYY-MM-DD'),
      member_id: [memberId],
    };

    const response = await Api.Instance.current().get(
      '/orgs/' + org + '/events',
      {params},
    );

    const newEvents = response.data.events;

    setEvents(newEvents);
    setLastPolled(new Date());
  }, [org, memberId]);

  const updateMemberId = useCallback((e) => {
    const updatedUserId = e.target.value;
    setMemberId(updatedUserId);
    const shouldPoll = validMemberId(updatedUserId);

    setIsPolling(shouldPoll);
  }, []);

  useEffect(() => {
    if (isPolling && validMemberId(memberId)) {
      intervalRef.current && clearInterval(intervalRef.current);
      timeoutRef.current && clearTimeout(timeoutRef.current);

      intervalRef.current = setInterval(pollEvents, POLL_INTERVAL * 1000);

      timeoutRef.current = setTimeout(() => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
        setIsPolling(false);
      }, POLL_TIMEOUT * 1000);

      setNextPollTimeout(moment().add(POLL_TIMEOUT, 'seconds'));
    }

    return () => {
      intervalRef.current && clearInterval(intervalRef.current);
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, [isPolling, pollEvents, memberId]);

  const columns = [
    {field: 'category', headerName: 'Event'},
    {field: 'data', headerName: 'Data'},
    {field: 'created', headerName: 'Timestamp'},
  ];

  return (
    <div>
      <Columns>
        <Columns.Column>
          <Heading>Events Stream</Heading>
        </Columns.Column>
        <Columns.Column>
          <Input
            type="text"
            placeholder="Enter Member ID"
            onChange={updateMemberId}
            value={memberId}
          />
        </Columns.Column>
        <Columns.Column>
          <div style={styles.headerContent}>
            <div style={styles.info}>
              Last Polled:{' '}
              <strong>{moment(lastPolled).format('h:mm:ssA')}</strong>
              <br />
              Polling will stop at:{' '}
              <strong>{moment(nextPollTimeout).format('h:mm:ssA')}</strong>
            </div>
            <div style={styles.buttons}>
              <Button
                inverted={false}
                fullwidth={true}
                disabled={!validMemberId(memberId)}
                color={isPolling ? 'danger' : 'success'}
                outlined={false}
                onClick={() => setIsPolling(!isPolling)}>
                {isPolling ? 'Stop Polling' : 'Start Polling'}
              </Button>
            </div>
          </div>
        </Columns.Column>
      </Columns>
      <div>
        <Table>
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.field}>{column.headerName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!events || !events.length ? (
              <tr>
                <td>
                  {isPolling
                    ? 'Loading data...'
                    : 'Enter Member ID to start polling...'}
                </td>
              </tr>
            ) : (
              events.map((event) => (
                <tr key={event.id}>
                  <td>{event.category}</td>
                  <td>
                    {event.data && Object.keys(event.data).length ? (
                      <pre>{JSON.stringify(event.data, null, 2)}</pre>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td>{moment(event.created).format('h:mm:ssA MMM Do')}</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

const styles = {
  buttons: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
  },
  headerContent: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  info: {
    fontSize: '0.8rem',
    textAlign: 'right',
    paddingRight: '1rem',
  },
};
