// @flow
import React from 'react';
import type {Node} from 'react';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import {ErrorMessage} from '@wellstone-solutions/web';
import type {UIGroupType} from '@wellstone-solutions/common';
import {ALERT_TYPES, showAlert} from 'utils/showAlert';
import {useStores} from 'hooks/useStores';
import {SaveCancelModal} from 'components/modal/saveCancelModal';
import {DEFAULT_DATE_FORMAT} from 'constants/dates';
import {ItemSelection} from './ItemSelection';
import {useStartEndDateAndId} from '../../hooks/useStartEndDateAndId';
import type {ApiProgramAdmissionType} from '@wellstone-solutions/common/dist/models/types';

type PropTypes = {
  open: boolean,
  setShowModal: (show: boolean) => void,
  addableGroups: UIGroupType[],
  programAdmission: ApiProgramAdmissionType,
};

export const AddGroupModal = ({
  open,
  setShowModal,
  addableGroups,
  programAdmission,
}: PropTypes): Node => {
  const {memberStore} = useStores();
  const {member: memberId} = useParams();
  const member = memberStore.findMember(memberId);

  // create options list for select
  const selectItems = addableGroups.map((group) => ({
    label: group.name,
    value: group.id,
  }));

  const form = useStartEndDateAndId();

  const onSave = async () => {
    if (!form.canSave()) {
      return;
    }

    const admissionInput = {
      memberId: memberId,
      startDate: moment(form.data.startDate).format(DEFAULT_DATE_FORMAT),
      endDate: moment(form.data.endDate).format(DEFAULT_DATE_FORMAT),
    };

    const response = await memberStore.createGroupAdmission({
      groupId: form.data.id,
      admissionInput,
    });

    if (response.isSuccess) {
      setShowModal(false);
      form.reset();
    } else {
      showAlert(
        'Could not enroll member in selected group.',
        ALERT_TYPES.ERROR,
      );
    }
  };

  return (
    <SaveCancelModal
      open={open}
      setShowModal={setShowModal}
      title={`Enroll <strong>${member.name}</strong> in a group`}
      onSave={onSave}
      disableSave={Boolean(selectItems.length <= 0 || !form.canSave())}>
      <ItemSelection
        admission={form.data}
        setAdmission={form.setData}
        selectOptions={selectItems}
        programAdmission={programAdmission}
        label="Groups"
      />
      <ErrorMessage
        name="endDate"
        errors={form.errors}
        touched={{endDate: form.data.endDate?.length > 0}}
      />
    </SaveCancelModal>
  );
};
