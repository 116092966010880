// @flow
import React from 'react';
import type {Node} from 'react';
import {Theme} from '@wellstone-solutions/common';
import {Icon, IconNames, Stack, Typography} from '@wellstone-solutions/web';

type PropsType = {
  status?: string,
  message?: string,
  isFullScreen?: boolean,
};

const DEFAULT_MESSAGE = 'Sorry, there was an error.';

export const ErrorPage = ({
  status,
  message = DEFAULT_MESSAGE,
  isFullScreen = true,
}: PropsType): Node => (
  <Stack
    sx={{
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: isFullScreen ? '100vh' : '100%',
    }}>
    <Stack sx={{textAlign: 'center'}}>
      {status && (
        <Typography
          variant="h1"
          sx={{
            fontSize: 30,
            pb: 0.5,
          }}>
          {status}
        </Typography>
      )}
      <Typography
        variant="h1"
        sx={{
          color: Theme.colorPalette.primary,
          fontSize: 30,
          fontWeight: 'bold',
          pb: 1,
        }}>
        Oops!
      </Typography>
      <Typography sx={{pb: 2}}>
        {message}
        <br />
        Let's try it again from the top.
      </Typography>

      {isFullScreen && (
        <a href="/">
          <Stack
            direction="row"
            sx={{display: 'inline-flex', fontWeight: 'bold'}}>
            <Typography sx={{color: Theme.colorPalette.secondary}}>
              Go back to Bridge
            </Typography>
            <Icon
              name={IconNames.ArrowRight}
              color={Theme.colorPalette.secondary}
            />
          </Stack>
        </a>
      )}
    </Stack>
  </Stack>
);
