// @flow
import React from 'react';
import type {Node} from 'react';
import {Modal} from 'react-bulma-components';
import {Box, Divider, Icon, Stack, Typography} from '@wellstone-solutions/web';
import {PfButton} from 'components/shared/PfButton';

type PropsType = {
  open: boolean,
  isRemoving: boolean,
  setShowModal: (showModal: boolean) => void,
  onRemove: () => Promise<void>,
};

export const RemoveStoryModal = ({
  open = false,
  isRemoving,
  setShowModal,
  onRemove,
}: PropsType): Node => (
  <Modal
    show={open}
    onClose={() => {
      setShowModal(false);
    }}
    closeOnBlur={true}
    closeOnEsc={true}>
    <Modal.Content style={styles.modal}>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Typography variant="h5">Remove Story</Typography>
        </Stack>
        <Divider />
        <footer style={styles.footer}>
          <PfButton
            onClick={() => setShowModal(false)}
            color="light"
            loading={isRemoving}
            disabled={isRemoving}>
            Cancel
          </PfButton>
          <PfButton
            outlined
            onClick={onRemove}
            color="danger"
            loading={isRemoving}
            disabled={isRemoving}>
            <Icon name="Trash" color="error" style={styles.spaceRight} />
            Remove Story
          </PfButton>
        </footer>
      </Box>
    </Modal.Content>
  </Modal>
);

const styles = {
  modal: {
    backgroundColor: '#fff',
    padding: '30px',
    borderRadius: '10px',
    minWidth: '200px',
    width: 'auto !imporant',
  },
  footer: {
    display: 'flex',
    paddingTop: 8,
    paddingBottom: 8,
    justifyContent: 'space-between',
  },
  spaceRight: {
    marginRight: 8,
  },
};
