// @flow
/* Found this here: https://gist.github.com/rmorse/426ffcc579922a82749934826fa9f743 */
import {useCallback} from 'react';
import {useBlocker} from '../useBlocker';

/**
 * Prompts the user with an Alert before they leave the current screen.
 *
 * @param  message
 * @param  when
 */
export function usePrompt(
  message: string,
  when: boolean = true,
  callback: ?() => any,
) {
  const blocker = useCallback(
    (tx) => {
      if (window.confirm(message)) {
        callback?.();
        tx.retry();
      }
    },
    [message, callback],
  );

  useBlocker(blocker, when);
}
