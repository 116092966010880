// @flow
import {makeObservable} from 'mobx';
import RootStore from 'mobx/RootStore';
import {Models} from '@wellstone-solutions/common';
import type {
  UIStoryType,
  ApiResponseType,
  ApiStoryType,
} from '@wellstone-solutions/common';
import {STATUSES} from './constants';

const {Story} = Models;

export class StoryStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  async createStory(story: UIStoryType): Promise<ApiResponseType<UIStoryType>> {
    const payload: ApiStoryType = Story.toApi(story);

    const formData = this._buildFormData(payload);

    const response: ApiResponseType<ApiStoryType> = await Story.create(
      formData,
    );

    return this._transformResponse(response);
  }

  async getStory(id: string): Promise<ApiResponseType<?UIStoryType>> {
    // $FlowFixMe
    const response = await Story.get(id);
    if (response.isSuccess && response.data) {
      const uiStory = Story.toUI(response.data);
      return {...response, data: uiStory};
    } else {
      return {...response, data: undefined};
    }
  }

  async updateStory(story: UIStoryType): Promise<ApiResponseType<UIStoryType>> {
    const payload: ApiStoryType = Story.toApi(story);

    const formData = this._buildFormData(payload);

    const response: ApiResponseType<ApiStoryType> = await Story.update(
      // $FlowIgnoreMe
      story.id,
      formData,
    );

    return this._transformResponse(response);
  }

  async updateStoryStatus(
    storyId: string,
    organization: string,
    status: typeof STATUSES,
  ): Promise<ApiResponseType<UIStoryType>> {
    // convert payload to formData for image uploading purposes
    const formData: FormData = new FormData();
    // $FlowIgnoreMe
    formData.append('status', status);
    formData.append('organization', organization);

    const response: ApiResponseType<ApiStoryType> = await Story.updateFields(
      storyId,
      formData,
    );

    return this._transformResponse(response);
  }

  async removeStory(storyId: string): Promise<ApiResponseType<null>> {
    const response: ApiResponseType<null> = await Story.remove(storyId);

    return response;
  }

  // convert payload to formData for image uploading purposes
  _buildFormData(payload: ApiStoryType): FormData {
    const formData: FormData = new FormData();
    Object.keys(payload).forEach((key) => {
      if (key === 'data') {
        // $FlowIgnoreMe
        formData.append(key, JSON.stringify(payload[key]));
      } else if (Array.isArray(payload[key])) {
        // convert to form data representation of array
        payload[key].forEach((value, i) => {
          // $FlowIgnoreMe
          formData.append(`${key}[${i}]`, value);
        });
      } else {
        // $FlowIgnoreMe
        formData.append(key, payload[key] || '');
      }
    });
    return formData;
  }

  _transformResponse(
    response: ApiResponseType<ApiStoryType>,
  ): ApiResponseType<UIStoryType> {
    const uiStory: UIStoryType = Story.toUI(response.data);
    return {
      ...response,
      data: uiStory,
    };
  }
}
