//React
import React, {Component} from 'react';

// Third party
import {inject, observer} from 'mobx-react';
import {DateRangePicker} from 'react-date-range';
import moment from 'moment';

// Bulma
import {Heading, Level, Tag, Tabs} from 'react-bulma-components';

// WS
import {
  Api,
  BridgeEventSources,
  Events,
  Roles,
  Utils,
} from '@wellstone-solutions/common';
import {Select} from '@wellstone-solutions/web';
import PfChatModule from 'components/chat/PfChatModule';
import EditAdminForm from 'components/forms/member/EditAdminForm';
import PfLoader from 'components/shared/PfLoader';
import PfModal from 'components/modal/PfModal';
import {Palette} from 'palette';
import {styleTag} from 'utils/Styler';
import {adminRoles, getGender, getIdentity} from 'utils/Forms';
import ScrollTo from 'components/tutorial/scrollto';
import {getDateRange, handleDateSelect, processData} from 'utils/Charts';
import {aggregatingList} from 'constants/Aggregators';
import MembersOverview from 'components/MembersOverview';
import {PermissionsLoader} from 'modules/staff/components';
import {AccessControl} from 'modules/rbac';
import {EHRConnect} from 'modules/integration';

class AdminDetailPage extends Component {
  state = {
    ready: false,
  };

  componentDidMount() {
    this.props.eventStore.setEventSource(BridgeEventSources.STAFF_DETAIL_PAGE);

    this._loadUX();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.router.params.admin !== this.props.router.params.admin) {
      this._loadUX();
    }
  }

  _loadUX = async () => {
    let {admin} = this.props.router.params;
    this.breadcrumbArray = [
      {
        name: 'Staff',
        url: '/staff',
      },
      {
        name: 'Staff Detail',
        url: '/staff/' + admin,
        active: true,
      },
    ];

    this.props.eventStore.addEvent(Events.VIEWED_STAFF_DETAIL, {
      staff_id: admin, // this is the staff id
    });

    let start = new Date();
    let end = new Date();
    start.setDate(start.getDate() - 6);
    end.setDate(end.getDate() + 1);

    this.setState(
      {
        ready: false,
        activeTab: 'permissions',
        aggregator: 'date',
        selectionRange: {
          startDate: start,
          endDate: end,
          key: 'selection',
        },
      },
      () => this.getAdmin(),
    );
  };

  onCloseModal = (data) => {
    this.getAdmin();
  };

  getAdmin = async () => {
    const {admin} = this.props.router.params;
    const {meStore, RBACStore, appUIStore} = this.props;
    const org = meStore.me.membership.organization.id;
    try {
      const response = await Api.Instance.current().get(
        '/orgs/' + org + '/staff/' + admin,
      );

      if (!response.isSuccess) {
        appUIStore.setBreadcrumbs(this.breadcrumbArray);
        this.setState({ready: true});
        return;
      }

      const isActive = response.data.status === 'active';

      this.breadcrumbArray[1].name = response.data.name;
      appUIStore.setBreadcrumbs(this.breadcrumbArray);
      const firstTab = isActive
        ? RBACStore.hasAccess(AccessControl.staff.viewPermissions)
          ? 'permissions'
          : 'chat'
        : 'activity';
      this.setState({
        activeTab: firstTab,
        admin: response.data,
        isActive,
        ready: true,
      });
      this._getData();
    } catch (e) {
      appUIStore.setBreadcrumbs(this.breadcrumbArray);
      this.setState({ready: true});
    }
  };

  _getData = async () => {
    let {admin} = this.props.router.params;
    let start = moment(this.state.selectionRange.startDate).format(
      'YYYY-MM-DD',
    );
    let end = moment(this.state.selectionRange.endDate).format('YYYY-MM-DD');

    const params = {
      limit: 999999,
      range_start: start,
      range_end: end,
      member_id: admin,
      only_staff: 1,
    };
    const org = this.props.meStore.me.membership.organization.id;

    try {
      let response = await Api.Instance.current().get(
        '/orgs/' + org + '/events',
        {params},
      );
      const allData = await processData(org, response, this.state);
      this.setState(allData);
    } catch (e) {}
  };

  _setTab = (tab) => {
    this.setState({activeTab: tab});
  };

  _getRole = (thisRole, prop = 'name') => {
    let tmpRole = adminRoles.find((role, r) => {
      return role.value === thisRole;
    });
    return tmpRole ? tmpRole[prop] : null;
  };

  _handleSelectAggregator = async (e) => {
    this.setState({aggregator: e.target.value}, () => this._getData());
  };

  render() {
    const {RBACStore} = this.props;

    if (!this.state.ready) {
      return <PfLoader />;
    }

    if (Utils.isObjectEmpty(this.state.admin)) {
      return (
        <Heading>Admin does not exist, or you may not have access</Heading>
      );
    }

    // Can edit staff member if current user is a super admin or
    // if the current user as all authorizations the staff member has
    const staffAuths = this.state.admin.authorizations;
    const hasAuthorizations = staffAuths.every(({obj_id, obj_type}) =>
      RBACStore.hasAccessToEntity(obj_type, obj_id),
    );

    const canEdit =
      RBACStore.hasAccess(AccessControl.staff.editAllStaff) ||
      (this.state.admin.role !== Roles.SUPER_ADMIN && hasAuthorizations);

    const canAssignEHRIntegration =
      !!this.props.meStore.ehrIntegrationOption &&
      RBACStore.hasAccess(AccessControl.staff.canAssignEHRIntegrations);

    return (
      <>
        <Level style={styles.level}>
          <Level.Side style={{flexDirection: 'column'}}>
            <Level.Item style={styles.stackedLeftItems}>
              <Heading size={3}>{this.state.admin.name}</Heading>
              <Tag
                color={this._getRole(this.state.admin.role, 'color')}
                style={styles.adminTag}>
                {this._getRole(this.state.admin.role, 'name')}
              </Tag>
              {!this.state.isActive ? (
                <Tag color="warning" style={styles.adminTag}>
                  Inactive
                </Tag>
              ) : null}
            </Level.Item>
            {canAssignEHRIntegration && (
              <Level.Item style={{marginTop: 8, alignSelf: 'flex-start'}}>
                <EHRConnect memberType={'staff'} member={this.state.admin} />
              </Level.Item>
            )}
          </Level.Side>
          <Level.Side>
            <Level.Item style={styles.stackedRightItems}>
              {canEdit && this.state.isActive ? (
                <PfModal
                  button={{
                    outlined: true,
                    text: 'Edit ' + this._getRole(this.state.admin.role),
                  }}
                  large
                  buttonStyle={styles.modalButtonStyle}
                  modal={{
                    closeOnBlur: true,
                    showClose: false,
                    closeOnEsc: true,
                  }}
                  disabled={!RBACStore.hasAccess(AccessControl.staff.editStaff)}
                  onCloseModal={this.onCloseModal}>
                  <Heading size={4}>Edit Staff Member</Heading>
                  <EditAdminForm admin={this.state.admin} />
                </PfModal>
              ) : null}
              <Tag.Group gapless style={styles.stackedLevelItem}>
                <Tag color="dark">Sex</Tag>
                <Tag
                  style={{
                    ...styleTag(getGender(this.state.admin.gender).name, true),
                    ...styles.groupTag,
                  }}>
                  {getGender(this.state.admin.gender).name}
                </Tag>
              </Tag.Group>
              <Tag.Group gapless style={styles.stackedLevelItem}>
                <Tag color="dark">Identifies As</Tag>
                <Tag
                  style={{
                    ...styleTag(
                      getIdentity(this.state.admin.identifies_as).name,
                      true,
                    ),
                    ...styles.groupTag,
                  }}>
                  {getIdentity(this.state.admin.identifies_as).name}
                </Tag>
              </Tag.Group>
            </Level.Item>
          </Level.Side>
        </Level>
        <Tabs className="tabs">
          {this.state.isActive &&
          RBACStore.hasAccess(AccessControl.staff.viewPermissions) ? (
            <Tabs.Tab
              active={this.state.activeTab === 'permissions'}
              onClick={() => this._setTab('permissions')}>
              Permissions
            </Tabs.Tab>
          ) : null}
          {this.state.isActive ? (
            <Tabs.Tab
              active={this.state.activeTab === 'chat'}
              onClick={() => this._setTab('chat')}>
              Chat
            </Tabs.Tab>
          ) : null}
          {RBACStore.hasAccess(AccessControl.staff.viewActivity) && (
            <Tabs.Tab
              active={this.state.activeTab === 'activity'}
              onClick={() => this._setTab('activity')}>
              Activity
            </Tabs.Tab>
          )}
          <div style={styles.tabSpacer} />
        </Tabs>

        {this.state.activeTab === 'permissions' ? (
          <PermissionsLoader staffId={this.props.router.params} />
        ) : null}

        {this.state.activeTab === 'chat' ? (
          <PfChatModule
            user={this.state.admin.user.id}
            name={this.state.admin.name}
            context={this}
          />
        ) : null}

        {this.state.activeTab === 'activity' &&
        RBACStore.hasAccess(AccessControl.staff.viewActivity) ? (
          <div>
            <ScrollTo stepId="range" />
            <Level>
              <Level.Side>
                <Level.Item>
                  <Heading size={4}>Activity</Heading>
                </Level.Item>
              </Level.Side>
              <Level.Side>
                <Level.Item>
                  <PfModal
                    button={{
                      outlined: true,
                      text: getDateRange(this),
                      style: styles.button,
                    }}
                    modal={{
                      closeOnBlur: true,
                      showClose: false,
                      closeOnEsc: true,
                    }}
                    id={'memberDateRangePicker'}
                    tutorialFunction={(v) => {
                      handleDateSelect(v, this);
                    }}>
                    <DateRangePicker
                      ranges={[this.state.selectionRange]}
                      rangeColors={[Palette.LINK]}
                      id={'memberDateRangePicker'}
                      onChange={(v) => {
                        handleDateSelect(v, this);
                      }}
                    />
                  </PfModal>
                </Level.Item>
                <Level.Item>
                  <span style={styles.viewBy}>View by</span>
                </Level.Item>
                <Level.Item style={styles.relative}>
                  <Select
                    sx={{width: 150}}
                    value={this.state.aggregator}
                    onChange={this._handleSelectAggregator}
                    className="right-drop short-drop"
                    id="member-view-by-dropdown"
                    items={aggregatingList.map((agg) => ({
                      value: agg.key,
                      label: agg.name,
                    }))}
                    canUnselect={false}
                  />
                </Level.Item>
              </Level.Side>
            </Level>
            <MembersOverview
              context={this}
              aggregator={this.state.aggregator}
              checkinData={[]}
              habitData={[]}
              staffData={this.state.staffData}
              activityData={this.state.activityData}
              memberNotifications={this.props.memberNotifications}
              start={this.state.selectionRange.startDate}
              end={this.state.selectionRange.endDate}
              rawEvents={this.state.rawEventData}
              showDetailsModal={false}
            />
          </div>
        ) : null}
      </>
    );
  }
}

const styles = {
  level: {
    alignItems: 'flex-start',
  },
  modalButtonStyle: {
    marginBottom: 15,
  },
  stackedLeftItems: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    flexDirection: 'row',
  },
  stackedRightItems: {
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  stackedLevelItem: {
    marginBottom: 5,
  },
  basicInfo: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
    color: Palette.GREY_2,
  },
  buttonIcon: {
    marginRight: 10,
  },
  groupTag: {
    marginTop: 'unset',
    marginRight: 'unset',
    marginLeft: 'unset',
    marginBottom: '0.5rem',
  },
  adminTag: {
    marginTop: 10,
    marginLeft: 10,
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  ehrConnect: {marginTop: 8, alignSelf: 'flex-start'},
};

export default inject(
  'appUIStore',
  'eventStore',
  'meStore',
  'RBACStore',
)(observer(AdminDetailPage));
