// @flow
import {observable, action, runInAction, makeObservable} from 'mobx';
import RootStore from 'mobx/RootStore';
import {
  UserResource,
  Collection,
} from '@wellstone-solutions/common/models/rest';
import type {ApiResponseType} from '@wellstone-solutions/common';
import type {
  UICollectionType,
  UIUserResourceType,
} from '@wellstone-solutions/common/models/rest';
import {Events} from '@wellstone-solutions/common';

import type {IObservableArray} from 'mobx';

export class ResourceStoreV2 {
  rootStore: RootStore;
  collections: IObservableArray<UICollectionType> = observable.array();
  userResources: IObservableArray<UIUserResourceType> = observable.array();
  isLoadingUserResources: boolean = false;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      collections: observable,
      userResources: observable,
      getCollections: action,
      getUserResources: action,
      handleUserResource: action,
      isLoadingUserResources: observable,
      isSavedResource: action,
    });

    this.rootStore = rootStore;
  }

  async getCollections(
    forceFetch: boolean = false,
  ): Promise<ApiResponseType<IObservableArray<UICollectionType> | []>> {
    if (!forceFetch && this.collections.length) {
      return Promise.resolve({
        isSuccess: true,
        errors: [],
        status: 200,
        data: this.collections,
      });
    }

    const response = await Collection.getAll();
    if (response.isSuccess) {
      const resourceCollections = response.data.filter(
        (collection) => collection.collection_type.name === 'Resources',
      );

      const uiCollections = resourceCollections.map(Collection.toUI);
      runInAction(() => {
        this.collections.replace(uiCollections);
      });

      return {...response, data: this.collections};
    } else {
      return {...response, data: []};
    }
  }

  async getUserResources(
    forceFetch?: boolean = false,
  ): Promise<ApiResponseType<IObservableArray<UIUserResourceType> | []>> {
    if (!forceFetch && this.userResources.length) {
      return Promise.resolve({
        isSuccess: true,
        errors: [],
        status: 200,
        data: this.userResources,
      });
    }

    runInAction(() => {
      this.isLoadingUserResources = true;
    });

    const response = await UserResource.getAll();
    if (response.isSuccess) {
      const uiUserResources = response.data.map(UserResource.toUI);
      runInAction(() => {
        this.userResources.replace(uiUserResources);
        this.isLoadingUserResources = false;
      });
      return {...response, data: this.userResources};
    } else {
      runInAction(() => {
        this.userResources.replace([]);
        this.isLoadingUserResources = false;
      });
      return {...response, data: []};
    }
  }

  async handleUserResource(
    resourceId: string,
  ): Promise<ApiResponseType<UIUserResourceType | void>> {
    const existingResource = await this.userResources.find(
      (ur) => ur.resource.id === resourceId,
    );
    let response;

    if (existingResource) {
      response = await UserResource.remove(existingResource.id);
      if (response.isSuccess) {
        runInAction(() => {
          this.userResources.remove(existingResource);
        });
      }
    } else {
      response = await UserResource.create({
        resourceId,
        // $FlowFixMe - meStore does not have types
        userId: this.rootStore.stores.meStore.me.id,
      });

      if (response.isSuccess) {
        const uiUserResource = UserResource.toUI(response.data);
        runInAction(() => {
          this.userResources.push(uiUserResource);
        });

        this.rootStore.stores.eventStore.addEvent(Events.USER_SAVED_RESOURCE, {
          id: resourceId,
        });
      }
    }
    return response;
  }

  isSavedResource(resourceId: string): boolean {
    return this.userResources.some((ur) => ur.resource.id === resourceId);
  }
}
