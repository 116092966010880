import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Heading, Level} from 'react-bulma-components';

import {BridgeEventSources, Theme} from '@wellstone-solutions/common';
import {Select} from '@wellstone-solutions/web/';
import {useEventSource} from 'hooks/useEventSource';
import {useStores} from 'hooks/useStores';
import {ENTITY_LEVEL, AccessControl} from 'modules/rbac';
import {NewAnnouncement, PastAnnouncements} from 'modules/announcements';

const ALL_MEMBERS_VALUE = -1;

export const AnnouncementsPage = () => {
  useEventSource(BridgeEventSources.ANNOUNCEMENTS_PAGE);

  const {group_id} = useParams();
  const {appUIStore, groupStore, RBACStore} = useStores();
  const navigate = useNavigate();

  const canSendToAllMembers = RBACStore.hasAccess(
    AccessControl.announcements.sendToAllMembers,
  );
  const canAccessAllGroups = RBACStore.hasEntityLevelAccess(ENTITY_LEVEL.all);

  const groups = canAccessAllGroups ? groupStore.groups : groupStore.myGroups;
  const selectedGroup = groups.find((g) => g.id === group_id)?.name;
  const groupName =
    canSendToAllMembers && !selectedGroup ? 'All Members' : selectedGroup;

  useEffect(() => {
    appUIStore.setBreadcrumbs([
      {
        name: 'Announcements',
        url: '/announcements',
        active: true,
      },
    ]);
  }, [appUIStore]);

  const handleChange = (event) => {
    if (event.target.value !== ALL_MEMBERS_VALUE) {
      navigate(`/announcements/${event.target.value}`);
      return;
    }

    navigate('/announcements');
  };

  const groupNameItems = [
    {label: 'All Members', value: ALL_MEMBERS_VALUE},
    ...groups.map((group) => ({
      label: group.name,
      value: group.id,
    })),
  ];

  return (
    <div style={styles.root}>
      <Level>
        <Level.Side align="left">
          <Level.Item>
            <Heading style={styles.heading} size={5}>
              Announcements for
            </Heading>
          </Level.Item>
          <Level.Item>
            <Select
              value={group_id || ALL_MEMBERS_VALUE}
              onChange={handleChange}
              className="short-drop evaluator-drop"
              items={groupNameItems}
              canUnselect={false}
            />
          </Level.Item>
        </Level.Side>
      </Level>

      <Level style={styles.content}>
        <Level.Side style={styles.newAnnouncementSide}>
          <NewAnnouncement groupId={group_id} groupName={groupName} />
        </Level.Side>
        <Level.Side style={styles.pastAnnouncementsSide}>
          <PastAnnouncements groupId={group_id} />
        </Level.Side>
      </Level>
    </div>
  );
};

const styles = {
  root: {
    padding: '1rem',
  },
  heading: {
    color: Theme.colorPalette.dark,
    marginRight: '1rem',
  },
  content: {
    alignItems: 'flex-start',
    marginTop: '1rem',
  },
  newAnnouncementSide: {
    flex: '2 1 auto',
    marginRight: '2rem',
    '@media screen and (max-width: 768px)': {
      flex: '1 1 auto',
      marginRight: 0,
      marginBottom: '1rem',
    },
  },
  pastAnnouncementsSide: {
    flex: '1 1 auto',
  },
};
