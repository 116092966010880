// @flow
import React, {useState, useEffect} from 'react';
import type {ComponentType} from 'react';
import {observer} from 'mobx-react';
import {
  Drawer,
  Grid,
  Icon,
  Box,
  Button,
  Tooltip,
} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';

import {PrimaryNavigation} from './PrimaryNavigation';
import {Branding} from './Branding';

import {useStores} from 'hooks/useStores';

import {throttle} from 'utils/Utils';

export const SiteNavigation: ComponentType<{}> = observer(() => {
  const {appStore} = useStores();
  const {menuShowing, toggle} = appStore;
  const headerHeight = 80;
  const [sidebarTop, setSidebarTop] = useState(headerHeight);

  const toggleMenu = (ev) => {
    toggle();
    ev.currentTarget.blur();
  };

  const handleScroll = throttle(() => {
    const header = document.querySelector('#site_header') || null;
    if (!header || header.classList.contains('visible')) {
      setSidebarTop(headerHeight);
    } else {
      setSidebarTop(0);
    }
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    const headerCheck = window.setInterval(handleScroll, 1000);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.clearInterval(headerCheck);
    };
  }, [handleScroll]);

  const maxHeightOffset = (menuShowing ? 190 : 150) + sidebarTop;

  return (
    <>
      {/* $FlowFixMe */}
      <Box
        data-testid="sidebar-scaling-box"
        sx={{
          width: menuShowing ? '308px' : '70px',
          transition: 'width 150ms',
        }}></Box>
      <Box
        data-testid="sidebar-root"
        sx={[
          style.navigationRoot,
          menuShowing && style.navigationRootOpen,
          {
            top: sidebarTop,
            '& .MuiPaper-root': {
              maxHeight: `calc(100vh - ${maxHeightOffset}px)`,
            },
          },
        ]}>
        <Drawer variant="permanent" anchor="left" sx={style.drawerRoot}>
          <Grid
            container
            direction="row"
            sx={[style.headerGrid, menuShowing && style.headerGridOpen]}>
            {menuShowing && <Branding />}
            <Tooltip
              title={menuShowing ? 'Collapse' : 'Expand'}
              placement="right"
              TransitionProps={{
                timeout: {
                  enter: 100,
                  exit: 0,
                },
              }}
              arrow>
              <span>
                <Button
                  data-testid="navbar-toggle-button"
                  size="medium"
                  onClick={toggleMenu}
                  disableRipple
                  sx={style.toggleButton}>
                  <Icon
                    name={menuShowing ? 'SidebarCollapse' : 'SidebarExpand'}
                    size={24}
                    sx={{cursor: 'pointer'}}>
                    {menuShowing ? 'SidebarCollapse' : 'SidebarExpand'}
                  </Icon>
                </Button>
              </span>
            </Tooltip>
          </Grid>
          <PrimaryNavigation open={menuShowing} onNavigate={() => {}} />
        </Drawer>
      </Box>
    </>
  );
});

const style = {
  toggleButton: {
    padding: 0,
    minWidth: '30px',
    ':focus': {
      border: `2px ${Theme.colorPalette.link} solid`,
    },
  },
  navigationRoot: {
    position: 'fixed',
    border: '1px #eee solid',
    borderRadius: '12px',
    width: '63px',
    padding: '12px 0',
    marginTop: '25px',
    overflow: 'auto',
    top: '0px',
    alignItems: 'start',
    overflowX: 'hidden',
    backgroundColor: Theme.colorPalette.surface,
    transition: 'all 150ms',
    '& .MuiDrawer-paper': {
      borderRightWidth: 0,
      position: 'relative',
      overflowX: 'hidden',
    },
    '& .MuiPaper-root': {
      display: 'grid',
      gridTemplateRows: '60px 1fr',
    },

    '& .MuiPaper-root > ul': {
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        background: 'transparent',
        height: '5px',
        width: '5px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'rgba(0, 0, 0, 0.1)',
        border: 'none',
        borderRadius: '4px',
        boxShadow: 'none',
      },
    },
  },
  navigationRootOpen: {
    borderRadius: '24px',
    width: '300px',
    padding: '18px 0 40px 0',
    '& .MuiPaper-root': {
      display: 'grid',
      gridTemplateRows: '95px 1fr',
    },
  },
  drawerRoot: {
    marginLeft: '0px',
    height: 'auto',
    overflowX: 'hidden',
  },
  headerGrid: {
    overflow: 'hidden',
    width: '100%',
    height: '60px',
    alignItems: 'start',
    flex: '1 1 100px',
    justifyContent: 'center',
    padding: '16px 0px 16px 0px',
  },
  headerGridOpen: {
    height: '100px',
    justifyContent: 'space-between',
    padding: '5px 24px 0px 24px',
  },
};
