// @flow
import React from 'react';
import type {Node} from 'react';
import type {GridColDef} from '@mui/x-data-grid';
import {Tag, Button} from 'react-bulma-components';
import {Stack, Typography} from '@wellstone-solutions/web';
import {Api, Theme} from '@wellstone-solutions/common';
import type {UIStaffType} from '@wellstone-solutions/common';
import {adminRoles} from 'utils/Forms';
import {styleTag} from 'utils/Styler';
import {EventEmitter} from 'utils/EventEmitter';
import {useStores} from 'hooks/useStores';
import NavLink from 'components/tutorial/navlink';
import PfModal from 'components/modal/PfModal';
import {AdminAuthsToRemove} from '../../components/StaffList/AdminAuthsToRemove';
import {STAFF_PROGRESS} from '../../constants';
import {AccessControl} from 'modules/rbac';

type ColumnsMapType = {
  name: GridColDef<UIStaffType>,
  role: GridColDef<UIStaffType>,
  groups: GridColDef<UIStaffType>,
  progress: GridColDef<UIStaffType>,
  actions: GridColDef<UIStaffType>,
  ehr: GridColDef<UIStaffType>,
};

const noop = () => {};

export const useStaffColumnMap = (
  refetch: () => void = noop,
): ColumnsMapType => {
  const {groupStore, meStore, RBACStore} = useStores();

  const orgEHRIntegration = meStore.ehrIntegrationOption;

  const NameCell = ({admin}): Node => {
    const viewSelf = RBACStore.hasAccess(
      AccessControl.staff.viewSelfStaffDetail,
    );

    return meStore.me.id === admin.user.id && !viewSelf ? (
      admin.name + ' (me)'
    ) : (
      <NavLink
        id={`goto-${admin.id}`}
        activeClassName="active-link"
        className="inactive-link"
        to={'/staff/' + admin.id}
        tutorialStyles={styles.nameLink}
        style={styles.boldText}>
        {meStore.me.id === admin.user.id ? admin.name + ' (me)' : admin.name}
      </NavLink>
    );
  };

  const RoleCell = ({admin}): Node => {
    const adminRole = adminRoles.find(({value}) => value === admin.role);
    return (
      <Tag color={adminRole?.color} style={styles.adminTag}>
        {adminRole?.name}
      </Tag>
    );
  };

  const GroupsCell = ({admin}): Node => {
    const groupTags = admin.authorizations.map((auth, a) => {
      // $FlowFixMe
      if (auth.obj_type.toLowerCase() === 'group') {
        // $FlowFixMe
        const group = groupStore.getGroupById(auth.obj_id);

        return (
          <Tag
            title={group.name}
            key={a}
            style={{
              ...styleTag(group.category.name, true),
              ...styles.groupTag,
            }}>
            <Typography
              noWrap
              sx={{fontSize: 'inherit', fontWeight: 'inherit'}}>
              {group.name}
            </Typography>
          </Tag>
        );
      }
      return null;
    });

    return (
      <Stack direction="row" flexWrap="wrap" style={styles.groupStack}>
        {groupTags}
      </Stack>
    );
  };

  const ProgressCell = ({admin}): Node => {
    if (!RBACStore.hasAccess(AccessControl.staff.viewListProgress)) {
      return null;
    }

    let progress = STAFF_PROGRESS.notLoggedIn;

    // $FlowFixMe
    const lastLogin = admin.user?.lastLogin;
    progress =
      lastLogin && lastLogin.length > 0
        ? STAFF_PROGRESS.loggedIn
        : STAFF_PROGRESS.notLoggedIn;

    const tutorialProgress = admin.user?.data?.tutorial_bridge;
    if (tutorialProgress) {
      progress =
        tutorialProgress === 'complete'
          ? STAFF_PROGRESS.completed
          : STAFF_PROGRESS.started;
    }

    return (
      <Tag
        style={{
          color: progress.color,
          ...styles.adminTag,
        }}>
        {progress.label}
      </Tag>
    );
  };

  const ActionCell = ({admin}): Node => {
    if (!RBACStore.hasAccess(AccessControl.staff.viewListActions)) {
      return null;
    }

    const removeAdmin = async (member) => {
      const org = meStore.me.membership.organization.id;
      const response = await Api.Instance.current().delete(
        `/orgs/${org}/staff/${member.id}`,
      );
      EventEmitter.dispatch('closeModal', response);

      if (response?.isSuccess) {
        refetch();
      }
    };

    if (meStore.me.id !== admin.user.id) {
      return (
        <PfModal
          button={{outlined: true, text: 'Remove'}}
          buttonContainerStyle={styles.buttonContainer}
          modal={{closeOnBlur: true, showClose: true, closeOnEsc: true}}>
          <AdminAuthsToRemove admin={admin} remove={removeAdmin} />
          <hr />
          <Button
            fullwidth={true}
            inverted={true}
            color="danger"
            outlined={false}
            onClick={() => EventEmitter.dispatch('closeModal')}>
            Cancel
          </Button>
        </PfModal>
      );
    }

    return null;
  };

  const EHRCell = ({admin}: {admin: UIStaffType}): Node => {
    const {integrations} = admin;
    const hasEHRConnection = integrations?.length > 0;
    const externalID = integrations[0]?.externalRelationshipId;

    return hasEHRConnection ? (
      <Typography>{externalID}</Typography>
    ) : (
      <Typography
        sx={{color: Theme.colorPalette.red, fontSize: 14, fontWeight: 'bold'}}>
        Not connected
      </Typography>
    );
  };

  const columnMap = {
    name: {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 140,
      maxWidth: 350,
      renderCell: ({row: admin}) => <NameCell admin={admin} />,
    },
    role: {
      field: 'role',
      headerName: 'Role',
      minWidth: 120,
      renderCell: ({row: admin}) => <RoleCell admin={admin} />,
    },
    groups: {
      field: 'groups',
      headerName: 'Groups',
      flex: 1,
      minWidth: 420,
      sortable: false,
      renderCell: ({row: admin}) => <GroupsCell admin={admin} />,
    },
    progress: {
      field: 'progress',
      headerName: 'Progress',
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: ({row: admin}) => <ProgressCell admin={admin} />,
    },
    actions: {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 160,
      sortable: false,
      renderCell: ({row: admin}) => <ActionCell admin={admin} />,
    },
    ehr: {
      field: 'ehr',
      headerName: orgEHRIntegration?.name ?? 'EHR System',
      minWidth: 200,
      renderCell: ({row: admin}) => <EHRCell admin={admin} />,
    },
  };

  return columnMap;
};

const styles = {
  boldText: {
    fontWeight: 'bold',
  },
  adminTag: {
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  nameLink: {
    zIndex: 501,
    position: 'relative',
    background: 'white',
    boxShadow: '0 0 8px 12px rgba(255, 255, 255, 1)',
  },
  buttonContainer: {
    marginRight: 10,
    display: 'inline-block',
  },
  groupStack: {
    maxWidth: '100%',
  },
  groupTag: {
    maxWidth: '100%',
    justifyContent: 'flex-start',
  },
};
