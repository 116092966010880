// React
import React, {Component} from 'react';

// Third party
import {inject, observer} from 'mobx-react';

// Bulma
import Button from 'components/tutorial/button';
import {Columns, Level, Form} from 'react-bulma-components';

// WS
import {Api} from '@wellstone-solutions/common';
import {
  fieldStatus,
  adminStructure,
  getAdminRole,
  genders,
  identities,
  getGender,
  getIdentity,
} from 'utils/Forms';
import {EventEmitter} from 'utils/EventEmitter';
import PfInput from 'components/forms/components/PfInput';
import PfDropdown from 'components/forms/components/PfDropdown';
const {Label} = Form;

class EditAdminForm extends Component {
  state = {
    email: adminStructure.email.default,
    name: adminStructure.name.default,
    gender: adminStructure.gender.default,
    identity: adminStructure.identity.default,
    role: adminStructure.role.default,
    isProcessing: false,
  };

  componentDidMount() {
    this._loadUX();
  }

  _loadUX = async () => {
    let a = this.props.admin;
    a.role = getAdminRole(a.role);
    a.email = a.user.email;
    a.gender = getGender(a.gender);
    a.identity = getIdentity(a.identifies_as);
    this.setState(a);
  };

  _selectGender = (sel) => {
    this.setState({gender: sel});
  };

  _selectIdentity = (sel) => {
    this.setState({identity: sel});
  };

  // _selectRole = (sel) => {
  // 	this.setState({ role: sel});
  // };

  _checkForErrors = () => {
    let hasErrors = false;
    for (let [key, value] of Object.entries(adminStructure)) {
      if (fieldStatus(this.state[key], value.test, value.required).error) {
        hasErrors = true;
      }
    }
    return hasErrors;
  };

  _updateAdmin = async () => {
    const org = this.props.meStore.me.membership.organization.id;

    const params = {
      gender: this.state.gender.value.toLowerCase(),
      identifies_as: this.state.identity.value.toLowerCase(),
      name: this.state.name,
    };

    try {
      this.setState({isProcessing: true});
      const response = await Api.Instance.current().post(
        '/orgs/' + org + '/members/' + this.props.admin.id,
        params,
      );
      this.setState({isProcessing: false});
      EventEmitter.dispatch('closeModal', response);
    } catch (e) {
      this.setState({isProcessing: false});
    }
  };

  render() {
    return (
      <div style={styles.form} className={'edit-member-form'}>
        {/*NAME*/}
        <PfInput
          fullWidth
          placeholder="full name"
          val={this.state.name}
          setval={(newVal) => this.setState({name: newVal})}
          label="Full Name*"
          field={{
            test: adminStructure.name.test,
            name: 'name',
            required: adminStructure.name.required,
          }}
        />

        <PfInput
          fullWidth
          placeholder=""
          label="Email"
          val={this.state.user ? this.state.user.email : ''}
          disabled={'disabled'}
          field={{
            test: adminStructure.email.test,
            name: 'email',
            required: adminStructure.email.required,
          }}
        />
        <span className="notice">
          Email is not editable. Please contact Pathfinder to update a member's
          email.
        </span>

        <Columns>
          {/*ROLE*/}
          {/*{isStaffPermitted(this.props.meStore.me.membership.role, "is_super") ?*/}
          {/*<Columns.Column size={6}>*/}
          {/*	<PfDropdown*/}
          {/*		context={this}*/}
          {/*		label="Role*"*/}
          {/*		selectFunc={this._selectRole}*/}
          {/*		list={adminRoles}*/}
          {/*		labelPath="name"*/}
          {/*		classes="right-drop short-drop"*/}
          {/*		field={{*/}
          {/*			test: adminStructure.role.test,*/}
          {/*			name: "role",*/}
          {/*			required: adminStructure.role.required*/}
          {/*		}}*/}
          {/*	/>*/}
          {/*</Columns.Column> : <Columns.Column size={6}>{this.state.role.name}</Columns.Column>}*/}
          <Columns.Column size={4}>
            <Label style={styles.fieldLabel}>Role</Label>
          </Columns.Column>
          <Columns.Column size={8} style={styles.alignRight}>
            {this.state?.role?.name}
          </Columns.Column>
        </Columns>
        {/*SEX*/}
        <PfDropdown
          val={this.state.gender}
          label="Sex"
          selectFunc={this._selectGender}
          list={genders}
          labelPath="name"
          classes="top-drop right-drop short-drop"
          field={{
            test: adminStructure.gender.test,
            name: 'gender',
            required: adminStructure.gender.required,
          }}
        />
        {/*IDENTIFIES AS*/}
        <PfDropdown
          val={this.state.identity}
          label="Identifies As"
          selectFunc={this._selectIdentity}
          list={identities}
          labelPath="name"
          classes="top-drop right-drop short-drop"
          field={{
            test: adminStructure.identity.test,
            name: 'identity',
            required: adminStructure.identity.required,
          }}
        />
        {/* BIRTH DATE */}
        <Columns>
          <Columns.Column size={4}>
            <Label style={styles.fieldLabel}>Birth Date*</Label>
          </Columns.Column>
          <Columns.Column size={8} style={styles.alignRight}>
            {this.props.admin.birthdate || 'Date not set'}
          </Columns.Column>
        </Columns>

        <hr />

        <Level>
          <Level.Side>
            <Level.Item>
              <Button
                color="primary"
                disabled={this._checkForErrors() ? true : false}
                isprocessing={this.state.isProcessing}
                onClick={this._updateAdmin}>
                Update {this.state?.role?.name}
              </Button>
            </Level.Item>
          </Level.Side>
          <Level.Side>
            <Level.Item>
              <Button
                color="danger"
                onClick={() => EventEmitter.dispatch('closeModal', null)}>
                Cancel
              </Button>
            </Level.Item>
          </Level.Side>
        </Level>
      </div>
    );
  }
}

const styles = {
  alignRight: {textAlign: 'right'},
  fieldLabel: {
    paddingRight: 20,
  },
  form: {
    maxHeight: 'calc(100vh - 160px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  stackedRightItems: {
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
};

export default inject('meStore')(observer(EditAdminForm));
