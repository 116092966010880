import {observable, makeObservable} from 'mobx';
// import { saveToAsync, clearArrayFromAsync } from '../utils/AsyncManager';
// import moment from 'moment';
import pfLogo from '../assets/images/PF_splash@3x.png';
import popSound from '../assets/sounds/pop.wav';

// const AsyncStorage = window.localStorage;
//
// const asyncMarkedPrefix = 'event_user_marked_message_';
// const asyncCheckinPrefix = 'event_user_added_checkin_';
// const asyncFriendPrefix = 'event_user_added_friend_';
// const asyncRelapsedPrefix = 'event_user_relapsed_';

// **************************** IMPORTANT ****************************
// This Store is being used exclusively for handling desktop
// notifications right now. Once we re-introduce notifications
// (other events besides new messages), the functionality will expand

class NotificationStore {
  constructor(rootStore) {
    makeObservable(this, {
      // events: observable,
      // total: observable,
      // set: action,
      // remove: action,
      tabIsFocused: observable,
    });

    this.rootStore = rootStore;
    // this.loadAllFromAsync();
    this.setupDesktopNotifications();
    if (/*@cc_on!@*/ false) {
      // check for Internet Explorer
      document.onfocusin = this.onTabFocus;
      document.onfocusout = this.onTabBlur;
    } else {
      window.onfocus = this.onTabFocus;
      window.onblur = this.onTabBlur;
    }
  }

  tabIsFocused = true;
  notificationAudio = null;

  playSound() {
    if (this.notificationAudio) {
      const playPromise = this.notificationAudio.play();

      // Bug fix: https://developer.chrome.com/blog/play-request-was-interrupted/
      if (playPromise) {
        playPromise
          .then(() => {
            // Play success!
          })
          .catch(() => {
            // Play failed
            // Just silently fail, a sound not playing is ok
          });
      }
    }
  }

  setupDesktopNotifications = async () => {
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification');
      return;
    }

    let granted = Notification.permission === 'granted';

    if (!granted && Notification.requestPermission) {
      const permission = await Notification.requestPermission();
      granted = permission === 'granted';
    }

    if (granted) {
      this.notificationAudio = new window.Audio();

      // Safari requires a user interaction in order to play a sound
      // this code activates the sound object the first time you click
      // anywhere on the screen, without actually playing the sound. The
      // audio.src is added when you want to show the notification
      const mouseResponder = () => {
        // The sound is not set on this initial round, so no sound plays,
        // but we can "clearance" to play the sound when the user tabs away
        // by doing this
        this.playSound();
        document.removeEventListener('mousedown', mouseResponder);
      };
      document.addEventListener('mousedown', mouseResponder);
    }
  };

  onTabBlur = () => {
    this.tabIsFocused = false;
  };

  onTabFocus = () => {
    this.tabIsFocused = true;
  };

  showDesktopNotification = (title, body) => {
    if (!this.tabIsFocused) {
      var options = {
        body: body,
        requireInteraction: true,
        icon: pfLogo,
        dir: 'ltr',
      };

      new window.Notification(title, options);

      // Set the actual sound now because we want to play the blip
      if (this.notificationAudio) {
        this.notificationAudio.src = popSound;
      }
      this.playSound();
    }
  };

  // HIDING certain event types temporarily until we resolve what events we want passing through to the inbox
  async loadAllFromAsync() {
    // this.events.user_marked_message = await loadArrayFromAsync(
    //   asyncMarkedPrefix
    // );
    // this.events.user_added_checkin = await loadArrayFromAsync(
    //   asyncCheckinPrefix
    // );
    // this.events.user_added_friend = await loadArrayFromAsync(asyncFriendPrefix);
    // this.events.user_relapsed = await loadArrayFromAsync(asyncRelapsedPrefix);
  }

  events = {
    user_marked_message: [],
    user_added_checkin: [],
    user_added_friend: [],
    user_relapsed: [],
  };

  total() {
    return 0;
    // return (
    //   this.events.user_marked_message.length +
    //   this.events.user_added_checkin.length +
    //   this.events.user_relapsed.length +
    //   this.events.user_added_friend.length
    // );
  }

  // remove = (type, index) => {
  //   if (index || index === 0) {
  //     let evt = this.events[type][index];
  //     this.events[type].splice(index, 1);
  //     AsyncStorage.removeItem('event_' + type + '_' + evt.timetoken);
  //   } else if (type) {
  //     this.events[type].forEach((evt, e) => {
  //       AsyncStorage.removeItem('event_' + type + '_' + evt.timetoken);
  //     });
  //     this.events[type] = [];
  //   } else {
  //     this.remove('user_marked_message');
  //     this.remove('user_added_checkin');
  //     this.remove('user_added_friend');
  //     this.remove('user_relapsed');
  //   }
  // };
  //
  // set = async (type, newEvent, persist) => {
  //   type = type ? type : 'unknown';
  //   if (!this.events[type]) {
  //     this.events[type] = [];
  //   }
  //   const found = this.events[type].find(
  //     (event) => event.timetoken === newEvent.timetoken
  //   );
  //   if (!found) {
  //     newEvent = this.buildPreview(newEvent);
  //     this.events[type].push(newEvent);
  //     if (persist) {
  //       saveToAsync('event_' + type + '_' + newEvent.timetoken, newEvent);
  //     }
  //   }
  // };
  //
  // buildPreview = (evt) => {
  //   switch (evt.message.event_type) {
  //     case 'user_marked_message':
  //       evt.copy =
  //         'Warning: Someone is concerned about ' +
  //         evt.message.data.message.data.name +
  //         '. "' +
  //         evt.message.data.comment +
  //         '"';
  //       evt.user = evt.message.data.message.data.author;
  //       evt.color = 'warning';
  //       break;
  //     case 'user_added_checkin':
  //       evt.copy = 'Checkin: ' + evt.message.data.name;
  //       evt.user = evt.message.data.user_id;
  //       evt.color = 'success';
  //       break;
  //     case 'user_added_friend':
  //       evt.copy =
  //         'New Relationship: ' +
  //         evt.message.data.friend_name +
  //         ' & ' +
  //         evt.message.data.user_name;
  //       evt.user = evt.message.data.user_id;
  //       evt.color = 'link';
  //       break;
  //     case 'user_relapsed':
  //       evt.copy =
  //         'Relapse: ' +
  //         evt.message.data.name +
  //         '(' +
  //         moment(evt.message.data.sober_date).format('MMM D, YYYY') +
  //         ')';
  //       evt.user = evt.message.data.user_id;
  //       evt.color = 'danger';
  //       break;
  //     default:
  //       evt.copy =
  //         'Notification: ' +
  //         evt.message.data.name +
  //         ', type: ' +
  //         evt.message.event_type;
  //       evt.user = evt.message.data.user_id;
  //       evt.color = 'primary';
  //       break;
  //   }
  //   return evt;
  // };
  //
  // clear() {
  //   this.events = {
  //     user_marked_message: [],
  //     user_added_checkin: [],
  //     user_added_friend: [],
  //     user_relapsed: [],
  //   };
  //   clearArrayFromAsync(asyncMarkedPrefix);
  //   clearArrayFromAsync(asyncCheckinPrefix);
  //   clearArrayFromAsync(asyncFriendPrefix);
  //   clearArrayFromAsync(asyncRelapsedPrefix);
  // }
}

export default NotificationStore;
