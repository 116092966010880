// @flow
import React, {useEffect} from 'react';
import type {Node} from 'react';
import {Modal} from 'react-bulma-components';
import {Events} from '@wellstone-solutions/common';
import type {UICalendarEventType} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {CalendarEventLoader} from '../CalendarEvent';
import {EventModalContent} from './EventModalContent';

type PropsType = {
  open: boolean,
  setShowModal: (value: boolean) => void,
  calendarEvent: UICalendarEventType,
};

export const EventModal = ({
  open = false,
  setShowModal,
  calendarEvent,
}: PropsType): Node => {
  const {eventStore} = useStores();

  useEffect(() => {
    eventStore.addEvent(Events.VIEWED_CALENDAR_EVENT_PREVIEW, {
      calendar_event_id: calendarEvent.id,
      original_start: calendarEvent.originalStart,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      show={open}
      onClose={() => {
        setShowModal(false);
      }}
      closeOnBlur={true}
      closeOnEsc={true}>
      <Modal.Content style={styles.modal}>
        <CalendarEventLoader
          calendarEventId={String(calendarEvent.id)}
          originalStart={String(calendarEvent.originalStart)}>
          {/* $FlowIgnoreMe */}
          <EventModalContent isModalOpen={open} setShowModal={setShowModal} />
        </CalendarEventLoader>
      </Modal.Content>
    </Modal>
  );
};

const styles = {
  modal: {
    backgroundColor: '#fff',
    padding: '30px',
    borderRadius: '10px',
    minWidth: '200px',
    width: 'auto !imporant',
  },
};
