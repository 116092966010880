import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import {ErrorBoundary} from 'components/ErrorBoundary';
import App from './App';
import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ErrorBoundary>
    <Router basename={process.env?.PUBLIC_URL || '/'}>
      <App />
    </Router>
  </ErrorBoundary>,
);
