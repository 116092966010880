// @flow
import React, {useState} from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
import {useParams} from 'react-router-dom';
import {Message} from 'react-bulma-components';
import {
  Box,
  Grid,
  Icon,
  IconNames,
  Stack,
  Typography,
} from '@wellstone-solutions/web';
import {Roles, Theme} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {PfButton} from 'components/shared/PfButton';
import {AddProgramModal} from './AddProgramModal';
import {StaffProgramAuthorization} from './StaffProgramAuthorization';
import {Revocations} from './Revocations';
import {AccessControl} from 'modules/rbac';

export const Permissions: ComponentType<{}> = observer((): Node => {
  const [showProgramModal, setShowProgramModal] = useState<boolean>(false);
  const {meStore, staffStore, RBACStore} = useStores();
  const {admin: staffId} = useParams();
  const staffMember = staffStore.findStaffMember(staffId);

  // Admins cannot edit their own programs
  const isMe = staffMember ? meStore.me.id === staffMember.user.id : false;
  const canAddPrograms =
    RBACStore.hasAccess(AccessControl.staff.canAddProgramEnrollment) || !isMe;

  // Bug fix: When selecting a staff member from search results, the staff member
  // may not be in the staff store yet, so just return null
  if (!staffMember) {
    return null;
  }

  if (staffMember.role === Roles.SUPER_ADMIN && !isMe) {
    return (
      <div>
        <Message style={styles.grantBox}>
          <Message.Header style={styles.grantBoxHeader}>
            Super Admin
          </Message.Header>
          <Message.Body style={styles.grantBoxBody}>
            Super Admin positions do not require permissions configuration.
          </Message.Body>
        </Message>
      </div>
    );
  }

  return (
    <Box sx={{flexGrow: 1}}>
      <Grid container spacing={2}>
        <Grid item sm={12} md={8}>
          <Stack direction="column">
            {staffStore.authorizedPrograms({staffId}).map((program) => (
              <StaffProgramAuthorization
                staffMember={staffMember}
                program={program}
                key={program.id}
              />
            ))}
            {canAddPrograms && (
              <Stack direction="row" justifyContent="flex-end">
                <PfButton
                  color="primary"
                  onClick={() => setShowProgramModal(true)}
                  style={styles.button}
                  aria-label="add staff to program"
                  inverted>
                  <Typography sx={{fontWeight: 'bold', mr: 1}}>
                    Add to Another Program
                  </Typography>
                  <Icon
                    color={Theme.colorPalette.primary}
                    name={IconNames.PlusCircle}
                    strokeWidth={2}
                  />
                </PfButton>
              </Stack>
            )}
          </Stack>
        </Grid>
        <Grid item sm={12} md={4}>
          <Revocations staff={staffMember} />
        </Grid>
      </Grid>
      <AddProgramModal
        open={showProgramModal}
        setShowModal={setShowProgramModal}
      />
    </Box>
  );
});

const styles = {
  button: {
    borderSize: 0,
    paddingRight: '15px',
  },
  grantBox: {
    backgroundColor: Theme.colorPalette.seafoamLight,
  },
  grantBoxHeader: {
    backgroundColor: Theme.colorPalette.violet,
    color: Theme.colorPalette.lightest,
  },
  grantBoxBody: {
    color: Theme.colorPalette.violet,
  },
};
