// @flow
import type Moment from 'moment';
import type {UIMemberType} from '@wellstone-solutions/common';

export const isGroupStartDateValid = ({
  member,
  selectedStartDate,
  programId,
}: {
  member: UIMemberType,
  selectedStartDate: Moment,
  programId: string,
}): boolean => {
  const programAdmission = member.admissions.find(
    (admission) => admission.program.id === programId,
  );

  if (!programAdmission) {
    return false;
  }

  // $FlowIgnore - programAdmission type is wrong for start_date
  return selectedStartDate.isSameOrAfter(programAdmission.start_date, 'day');
};
